import React, { Component } from 'react';
import Service from '../components/Service';



class About extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],

        }

        this.mainHeading = React.createRef();

    }

    componentDidMount = () => {
        Service.get("/test/frontEndTest", {}, (res) => {
            this.setState({ users: res.data.users })
            console.log(res)

        });
        this.mainHeading.current.focus();


    }



    render() {
        return (
            <div className="about-page-wrapper">
            <img className="bg-t-r" src="/images/background-about.jpg" />
            <div className="content">
                
                <h1 ref={this.mainHeading} tabIndex="0" className="title-left">ABOUT</h1>

                <div className="about-int">
                    <h3>ABOUT US</h3>
                    <p>
                        We are consortium of seven organizations: the founding organization
                        “Youth Cultural Center of Vojvodina” from Serbia and partner organizations
                        from Albania, Bulgaria, Greece, Italy, Montenegro and Turkey. Supported
                        through Erasmus+ projects named “Use sound to watch around” we developed
                        of Soundsy platform that enable persons with visual impairment in
                        their entrepreneurship.
                        
                    </p>

                </div>
                <div className="about-platform">
                    <h3>ABOUT PLATFORM</h3>
                    <p>
                        The platform itself is a base of members which form a diverse community of individual
                        audio artists seeking to find or offer work, sell or buy sounds. Our platform enables 
                        persons with visual impairment to work as freelancers in the field of audio stock and 
                        helps them become self-employed.
                    </p>

                    <p>
                        This platform is designed to facilitate inclusion of differently abled persons, support their
                        entrepreneurial efforts and educate them about music and sound design possibilities.
                        The platform’s aim is to help young entrepreneurs start a business and work as
                        contributors and freelancers in the field of sound design.
                    </p>

                    <p>
                        By using sound stock and services, our clients have the opportunity to support young
                        entrepreneurs and give real value to their skills and hard work.
                    </p>

                    <p>
                        Organization members have been involved in practical work on designing and creating
                        this platform, participants from these countries have contributed with audio works for
                        selling on stock, testing platform and putting it into action.
                    </p>
                </div>

                <h3>ABOUT PARTNER ORGANIZATIONS:</h3>

                <div className="about-partner">

                    <div className="container-about-side">
                        <div className="about-box">
                        
                            <h4>MONTENEGRO</h4>
                            <h4>The Union of the Blind of Montenegro</h4>
                            <p>
                                Support visually impaired persons <br></br>
                                through international cooperation, <br></br>
                                education and assistance during the <br></br>
                                employment process.<br></br>
                            </p>   
                                <a href="http://www.ss-cg.org" target="_blank"><button>VIEW DETAILS</button></a>
                                
                        </div>
                        <div className="about-box">
                        
                            <h4>GREECE</h4>
                            <h4>Break The Borders</h4>
                            <p>
                                Social inclusion of the visually <br></br>
                                impaired using non-formal <br></br>
                                education using theatre, <br></br>
                                music and sound design. <br></br>
                            </p>   
                            <a href="https://www.facebook.com/BreakTheBordersNGO/" target="_blank"><button>VIEW DETAILS</button></a>                                
                        </div>

                    </div>

                    {/* ITALIY */}
                    <div className="container-about">
                        <div className="about-box">
                        
                            <h4>ITALY</h4>
                            <h4>Young Men’s Christian Association Parthenope</h4>
                            <p>
                                Specializes in transnational<br></br>
                                educational projects focusing on <br></br>
                                youth entrepreneurship and <br></br>
                                employability improvement.<br></br>
                            </p>   
                            <a href="http://www.ymcaparthenope.eu" target="_blank"><button>VIEW DETAILS</button></a>
            
                        </div>
                        <div className="about-box">
                        
                            <h4>SERBIA</h4>
                            <h4>Association “Youth Cultural Center of Vojvodina”</h4>
                            <p>
                                Promotes women and young people <br></br>
                                through international educational <br></br>
                                projects in the fields of art and <br></br>
                                culture.<br></br>
                            </p>
                            <a href="http://www.kcmv.udruzenje.org" target="_blank"><button>VIEW DETAILS</button></a>
                        
                        </div>
            
                    <div className="about-box">         
                        <h4>ALBANIA</h4>
                        <h4>European Integration and Development</h4>
                        <p>
                            Assists and promotes social integration<br></br>
                            of vulnerable communities i.e. <br></br>
                            differently abled through education <br></br>
                            and training.<br></br>
                        </p>
                        <a href="https://www.facebook.com/European-Integration-Development-857451267660613/?ref=aymt_homepage_panel" target="_blank"><button>VIEW DETAILS</button></a>
                      
                    </div>
                    </div>

                    <div className="container-about-side">

                        <div className="about-box">
                        
                            <h4>TURKEY</h4>
                            <h4>System and Generation</h4>
                            <p>
                                Supports integration and  sustainable <br></br>
                                development of youth through <br></br>
                                entrepreneurship projects and <br></br>
                                international cooperation.<br></br>
                            </p>
                            <a href="http://www.systemandgeneration.com" target="_blank"><button>VIEW DETAILS</button></a>
                            
                        </div>

                        <div className="about-box">
                        
                            <h4>BULGARIA</h4>
                            <h4>The Change is in You</h4>
                            <p>
                                Supports integration of differently abled <br></br>
                                and ethnic minority groups by giving <br></br>
                                them access to modern achievements and <br></br>
                                information technologies.<br></br>
                            </p>        
                            <a href="http://www.CYA.Tryavna.eu" target="_blank"><button>VIEW DETAILS</button></a>
                            
                        </div>

                    </div>


                        
                    

                </div>
            </div>
            </div>
        );
    }





}





export default About;