import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Service from '../components/Service';



class AdminChats extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            chats: [],
             
            
        }

    }



    componentDidMount(){
        this.getChats();


    }

    getChats=()=>{
        Service.get("/admin/getChats",{}, (res) => {

            console.log(res);
            this.setState({chats: res.data.chats});
            
        }); 
    }





    render() {
        return (
            <div className="content">

                <h1> Chats</h1>
                
            </div>
        );
    }

}

export default AdminChats;