import React, { Component } from 'react';


class TermsOfUse extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
        };
        this.mainHeading = React.createRef();
    }

    componentDidMount() {
        this.mainHeading.current.focus();
    }
    
    render(){
        return(
            <div className="tof-wrapper">
                 <img className="bg-b-r full-width" src="/images/background-terms-of-use.png" />
                <div className="content">
                    <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Terms Of Use</h1>
                    <div className="tof-textbox">
                        {/* Introductory provisions */}
                        <h2>Introductory provisions</h2>
                        <p>
                            SOUNDSY.NET is a website created within the project "Use sound to watch around II" 
                            organized by the nonprofit and nongovernment organization - Association "Youth Cultural 
                            Center of Vojvodina" in order to address the unemployment of blind young people 
                            by using the possibilities of sound and music design.
                        </p>
                        <p>
                            These terms, including any schedules and supplemental terms applies to our sites and services 
                            on which we display or post a direct link to this Terms of use. If there is any conflict 
                            between these Terms of use and any supplemental terms to a site or service, the supplemental 
                            terms will control. This Terms of use do not apply to those sites and services that do not 
                            display or link to this Terms of use, or that have their own terms of use.
                        </p>
                        <p>
                            By using our site and services, you are a "user" and you accept and agree to this Terms of use 
                            as a legally binding contract between you and Association Youth Cultural Center of Vojvodina, 
                            which owns this site. 
                        </p>
                        <p>
                            Access to the sites and services is available to all interested users.
                        </p>
                        <p>
                            Users are considered visitors and registered users.
                        </p>
                        <p>
                            Visitors are all persons who access the site and services without logging in or registering. 
                            Content from the sites and services might be restricted to visitors.
                        </p>
                        <p>
                            Registered users are all natural and legal persons who make available personal and business 
                            information and are registered using the online form. Registered users can post their own 
                            content, comments and ratings.
                        </p>
                        <p>
                            Authors are registered users who, on or via the site, sell items - their copyrighted works, 
                            including but not limited to photographs, text, audio or visuals, program codes.
                        </p>
                        <p>
                            We may post changes to these Terms of use at any time, and any such changes will be applicable to 
                            all subsequent access to or use of our sites and services. If you do not accept and agree to all 
                            provisions of these Terms of use, now or in the future, you may reject these Terms of use by immediately 
                            terminating all access and use of our sites and services, in which case any continuing access or use of our 
                            sites and service is unauthorized.
                        </p>
                        <p>
                            By establishing a legally binding relationship, acceptance of these Terms of Use shall be deemed to 
                            be the conclusion of a distance contract in accordance with the relevant regulations of the Republic 
                            of Serbia. By accepting these Terms of Use you are giving your unconditional and irrevocable consent to 
                            be bound by these Terms of Use when using the site or services.
                        </p>
                        <p>
                            You are also required to comply with, and to ensure compliance with all laws, ordinances and 
                            regulations applicable to your activities on our sites and services.
                        </p>
                        <p>
                            Unless expressly permitted by supplemental terms, our sites and services are intended for general 
                            audiences 18 years of age and older, and access or use by anyone younger is not authorized.
                        </p>
                        <p>
                            These Terms of use grants you a limited, revocable, nonexclusive license to access our sites and services 
                            and use our sites and services, in whole or in part, including but not limited to our intellectual property 
                            therein, solely in compliance with these Terms of use.
                        </p>

                        {/* 1. Administration */}
                        <h2>1. Administration</h2>
                        <p>
                            We have the right, but not the obligation, to regulate content (which includes but is not limited to postings, text, code, 
                            images, video, binary files, ads, accounts, account information, flags, emails, messages and any other user 
                            communications ("content")) posted to, stored on or transmitted via our sites and services by any user (or any 
                            other third party in any manner); to regulate conduct (including but not limited to any authorized or unauthorized 
                            access to or use of our sites and services) by any user (or any other third party in any manner); and to enforce 
                            these Terms of use, for any reason and in any manner or by any means that we, in our sole discretion, deem necessary or 
                            appropriate (including but not limited to automated and manual screening, blocking, filtering, exclusion from index 
                            pages, exclusion from search results, requiring the use of an application programming interface (API), requiring 
                            the use of a bulk posting interface, authorization, verification, and the deletion and/or termination of content, 
                            accounts and/or all or any use or access). 
                        </p>
                        <p>
                            We may, in our sole discretion and without notice, start, stop or modify any regulation or enforcement measures at any time. 
                            Our action or inaction to regulate content or conduct or to enforce against any potential violation of these Terms of use by any user 
                            (or any other third party) does not waive our right to implement or not implement regulation or enforcement measures with respect 
                            to any subsequent or similar content, conduct or potential Terms of use violation. You also understand and agree that any action or 
                            inaction by us or any of our directors, officers, Association members, employees, consultants, agents or representatives (collectively, 
                            "our representatives" and individually "our representative") to prevent, restrict, redress or regulate content, or to implement other 
                            enforcement measures against any content, conduct or potential Terms of use violation is undertaken voluntarily and in good faith, and 
                            you expressly agree that neither we nor any of our representatives will be liable to you or anyone else for any action or inaction to prevent, 
                            restrict, redress, or regulate content, or to implement other enforcement measures against any content, conduct or potential violation of these Terms of use.
                        </p>
                        <p>
                            Although our representatives may moderate content on our sites and services at our discretion, our representatives have no authority to make binding commitments, 
                            promises or representations to anyone that they or anyone else on behalf of us will "take care" of any alleged problem or complaint, or that they or anyone else on 
                            behalf of us will otherwise stop, cure or prevent any problem, content, conduct or purported Terms of use violation from occurring or recurring. Accordingly, you 
                            further agree that any representation (written or verbal) by our representative (or by anyone else acting on behalf of us or by anyone purportedly acting on behalf of us) 
                            that we (including but not limited to our representative, anyone else acting on behalf of us, or anyone purportedly acting on behalf of us) would or would not prevent, 
                            restrict, redress or regulate content (including, without limitation, screen, block, moderate, review, remove, terminate, delete, edit or otherwise stop, cure or exclude any content), 
                            or to implement other enforcement measures against any content, conduct or potential or purported Terms of use violation is superseded by this provision and is nonbinding and unenforceable. 
                            Specifically, you agree that we, our representatives and anyone else authorized to act on behalf of us will in no circumstances be liable as a result of any representation that we, 
                            our representatives or anyone else on behalf of us would or would not restrict or redress any content, conduct or potential or purported Terms of use violation. For the purpose of clarity, 
                            the opinions, statements, comments, posts and other communications expressed on our sites and services are solely those of the poster and not ours, or our employees, officers, directors, 
                            shareholders, subsidiaries, parent companies, attorneys or other agents. 
                        </p>
                        <p>
                            We do not guarantee the accuracy or reliability, or the information provided by any poster, administrator, or moderator. Administrators and moderators are not our agents and their opinions, 
                            comments and posts are their own. This paragraph may not be modified, waived or released except by a written agreement, dated and signed by our legal representative and dated and signed by the 
                            individual or entity to whom the modification, waiver or release is granted. We also have the right in our sole discretion to limit, modify, interrupt, suspend or discontinue all or any portions 
                            of our sites and services at any time without notice. We and our representatives will not be liable for any such limitations, modifications, interruptions, suspensions or discontinuance, or any purported 
                            losses, harm or damages arising from or related thereto.
                        </p>
                        <p>
                        The administrator reserves the right to first determine and change the price of the file based on quality, originality and duration in order to achieve good sales in the market that is mutually beneficial.
                        </p>
                        {/* 2. Content and Conduct */}
                        <h2>2. Content and Conduct</h2>
                        <p>
                            We do not control, are not responsible for and makes no representations or warranties with respect to any user content. You are solely responsible for your access to, use of and/or reliance on any user content. 
                            You must conduct any necessary, appropriate, prudent or judicious investigation, inquiry, research and due diligence with respect to any user content. You are also responsible for any content that you post or transmit 
                            and, if you create an account, you are responsible for all content posted or transmitted through or by use of your account. Content prohibited from our sites and services includes but is not limited to:
                        </p>
                        <ul>
                            <li>illegal content;</li>
                            <li>content in facilitation of the creation, advertising, distribution, provision or receipt of illegal goods or services;</li>
                            <li>offensive content (including, without limitation, court ordered defamatory statements, threatening, hateful or pornographic content);</li>
                            <li>content that discloses another's personal, confidential or proprietary information;</li>
                            <li>false or fraudulent content (including but not limited to false, fraudulent or misleading responses to user ads transmitted via our site and services);</li>
                            <li>malicious content (including, without limitation, malware or spyware);</li>
                            <li>content that offers, promotes, advertises, or provides links to posting or auto-posting products or services, account creation or auto-creation products or services, 
                                flagging or auto-flagging products or services, bulk telephone numbers, or any other product or service that if utilized with respect to our sites and services would 
                                violate these Terms of Use or our other legal rights; and
                            </li>
                            <li>content that offers, promotes, advertises or provides links to unsolicited products or services.</li>
                        </ul>  
                        <p>
                            Other content prohibitions are set forth in supplemental terms for particular categories or services on our sites and services and all such 
                            prohibitions are expressly incorporated into these Terms of use as stated in the introductory paragraphs above. 
                        </p>
                        <h2>2a. Conduct</h2>
                        <p>
                            We do not control, are not responsible for and makes no representations or warranties with respect to any user or user conduct. You are solely 
                            responsible for your interaction with or reliance on any user or user conduct. You must perform any necessary, appropriate, prudent or judicious 
                            investigation, inquiry, research and due diligence with respect to any user or user conduct. You are also responsible for your own conduct and 
                            activities on, through or related to our sites and services, and, if you create an account on our site and services, you are responsible for all 
                            conduct or activities on, through or by use of your account.
                        </p>
                        {/* 3. Postings and Accounts  */}
                        <h2>3. Postings and Accounts</h2>
                        <p>
                            This section 3 applies to all uses of our sites and services, unless we have specifically authorized an exception to a particular term for a 
                            particular user in a written agreement. We have sole and absolute discretion to authorize or deny any exception or exceptions to the terms in this 
                            section 3.
                        </p>
                        <h2>3a. Postings</h2>
                        <p>
                            Users may not circumvent any technological measure implemented by us to restrict the manner in which content may be posted on our sites and services 
                            or to regulate the manner in which content (including but not limited to email) may be transmitted to other users. This prohibition includes, without 
                            limitation, a ban on the use of multiple email addresses (created via an email address generator or otherwise); the use of multiple IP addresses 
                            (via proxy servers, modem toggling, or otherwise); CAPTCHA circumvention, automation or outsourcing; multiple and/or fraudulent service accounts.
                        </p>
                        <p>
                            It is explicitly prohibited for any third party to post content to our sites and services on behalf of another. Users must post content only on 
                            their own behalf, and may not permit, enable, induce or encourage any third party to post content for them. It is explicitly prohibited to post 
                            content to our sites and services using any automated means. Users must post all content personally and manually through all steps of the posting 
                            process. It is also explicitly prohibited for any user to develop, offer, market, sell, distribute or provide an automated means to perform any step 
                            of the posting process (in whole or in part). Any user who develops, offers, markets, sells, distributes or provides an automated means to perform 
                            any step of the posting process (in whole or in part) will be responsible and liable to us for each instance of access to our sites and services 
                            (by any user or other third party) using that automated means. Affiliate marketing by users is explicitly prohibited on our sites and services. Users 
                            may not post content or communicate with any user for purposes of affiliate marketing or in connection with any affiliate marketing system, scheme or 
                            program in any manner or under any circumstances.
                        </p>
                        <h2>3b. Accounts</h2>
                        <p>
                            User may create, maintain and use no more than one account to post content only on his/her or its own behalf. A user must create his/her or its account 
                            personally and manually and may not create accounts by any automated means. Without limitation, this includes the obligation that the user personally and 
                            manually solves any CAPTCHA challenge in the account creation process. A user may not create or use additional accounts or any account of another and must not 
                            permit, enable, induce or encourage others to create accounts for him/her or it. The purchase and sale of accounts and the creation of accounts for others is 
                            explicitly prohibited. The circumvention of any technological restriction or security measure in the account creation process, posting process or otherwise for 
                            posting content in violation of these Terms of use also is explicitly prohibited.
                        </p>
                        {/* 4. Unauthorized Access and Activities */}
                        <h2>4. Unauthorized Access and Activities</h2>
                        <p>
                            This section applies to all uses of our sites and services, unless we have specifically authorized an exception to a particular term for a particular user in a written 
                            agreement. We have sole and absolute discretion to authorize or deny any exception or exceptions to the terms in this section. To maintain the integrity and functionality 
                            of our sites and services for its users, access to our sites and services and/or activities related to our sites and services that are harmful to, inconsistent with or 
                            disruptive of our sites and services and/or users, beneficial use and enjoyment of our sites and services are explicitly unauthorized and prohibited. 
                        </p>
                        <p>For example, without limitation:</p>
                        <p>
                            The collection of our site and services user’s personal information (including but not limited to email addresses, IP addresses and telephone numbers) is not 
                            allowed for any purpose.
                        </p>
                        <p>
                            Any copying, aggregation, display, distribution, performance or derivative use of our sites and services or any content posted on our sites and services whether done directly or 
                            through intermediaries (including but not limited to by means of spiders, robots, crawlers, scrapers, framing, iframes or RSS feeds) is prohibited. As a limited exception, general 
                            purpose Internet search engines and noncommercial public archives will be entitled to access our sites and services without individual written agreements executed with us that specifically 
                            authorize an exception to this prohibition if, in all cases and individual instances:
                        </p>
                        <ul>
                            <li>they provide a direct hyperlink to the relevant website, service, forum or content;</li>
                            <li>they access our sites and services from a stable IP address using an easily identifiable agent; and</li>
                            <li>they comply with our robots.txt file; provided however, we may terminate this limited exception to any search engine or public archive 
                                (or any person or entity relying on this provision to access our sites and services without their own written agreement executed with us), 
                                at any time and in our sole discretion, upon written notice, including, without limitation, by email notice.
                            </li>
                        </ul>  
                        <p>
                            Any access to or use of our sites and services to design, develop, test, update, operate, modify, maintain, support, market, advertise, distribute or otherwise make 
                            available any program, application or service (including, without limitation, any device, technology, product, computer program, mobile device application, website, 
                            or mechanical or personal service) that enables or provides access to, use of, operation of or interoperation with our sites and services (including, without limitation, 
                            to access content, post content, cross-post content, re-post content, respond or reply to content, verify content, transmit content, create accounts, verify accounts, use 
                            accounts, circumvent and/or automate technological security measures or restrictions, or flag content) is prohibited. This prohibition specifically applies but is not limited to 
                            software, programs, applications and services for use or operation on or by any computer and/or any electronic, wireless and/or mobile device, technology or product that exists 
                            now or in the future.
                        </p>
                        <p>
                            Any effort to decompile, disassemble or reverse engineer all or any part of our sites and services in order to identify, acquire, copy or 
                            emulate any source code or object code is explicitly prohibited.
                        </p>
                        <p>
                            Any activities (including but not limited to posting voluminous content) that are inconsistent with the use of our sites and services in compliance with these Terms of use or 
                            that may impair or interfere with the integrity, functionality, performance, usefulness, usability, signal-to-noise ratio or quality of all or any part of our sites and services in 
                            any manner are explicitly prohibited.
                        </p>
                        <p>
                            Any attempt (whether or not successful) to engage in, or to enable, induce, encourage, cause or assist anyone else to engage in, any of the above unauthorized and prohibited access and 
                            activities is also explicitly prohibited and is a violation of these Terms of Use.
                        </p>
                        {/* 5. Interactions with Others  */}
                        <h2>5. Interactions with Others </h2>
                        <p>
                            We and our representatives are not parties to, have no involvement or interest in, make no representations or warranties as to, and have no responsibility or liability with respect to any communications, 
                            transactions, interactions, disputes or any relations whatsoever between you and any other user, person or organization ("your interactions with others"). You must conduct any necessary, appropriate, prudent or 
                            judicious investigation, inquiry, research or due diligence with respect to your interactions with others. 
                        </p>
                        <p>
                            The sites or services may provide links to other websites and online resources. Because we have no control over such websites and resources, you acknowledge and agree that we, including our members of the Association, 
                            affiliates, employees, officers, directors, agents, representatives, licensors, suppliers and service providers, (collectively, "we") are not responsible for the availability of such external websites or resources and we 
                            neither endorse nor are responsible or liable for, and make no representations or warranties regarding, the identity or trustworthiness of the third-party website or resources, including any content, advertising, products, 
                            services, or other materials on or available through such websites or resources. Other websites may provide links to our sites with or without authorization. You acknowledge and agree that we do not endorse such websites, 
                            and are not and shall not be responsible or liable for any links from those websites to our sites, any content, advertising, products or other materials available on or through such other websites, or any loss or damages 
                            incurred in connection therewith. Your use of third-party websites and resources, including without limitation, your use of any content, information, data, advertising, products or other materials on or available through such 
                            websites and resources, is at your own risk and is subject to the terms and conditions of use and privacy policy applicable to such websites and resources. We shall have the right, at any time and in our sole discretion, to 
                            block links to our sites through technological or other means without prior notice.
                        </p>
                        {/* 6. Intellectual property  */}
                        <h2>6. Intellectual property</h2>
                        <p>
                            We have exclusive copyrights on the site and services, as well as on certain elements that make them, such as trademarks, logo, text, audio and visual elements, visual identity, photos, data and databases, programmatic code and other 
                            elements of the site. Utilization of the aforementioned copyright objects without the express prior written consent of the Association will be considered a violation of copyright, and the we will be empowered to use all available legal 
                            means to exercise that right.
                        </p>
                        <p>
                            The site also contains elements on which other persons, such as users of services, business partners, advertisers, have exclusive copyright and other intellectual property rights. Those persons have sole responsibility for the content on 
                            which they hold the rights, regardless of the fact that such content is on our site. By posting content on this site, you agree that it will become visible to each visitor of the site.
                        </p>
                        <p>
                            We don’t have copyrights on items our registered users upload on the site, our registered users do, so we cannot take responsibility for the quality, safety or legality of the items.
                        </p>
                        <p>
                            Further transfer, reproduction, public display or distribution of the content or part of the content from any part of the site is allowed only with the obligatory explicit consent of the copyright holder.
                        </p>
                        <p>
                            By accepting these Terms of use, you explicitly represent and warrant that by posting on the site content (including but not limited to photographs, text, audio or visuals, program codes) you do not infringe any third-party copyrights, 
                            and accept that you are solely responsible for any copyright infringement of third-party rights by posting content on the site.
                        </p>
                        <p>
                            When you buy an item, you acquire the right to use that item; you're not actually acquiring the item itself. What you get includes a license directly from the author to use that item. The author retains the copyright and is authorized to transfer 
                            the right of use on item you bought to third parties, unless you agree with the author that you acquire the exclusive right of use. You are not allowed to transfer right of use to any third-party, unless explicitly agreed by the author.
                        </p>
                        <p>
                            Failure to comply with the Terms of use, ie the unauthorized use of the Content, including but not limited to duplication, copying, broadcasting, making available in any other way, by any medium, is a violation of these rights in accordance with 
                            the positive regulations in force in the Republic of Serbia and is subject to civil and criminal liability.
                        </p>
                        {/* 7. Payment  */}
                        <h2>7. Payment</h2>
                        <p>
                            You are responsible for any payment applicable to content that you post or other features, products, services or licenses you purchase or that are purchased through your account. You authorize us or our designated payment processor to 
                            charge your specified credit card, debit card or other payment method for such fees.
                        </p>
                        <p>
                            Prices on the site will be expressed in euros, and payment will be made exclusively in dinars at the middle exchange rate of the 
                            National Bank of Serbia. Any applicable sales or other taxes are additional to the stated fee. Currency 
                            exchange settlements and foreign transaction fees are based on your agreement with your credit card or other 
                            payment method provider. Except as required by law or otherwise stated on the site or in the services, all payments 
                            are nonrefundable, and payments and purchases may not be canceled by the user. However, we reserve the right to refuse or 
                            terminate any purchase or attempted purchase at any time at our sole discretion.
                        </p>
                        <p>
                            Prices on the site are expressed in euros, but all payments are made exclusively in dinars at the middle exchange 
                            rate of the National Bank of Serbia on the day of payment.
                        </p>
                        <h2>7a. Limitations</h2>
                        <p>
                            Transactions in which amounts exceed EUR 5,000.00 can be frozen due to financial reasons. If you need to manage a transaction over 
                            EUR 5.000,00, please contact us directly.
                        </p>
                        <h2>7b. Tax policy</h2>
                        <p>
                            The user who gains money or goods is bound with their own national taxes. Association Youth Cultural Center of Vojvodina is 
                            not a party in purchase or any other contract between users, and therefore cannot be held responsible for paying any taxes.
                        </p>
                        <h2>Conversion Statment</h2>
                        <p>
                            All payments will be effected in Serbian currency – dinar (RSD). The amount your credit 
                            card account will be charged for is obtained through the conversion of the price in Euro into 
                            Serbian dinar according to the current exchange rate of the Serbian National Bank. When charging 
                            your credit card, the same amount is converted into your local currency according to the exchange 
                            rate of credit card associations. As a result of this conversion there is a possibility of a 
                            slight difference from the original price stated in our web site.
                        </p>
                        {/* 8. Indemnification */}
                        <h2>8. Indemnification</h2>
                        <p>
                            You agree to indemnify and hold us and our representatives harmless from and against any third-party claim, cause of action, 
                            demand or damages related to or arising out of: (a) content that you post or transmit (including but not limited to content that a 
                            third-party deems defamatory, infringement of its copyright, or otherwise harmful or offensive); (b) activity that occurs through or 
                            by use of your account (including, without limitation, all content posted or transmitted and your interactions with others); (c) your 
                            use of or reliance on any user content; and (d) your violation of these Terms of Use. This indemnification obligation includes payment 
                            of any attorneys' fees and costs incurred by us or our representatives. We reserve the right, at our own expense, to assume the exclusive 
                            defense and control of any matter otherwise subject to indemnification by you, and you agree to cooperate with our defense of these claims.
                        </p>
                        {/* 9. Disclaimers  */}
                        <h2>9. Disclaimers</h2>
                        <p>
                            Your access to, use of and reliance on our sites and services and content accessed through our sites and services is entirely at your own risk. 
                            Our sites and services (including, without limitation, the websites, programs, services, forums and content accessed through the websites, programs, 
                            services and forums) is provided on an "as is" or "as available" basis without any warranties of any kind. All express and implied warranties 
                            (including, without limitation, warranties of merchantability, fitness for a particular purpose, and non-infringement od proprietary rigrts) 
                            are expressly disclaimed. Without limiting the foregoing, we also disclaim all warranties for or with respect to: (a) the security, reliability, 
                            timeliness, accuracy and performance of our sites and services and content accessed through our sites and services; (b) computer worms, viruses, 
                            spyware, adware and any other malware, malicious code or harmful content or component accessed , received or disseminated through, related to or 
                            as a result of our sites and services or content accessed through our sites and services and /or (c) any transactions or potential transactions, 
                            goods or services promised or exchanged, information or advice offered or exchanged, or other content, interactions, representations or communications 
                            through, related to or as a result of use of our sites and services or content accessed through our sites and services (including, without limitation, 
                            accessed through any links on our sites and services or in content). We disclaim any responsibility to warrant that the content uploaded is acute or 
                            up-to-date. Simultaneously we do not warrant that the application will be uninterrupted or free of other harmful components. We will not bear any cost 
                            of repairing or correct at the event of any loss or damages sprung up from the utilization of the service from our sites.
                        </p>
                        <p>
                            We do not guarantee the accuracy, reliability and timeliness of the information that users enter when registering on the site, and the you agree by accepting these 
                            Terms of use that we are not in any way responsible for direct or indirect damage caused by entering incorrect information by another user or any third party.
                        </p>
                        <p>
                            In accordance to Criminal Code of the Republic of Serbia submitting inaccurate data, mistakes made during submitting accurate data or any other act of 
                            concealing or falsely representing details, resulting in manipulation of the electronic processing and transfer of data, with the aim of acquiring illegal 
                            financial gain or causing harm to oneself or another person, will be fined or punished by imprisonment for up to three years.
                        </p>
                        <p>
                            We are not responsible for any content posted or shared by users (including but not limited to photographs, texts, audio and visual recordings, codes), 
                            as we do not initiate the upload of user content, we do not select the user content that is uploaded, do not exclude or modify data in the user content, 
                            nor do we select the recipient of the user content. We cannot take responsibility for the quality, safety or legality of the items. We do not warrant that 
                            the items or any users content, photographs audio and visuals, code, data or materials available on or via our site do not infringe the intellectual property 
                            rights of a third party. Each author provides promises to you directly when you purchase their item.
                        </p>
                        <p>
                            When registering, users create their own account with an access code and are solely responsible for accessing their account. We are not responsible 
                            if the user suspects unauthorized use of their access data.
                        </p>
                        <p>
                            By accepting these Terms of use, you agree that we cannot be responsible for the behavior of users or third parties, and that the risk of 
                            possible harm is entirely borne by those persons, in accordance with the applicable legislation of the Republic of Serbia.
                        </p>
                        <p>These disclaimers will apply to the fullest extent permitted by the law.</p>
                        {/* 10. Limitations of Liability  */}
                        <h2>10. Limitations of Liability</h2>
                        <p>
                            We and our representatives will under no circumstances be liable for any access to, use of or reliance on our sites and services or content 
                            accessed through our sites and services by you or anyone else, or for any transactions, communications, interactions, disputes or relations 
                            between you and any other person or organization arising out of or related to our sites and services or content accessed through our sites and 
                            services, including but not limited to liability for injunctive relief as well as for any harm, injury, loss or damages of any kind incurred by 
                            you or anyone else (including, without limitation, direct, indirect, incidental, special, consequential, statutory, exemplary or punitive damages, 
                            even if we or our representative has been advised of the possibility of such damages). This limitation of liability applies regardless of, but is 
                            not restricted to, whether the alleged liability, harm, injury, loss or damages arose from authorized or unauthorized access to or use of our sites 
                            and services or content accessed through our sites and services; any inability to access or use our sites and services or content accessed through 
                            our sites and services, or any removal, deletion, limitation, modification, interruption, suspension, discontinuance or termination of our sites 
                            and services or content accessed through our sites and services. 
                        </p>
                        <p>
                            Nothing in these Terms of use will affect the statutory rights of any consumer. These limitations will also apply with respect to damages resulting 
                            from any transactions or potential transactions, goods or services promised or exchanged, information or advice offered or exchanged, or other content, 
                            interactions, representations, communications or relations through, related to or as a result of our sites and services or content accessed through our 
                            sites and services (including, without limitation, any links on our sites and services and links in content accessed through our sites and services).
                        </p>
                        <p>
                            In no event will our or our representative’s liability in connection with access to, use of or reliance on our sites and services or content accessed 
                            through our sites and services by you exceed the amounts paid by you to us, if any. Total aggregate liability arising out of or related to this agreement will 
                            not exceed the amounts paid by you during the six (6) months preceding the bringing of any claim, or five hundred Euros (EUR 500.00), whichever is less. 
                            You acknowledge and agree that if you have not paid provider any amounts in the six (6) months preceding the bringing of any claim, your sole and exclusive remedy 
                            for any dispute is to stop using our sites and services and to cancel your account, if any.
                        </p>
                        <p>
                            You hereby release us and each of our representatives, and their respective subsidiaries, affiliates, successors, predecessors, assigns, heirs, service providers and 
                            suppliers, from all claims, demands and damages of every kind and nature, known and unknown, direct and indirect, suspected and unsuspected, disclosed and undisclosed, 
                            arising out of or in any way related to our sites and services or content accessed through our sites and services, or any interactions with others arising out of or 
                            related to our sites and services or content accessed through our sites and services. These limitations will apply to the fullest extent permitted by law.
                        </p>
                        {/* 11. Damages */}
                        <h2>11. Damages</h2>
                        <p>
                            At our election, we may seek actual or liquidated damages. In addition to any injunctive relief, you agree to pay us the total amount of all actual damages (including 
                            but not limited to direct, indirect, consequential and incidental damages) caused by any violation of these Terms of use for which you bear responsibility; except you 
                            acknowledge that, for certain violations, actual damages would be extremely difficult or impossible to quantify. Consequently, for such Terms of use violations, you agree 
                            to pay liquidated damages to us. Furthermore, you agree that the amounts of liquidated damages described herein are reasonable estimates of our damages for such violations, 
                            and that liquidated damages for violations of these Terms of Use are and will be cumulative.
                        </p>
                        <h2>11b. Liquidated Damages</h2>
                        <h3>1. EUR 100.00 Per Account/Instance.</h3>
                        <p>
                            You agree to pay us Liquidated Damages in amount of EUR 100.00 per Account/Instance of Unauthorized Conduct: (a) if you create, maintain or use at any time more than one Account 
                            to post content; (b) if you create one or more Accounts for or on behalf of another; (c) if you permit, enable, induce or encourage someone else to create one or more Accounts on your behalf; 
                            (d) if you use other users' Accounts (with or without their knowledge or consent) (including but not limited to your use of any Account you purchase or otherwise do not personally create); (e) for any 
                            Account you purchase, offer, market, sell, or distribute; (f) for any Account you create for another; (g) for any Account you create by any automated means; (h) if you use any Accounts created in 
                            circumvention of any technological restriction or security measure in the Account creation process; (i) if you create any Accounts in circumvention of any technological restriction or security measure in the 
                            Account creation process; (j) for any product, software, or tool (including but not limited to CAPTCHA credits) you acquire, purchase, offer, market, sell, or distribute, that facilitates circumvention of any 
                            technological measure implemented by us to restrict the manner in which content may be posted on the service or to regulate the manner in which content may be transmitted to other users; (k) if you collect users' 
                            personal information (including but not limited to email addresses, IP addresses and telephone numbers), or (l) for any activities (including but not limited to posting voluminous content) that are inconsistent with 
                            the use of the service in compliance with the Terms of use or that may impair or interfere with the functionality, performance or quality of all or any part of the service in any manner.
                        </p>
                        <h3>2. EUR 1,000.00 Per Item of Content</h3>
                        <p>
                            You agree to pay us Liquidated Damage in amount of EUR 1,000.00 per Item of Content that contains: (a) illegal content; (b) content that facilitates the creation, advertising, distribution, provision or receipt of illegal 
                            goods or services; (c) offensive content (including, without limitation, defamatory, threatening, hateful or pornographic content); (d) content that discloses another's personal, confidential or proprietary information; (e) 
                            content that infringe the intellectual property rights of someone else (f) false or fraudulent content (including but not limited to false, fraudulent or misleading responses to user ads transmitted via the service); 
                            (g) malicious content (including, without limitation, malware or spyware); or (h) content that offers, promotes, advertises, provides links to or solicits posting or auto-posting products or services, account creation or 
                            auto-creation products or services, bulk telephone numbers, or any other product or service that if utilized with respect to the service would violate the Terms of use or our other legal rights.
                        </p>
                        {/* 12. Warranties authors make */}
                        <h2>12. Warranties authors make</h2>
                        <p>
                            By using site and service and accepting this Terms of use, author of item accept to warrant to you that: (a) the item is of acceptable quality and fit for the purpose for which it is 'sold'; (b) the item matches the description 
                            given by the author; (c) they will honor any express warranties given to you that are not contained in these terms; (d) they have all applicable intellectual property rights, including but not limited to copyright on the item; 
                            (e) your use of the item you bought does not infringe the intellectual property rights of someone else; (f) the item and its description are not false, inaccurate, misleading, fraudulent, unlawful or defamatory; (g) the item 
                            and its description do not violate any applicable law or regulation (including those governing export control, consumer protection, unfair competition, criminal law, pornography, anti-discrimination, trade practices or fair trading); 
                            (h) the item does not contain viruses or other computer codes, files or programs which are designed to limit or destroy the functionality of other computer software or hardware; (j) if it occurs that one or more of warranties given by the 
                            author is false or untrue, they accept and agree to pay you the total amount of all actual damages (including but not limited to direct, indirect, consequential and incidental damages) caused by their violation of these Terms of use.
                        </p>
                        {/* 13. Injunctive Relief */}
                        <h2>13. Injunctive Relief</h2>
                        <p>
                            You acknowledge and agree that any violation or breach of these Terms of use may cause us immediate and irreparable harm and damages. Consequently, notwithstanding any other provision of these Terms of Use or other applicable legal requirements, 
                            we have the right to, and may in our discretion, immediately obtain preliminary injunctive relief (including, without limitation, temporary restraining orders) and seek permanent injunctive relief regarding any violation or breach of these Terms of use. 
                            In addition to any and all other remedies available to us in law or in equity, we may seek specific performance of any term of these Terms of use, including but not limited to by preliminary or permanent injunction.
                        </p>
                        {/* 14. Release of Information */}
                        <h2>14. Release of Information</h2>
                        <p>
                            Our Privacy Policy prohibits the release of user or account information except in limited circumstances, including with express permission from the user, as and when required or permitted by law, or to comply with legal process properly served on us. 
                            If you seek the identity or account information of a user of our sites and services in connection with a civil legal matter, you must serve us with a valid subpoena.
                        </p>
                        <p>
                            We reserve the right to disclose any personal information about you or your use of our sites and services, including its contents, without your prior permission if we have a good faith belief that such action is necessary to: (1) conform to legal requirements or 
                            comply with legal process; (2) protect and defend our rights and property or our affiliated companies; (3) enforce these Terms of use; or (4) act to protect the interests of our users or others. Our performance of these Terms of use is subject to existing laws and legal process, 
                            and nothing contained in this Terms of use is in derogation of our right to comply with governmental, court and law enforcement requests or requirements relating to your use of our sites and services or information provided to or gathered by us with respect to such use.
                        </p>
                        {/* 15. Personal Information that You Provide to Us */}
                        <h2>15. Personal Information that You Provide to Us</h2>
                        <p>
                            ‘’Personal data’’ means any information relating to an identified or identifiable natural person. An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, 
                            location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person. When you interact with our sites and services, depending on the site or service, we may collect the 
                            following personal information directly from you:
                        </p>
                        <p>
                            <b>Account or Registration Information</b> where needed to use our sites and services, and may include your name, residence information, email address, 
                            telephone number, birthday, user account name, and password.
                        </p>
                        <p>
                            <b>Contact Information</b>, which generally includes your name, addresses, email addresses, personal identification number, social media website 
                            user account names, and/or telephone numbers.
                        </p>
                        <p>
                            <b>Payment Information</b> where needed to process payments and generally includes your credit or debit card number, expiration date, and the card 
                            verification number, and bank account number, SWIFT code or other payment instructions.
                        </p>
                        <p>
                            <b>Health Information</b> bearing in mind that this site is intended for blind authors, we may also seek medical records to prove the author's 
                            medical condition.
                        </p>
                        {/* 16b. Other Information We Automatically Collect through Cookies and Other Technologies */}
                        <h2>16b. Other Information We Automatically Collect through Cookies and Other Technologies</h2>
                        <p>
                            We or our third-party service providers may collect and store certain technical information when you use our sites and services. For example, our servers receive and automatically 
                            collect information about your computer and browser, including, for instance, your IP address, browser type, domain name from which you accessed the site or service, device size and other 
                            software or hardware information. If you access our sites and services from a mobile or other device, we may collect a unique device identifier assigned to that device (UDID), type of device, 
                            general GPS location, or other transactional information for that device in order to serve content to it and to improve your experience in using the sites or services.
                        </p>
                        <p>
                            In addition, we or our third-party service providers may collect information about how you use of our sites, including but not limited to, the date and time you visit the sites, the areas or pages 
                            of the sites that you visit, the amount of time you spend viewing the sites, the number of times you return to the sites, visits to sites outside our network, preferred language, and other click-stream data. 
                        </p>
                        <h3>Cookies</h3>
                        <p>
                            We or our third-party service providers may use cookies and other tracking technologies (including browser cookies, pixels, beacons, mobile application identifiers, and Adobe Flash technology) to help us 
                            recognize you across different sites and services, improve your experience, increase security, measure use and effectiveness of our services, and serve advertising. You can control cookies through your browser 
                            settings and other tools. For more information about our use of cookies, please read our Cookie Policy. 
                        </p>
                        <p> 
                            By visiting our site and services, you consent to the placement of cookies and beacons in your browser and HTML-based emails in accordance with this privacy policy and our Cookie Policy.
                        </p>
                        <p>
                            We currently do not respond to "Do Not Track" browser signals. Accordingly, your navigation on our sites and services may be tracked as part of our efforts to gather user information described above. 
                            If you arrive at our sites and services by way of a link from a third-party site that does respond to “Do Not Track” browser signals, such “Do Not Track” browser signal recognition will end as soon as you reach our sites and services. 
                        </p>
                        <p>
                            We may use information collected from you through cookies and other tracking technologies in one or more of the following ways:
                        </p>
                        <ul>
                            <li>to remember you when you return to our sites; </li>
                            <li>to understand and analyze trends, to monitor usage, and learn about user behavior;</li>
                            <li>to gather demographic information about our user base as a whole;</li>
                            <li>to customize ads, content, or offers on our sites and services; and</li>
                            <li>to conduct market research and measurement in order to improve our sites, content, and services and to make our sites, content, and services more useful for users.</li>
                        </ul> 
                        {/* 17. Accessing and Updating Personal Information */}
                        <h2>17. Accessing and Updating Personal Information</h2>
                        <p>
                            We encourage you to keep your personal information up-to-date and accurate. The methods for accessing, viewing, correcting, and deleting your personal information will depend 
                            on which sites or services you use and their features. You have several choices; for instance:
                        </p>
                        <ul>
                            <li>to view and change the personal information that you directly provided to us, you can return to the web page on our site where you originally submitted the data and follow the instructions on that web page;</li>
                            <li>to correct or update your account information, you can log into the site or service where you are registered and navigate to your account;</li>
                            <li>to close your account, you can log into the site or service where you are registered and navigate to your account;</li>
                            <li>to change your email preferences, you can visit the email preferences page for the relevant site or follow the opt-out or unsubscribe instructions included in each email.</li>
                        </ul>  
                        {/* 18. Linked Websites and Services */}
                        <p>
                            Our sites and services may link to other sites or services operated by our affiliates or third parties, and may carry advertisements or offer content, functionality, newsletters, contests or sweepstakes, or 
                            applications developed and maintained by third parties. We do not exercise control over third party sites or services. We are not responsible for the privacy practices of any such third parties. Once you leave our 
                            sites or services via a link, enable a third-party service, or click an advertisement, you should check the applicable privacy policy of the third-party's site or service. The fact that we link to a website or 
                            present a banner ad or other type of advertisement is not an endorsement, authorization, or representation of our affiliation with that third party nor is it an endorsement of their privacy or information security 
                            policies or practices.
                        </p>
                        <p>
                            We may also provide social media features on our sites and services that enable you to share personal information with your social network(s) and to interact with our sites and services. Depending on the features, 
                            your use of these features may result in the collection or sharing of personal information about you. We encourage you to review the privacy policies and settings on the social media site(s) with which you interact.
                        </p>
                        {/* 19. Cookie Policy */}
                        <h2>19. Cookie Policy</h2>
                        <p>We believe in being clear and open about how we use your information. This policy provides detailed information about how and when we use cookies.</p>
                        <h3>How We Use Cookies</h3>
                        <p>
                            We or our third-party service providers may use cookies and other tracking technologies (including browser cookies, pixels, beacons, mobile application identifiers, 
                            and Adobe Flash technology) to help us recognize you across different sites and services, improve your experience, increase security, measure use and effectiveness of 
                            our services, and serve advertising. By continuing to use our sites or services, you are agreeing to the use of cookies and similar technologies for the purpose we describe in this policy.
                        </p>
                        <h3>What is a Cookie?</h3>
                        <p>
                            A cookie is a small file that is placed onto your device and that is managed by your browser. Cookies were designed for websites to remember information (such as items in a shopping cart) and 
                            enable our sites or services to function. For example, cookies enable us to identify your device and enable you to easily share content on our sites and services and help us serve relevant ads to you.
                        </p>
                        <h3>When We Use Cookies</h3>
                        <p>
                            We use cookies on our sites and services, including mobile applications. Any browser loading these sites will receive cookies from us. We may also place cookies in your browser when you visit non-Internet 
                            Brands sites that host our plugins. However, unless you register with us, cookies do not contain any personally identifiable information about you and therefore cannot be used to identify you personally.
                        </p>
                        <h3>The Types of Cookies We Use</h3>
                        <p>
                            We use two types: persistent cookies and session cookies. A persistent cookie helps us recognize you as a user. After you sign in, a persistent cookie stays in your browser and will be read by our site when you 
                            return to the site. Session cookies only last for as long as the session (usually the current visit to a site or a browser session). We also have implemented and use Google Analytics Display Advertising, 
                            specifically the Demographics and Interest Reporting feature to advertise online, sell advertising, and develop content. We also may use Adobe Flash, which is another technology that can provide cookie-equivalent functionality. 
                            Adobe Flash is capable of storing information on your device (in a file outside of your browser).
                        </p>
                        <h3>How Cookies Are Used for Advertising Purposes</h3>
                        <p>
                            Cookies combined with ad technology such as web beacons, pixels, and anonymous ad network tags help us serve relevant ads to you more effectively. They also help us collect aggregated auditing, research, and 
                            reporting for advertisers. Pixels, which are invisible tags placed on our websites, help us understand and improve our service, show you ads, and know when content has been shown to you. Please note that 
                            because our pages include advertisements, your web browser may request advertisements and web beacons directly from ad network servers, and these networks can view, edit, or set their own cookies, just as if 
                            you had requested a web page from their site. Although we do not use cookies to track your browsing behavior on third-party sites, we use aggregate data from third parties and data from sites and services you 
                            have used to show you relevant, interest-based advertising. We do not provide any personal information that we collect to advertisers.
                        </p>
                        <h3>How You Can Manage Cookies</h3>
                        <p>
                            You can set your browser to warn you about attempts to place cookies on your computer or limit the type of cookies you allow. However, if you limit the ability of our sites and services to set cookies, 
                            you may limit your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings such as login information. Please note that companies 
                            delivering advertisements on our sites may also use cookies or other technologies, and those practices are subject to their own policies. You can opt-out of Google Analytics for Display Advertising 
                            and customize Google's Display Network ads using Google's <a href="https://www.google.com/settings/ads">Ad Settings</a>. You can opt-out of Google Analytics by using <a href="https://tools.google.com/dlpage/gaoptout/">Google Analytics Opt-out Browser Add-on</a>. Please note that not all browsers provide the 
                            ability to remove Adobe Flash "cookies" (also called "locally stored objects," or "LSOs"). You can restrict or block Adobe Flash cookies/LSOs through the <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html">Adobe website</a>. You can opt out of being targeted 
                            by certain third party advertising companies online by visiting the <a href="http://www.networkadvertising.org/managing/opt_out.asp">Network Advertising Initiative</a>, Adobe, and/or <a href="http://aboutads.info/choices/">Digital Advertising Alliance</a>. To find out more about cookies, including how to see what 
                            cookies have been set and how to manage and delete them, visit <a href="https://www.allaboutcookies.org/">www.allaboutcookies.org</a>, or <a href="https://www.aboutcookies.org/">www.aboutcookies.org</a>.
                        </p>
                        {/* 20. Children's and Minor's Privacy */}
                        <h2>20. Children's and Minor's Privacy</h2>
                        <p>
                            Children under the age of 14 are not permitted to use our site and services. We do not knowingly collect personal information from children under the age of 14 or utilize plug-ins or ad networks that collect personal information through child-directed third-party websites or online services. 
                            If we learn that we have collected personal information from a child under 14, we will take steps to promptly delete such information. 
                        </p>
                        {/* 21. International Users */}
                        <h2>21. International Users</h2>
                        <p>
                            Our headquarters are in Serbia. Our sites and services are hosted and administered in Serbia or hosted through cloud service providers who are headquartered in Serbia and are intended for users in Serbia. If you are located outside Serbia, be aware that the 
                            information you provide to us or that we obtain as a result of your use of our sites and services may be processed in, transferred to, and stored in Serbia and will be subject to Serbian law. The privacy and data protection laws of Serbia may be different 
                            from the laws of your country of residence. 
                        </p>
                        <p>
                            By using our site and services or providing us with your information, you consent to the transfer of your information to Serbia for processing and storage.
                        </p>
                        {/* 22. Personal data protection */}
                        <h2>22. Personal data protection</h2>
                        <p>
                            Association Youth Cultural Centre of Vojvodina, whose site you use, respects your right to privacy, and therefore we inform you with this Privacy Policy what your personal information is, why we collect it, 
                            how we use it, how we handle it, as well as what your rights are and how you can exercise them. We ensure that the personal data of users are processed in accordance with the applicable provisions of the 
                            regulations of the Republic of Serbia and in accordance with the applicable provisions of the Directive of the European Union. The privacy policy applies to all services which involves the processing of 
                            personal information. Our Privacy Policy lets you know how we handle the personal information of users of our services when you use the site and  above all who is the administrator of your personal information, 
                            what information we process and for what purpose, on what basis do we process data, how long we process the data, what are our users' rights in terms of processing personal information and  who we share data with.
                        </p>
                        <p>
                            The administrator of your personal data is Association Youth Cultural Centre of Vojvodina , Staparska 10c street, Sombor, Serbia, with  registration number: 08795827 , which is a data controller and processor, and 
                            whose site you use. By agreeing to these Terms of Use, you give consent Association Youth Cultural Centre of Vojvodina. to collect and process the collected personal data and to decide on the methods of processing your personal data.
                        </p>
                        <p>
                            We may collect and process your personal information for the purpose to allow access to certain content, when creating a User Account and to fulfill legal obligations.
                            When you visit and use our site solely for informational purposes, that is, unless you register or otherwise provide us with your personal information, we collect only your personal information 
                            transmitted by your internet browser, which is technically necessary to enable you to display our site and provide stability and security.
                            Only after your express consent to the use of the data, your data will be stored and used for the purposes stated in this Privacy Policy. The data is stored on hard disk of our server, 
                            they are encrypted, and only our authorized users have access to the data.
                        </p>
                        <p>
                            The purpose of collecting the above-mentioned personal data is to create a User account on the <a href="http://www.soundsy.net/">www.soundsy.net</a> site, as well as to pursue the 
                            legitimate interest of the Association - sending advertising notices. This information is collected when creating a User account.
                        </p>
                        <p>
                            The legal basis for the processing of personal data are your consent as data subjects, fulfillment of the contractual obligation and the legitimate interest of the personal data controller, 
                            referred to in Article 12 of the Law on Protection of Personal Data, applicable in the Republic of Serbia.
                        </p>
                        <p>
                            Your data will be processed until: 1) in the case of giving consent up to the moment of revocation of consent for processing, restrictions or other 
                            action limiting the given consent, 2) if we need to use your information to fulfill the contract, until the expiration of the contractual obligation 
                            and 3) if the basis for data processing is our legitimate interest, until the submission of the objection or revocation of consent.
                        </p>
                        <p>
                            In accordance with the provisions of the General Data Protection Regulation (GDPR) (Regulation of the European Parliament and of the Council (EU) 2016/679 applicable from 25.05.2018) 
                            and the Law on Personal Data Protection of the Republic of Serbia ("Official Gazette of the RS", no. 87/2018), you have the following rights with respect to the processing of personal data by us:
                        </p>
                        <span>- the right to access your data, including obtaining a copy of the data,</span><br></br>
                        <span>- the right to request rectification of data,</span><br></br>
                        <span>- the right to delete data,</span><br></br>
                        <span>- the right to file a complaint with the supervisory authority for the protection of personal data,</span><br></br>
                        <span>- the right to restrict data processing.</span>
                        <p>
                            If your data is processed based on consent, you have the right to revoke your previously given consent. Withdrawal of consent does not affect the legality of processing 
                            performed on the basis of consent prior to your revocation.
                        </p>
                        <p>
                            If your data is processed on the basis of consent or for the purpose of the contract (required for the provision of the service), you have the right to transfer personal data. 
                            A person's right to data portability means the right to obtain your personal data that you previously provided to the controller in a commonly used and electronically readable form, 
                            and you have the right to pass such data to another controller.
                        </p>
                        <p>
                            You have the right to correct your personal data, or you have the right to apply for the correction of your incorrect personal data, as well as the right to supplement incomplete personal data, 
                            including by making an additional statement.
                        </p>
                        <p>
                            The right to delete (the "right to forget") signifies your right to delete your personal information in the following cases: 1) when your personal information is no longer necessary for the purpose 
                            for which it was collected or otherwise processed; 2) when you revoke the consent on the basis of which the processing of personal data and special personal data was performed for one or more processing purposes, 
                            and there is no other legal basis for processing; 3)  when you object to the processing of data in accordance with the Law, and there is no other legal basis for processing that outweighs the legitimate interest, 
                            right or freedom of the data subject, 4) when your personal information has been illegally processed and 5) when we must delete personal data in order to comply with our legal obligations in accordance with the law 
                            of the Republic of Serbia.
                        </p>
                        <p>
                            You have the right to restrict the processing of your personal information in the following cases: 1) when you dispute the accuracy of personal data, we will limit processing to a period that allows us to verify the accuracy 
                            of personal data; 2)  when the processing of your personal data is illegal and you oppose the deletion of data and instead seek a restriction on their processing; 3)  when there is no longer a need to process your personal data, 
                            and you request that we continue to process it in order to file, achieve or defend your legal claims; 4)  when you object to processing pursuant to Article 37, paragraph 1 of the Law on Personal Data Protection, expecting confirmation 
                            that there are legal reasons for processing personal data that weigh over your interests, rights or freedoms or are related to the submission, exercise or by defending a legal claim.
                        </p>
                        <p>
                            You have the right to object, which means you have the right to object to the Association for processing personal data. When you file a complaint, the Association is no longer permitted to process your personal information unless there are 
                            legal reasons for processing that outweigh your interests, rights or freedoms, or are related to the filing, exercise or defense of a legal claim.
                        </p>
                        <p>
                            We will process your request to exercise rights regarding your personal information within 30 days. This time limit may, in justified cases, be extended by another 60 days. The Supervisory Body for the Protection of Personal Data in the Republic 
                            of Serbia is the Commissioner for Information of Public Importance and the Protection of Personal Data, Boulevard of King Alexander 15, Belgrade (hereinafter: the Commissioner).
                        </p>
                        <p>
                            You have the right to file a complaint with the Commissioner if you believe that the data processing was performed contrary to the provisions of the Act. Filing a complaint with the Commissioner does not 
                            affect the right to initiate other administrative or judicial protection proceedings. The Commissioner is obliged to inform you about the course of the proceedings he is conducting, the results of the proceedings, 
                            as well as the right of the person to initiate court proceedings in accordance with Article 83 of the Law. The complaint shall be submitted in writing, directly or electronically on the Complaint form, by mail to 
                            the above address or by email: <a href="mailto:prituzba@poverenik.rs">prituzba@poverenik.rs</a>.
                        </p>
                        <p>
                            If you have any questions or concerns about this privacy policy or our practices you can <a href="https://www.internetbrands.com/privacy/privacy-contact-form.php">contact us</a> or write to us at <a href="mailto:privacy@usesound.net">privacy@usesound.net</a>. Please be sure to identify the specific website, software, 
                            or service about which you have a question or concern and how we can contact you.
                        </p>
                        {/* 23. Changes to This Privacy Policy */}
                        <h2>23. Changes to This Privacy Policy</h2>
                        <p>
	                        We may revise this privacy policy from time to time and, if we do, we will update it on this page and modify the "Last Updated" date. If our information practices change 
                            in a significant way, you will be provided notice by means of notice on our sites or otherwise in writing. Because our privacy policy can change at any time, we encourage 
                            you to reread it periodically to see if there have been any changes that affect you. If you disagree with any changes to this privacy policy and do not wish your information to be 
                            subject to the revised privacy policy, you will need to deactivate your account or terminate your services. Your use of our sites and services following any such change constitutes your 
                            agreement that all information collected from or about you through our sites and services after the revised privacy policy is posted will be subject to the terms of the revised privacy policy.
                        </p>
                        {/* 24. Electronic Communication */}
                        <h2>24. Electronic Communication</h2>
                        <p>
                            When you use our sites and services, or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. 
                            We will communicate with you by email or by posting notices on our sites and services. You agree that all agreements, notices, disclosures and other communications 
                            that we provide to you electronically satisfy any legal requirement that such communications be in writing.
                        </p>
                        {/* 25. Termination; No Assignment */}
                        <h2>25. Termination; No Assignment</h2>
                        <p>
                            You may terminate your account and/or stop using our sites and services at any time. We may, in our sole discretion, terminate or suspend your access to all or part of our 
                            sites and services for any reason, including, without limitation, violation of these Terms of Use and/or if we have reasonable grounds to suspect that you have violated 
                            these Terms of use. These Terms of use and the rights granted, and obligations undertaken, may not be transferred, assigned or delegated by you. Any purported attempt will be ineffective. 
                            We retain the right to transfer, assign or delegate these Terms of use.
                        </p>
                        {/* 26. Severability */}
                        <h2>26. Severability</h2>
                        <p>
                            If any provision of those Terms of use is declared by a court of competent jurisdiction to be invalid or unenforceable, the remaining provisions of the Terms of use will continue in full force and effect. 
                            The nullity of any provision of those Terms of use will not affect the remainder of thereof.
                        </p>
                        {/* 27. Jurisdiction and applicable law */}
                        <h2>27. Jurisdiction and applicable law</h2>
                        <p>
                            You hereby agree that all disputes will solve in an amicable manner. Otherwise, you will submit all disputes related to or as a result of use of our sites and services or content accessed through our 
                            sites and services (including, without limitation, accessed through any links on our sites and services or in content) to the exclusive jurisdiction of the competent court in the Republic of Serbia. 
                            Consequently to this, you hereby agree that on all disputes related to or as a result of the use of our sites and services or content accessed through our sites and services 
                            (including, without limitation, accessed through any links on our sites and services or in content) apply regulations of the Republic of Serbia.
                        </p>
                        {/* 28. Feedback */}
                        <h2>28. Feedback</h2>
                        <p>
                            You automatically grant and assign to us, a royalty-free, worldwide, irrevocable, perpetual license to use and incorporate into our sites and services any suggestions, 
                            enhancement requests, recommendations or other feedback provided by you.
                        </p>
                        {/* 29. The entry into force */}
                        <h2>29. The entry into force</h2>
                        <p>
                            These Terms of use enter into force the day after they are posted on website <a href="http://www.soundsy.net/">www.soundsy.net</a>.
                        </p>
                        <p>
                            These Terms of use in accordance with its authority are issued and signed by the legal representative of the Association Ms. Zeljka Milosevic.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

}

export default TermsOfUse;