import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Service from '../components/Service';



class AdminChat extends Component {


    
    constructor(props) {
        super(props);
        this.state = {
            messages:[]
          
                
        
            
        }

    }

    
    // componentDidMount(){
    //     this.getMessage();
       
       


    // }

    // getMessage=()=>{
    //     Service.get("/admin/getMessages",{fromId:this.props.id , toId:this.props.id2} , (res) => {
    //         console.log(res);
    //         this.setState({messages: res.data.messages});
            
            
    //     }); 
    // }





    render() {
        return (
            <div className="content">

                <h1> Chat</h1>
                
            </div>
        );
    }

}

export default AdminChat;