import React, { Component } from "react";
import Service from "../components/Service";
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Supported from "../components/Supported";
import {Auth} from '../components/Auth';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: []
    };
    this.mainHeading = React.createRef();
  }
  // componentDidMount=()=>{
  //   this.mainHeading.current.focus();
  // }

  render() {
    return (
      <div className="container">
          
        <div className="home-wrapper">
            <img className="bg-b-r" src="images/home-content-background-graphic-position-right-bottom.gif" />
            <div className="content">
                
                <h1>Welcome</h1>
                <p className="home-paragraph">
                        Soundsy.net is a place where you can buy<br className="home-break"/> 
                        audio stock files, hire artists for audio services.
                </p>
                <p className="home-paragraph">
                    Soundsy platform enables persons with visual<br className="home-break"/> 
                    impairment in their entrepreneurship by becoming<br className="home-break"/> 
                    contributors/freelancers for audio services.<br className="home-break"/> 
                </p>

                <div className="home-boxes">
                <div className="home-box">
                    <h2>
                        BUY<br></br>
                        <span>SOUNDS</span>
                    </h2>
                    <p>
                    Find sound that fit to <br className="home-break-min"></br>
                    your project<br className="home-break-min"></br>
                    for tv, radio, video<br className="home-break-min"></br>
                    and other media
                    </p>
                    <Link to="/audio-stock"><button>MORE&gt;&gt;</button></Link>
                </div>

                <div className="home-box">
                    <h2>
                    HIRE<br></br>
                    <span>FREELANCER</span>
                    </h2>
                    <p>
                    Hire freelancer <br className="home-break-min"></br>
                    to do audio services for your<br className="home-break-min"></br>
                    project for tv, radio, video<br className="home-break-min"></br>
                    and other media
                    </p>
                    <Link to="/audio-services-categories"><button>MORE&gt;&gt;</button></Link>
                </div>

                <div className="home-box">
                    <h2>
                        SELL<br></br>
                        <span>SOUNDS</span>
                    </h2>
                    <p>
                    Become stock contributor <br className="home-break-min"></br>
                    earn from your work <br className="home-break-min"></br>
                    sell your sounds: music, <br className="home-break-min"></br>
                    sounds, efects
                    </p>
                    <Link to="/user/projects"><button>MORE&gt;&gt;</button></Link>
                </div>

                <div className="home-box">
                    <h2>
                        OFFER<br></br>
                        <span>SERVICES</span>
                    </h2>
                    <p>
                    Become audio <br className="home-break-min"></br>
                    freelancer <br className="home-break-min"></br>
                    earn from your<br className="home-break-min"></br>
                    audio services
                    </p>
                    <Link to="/user/services-categories"><button>MORE&gt;&gt;</button></Link>
                </div>

                </div>
                {/* <h1 ref={this.mainHeading} tabIndex="0">USE SOUND</h1> */}
                
                {/*             
                <Link to="/audio-services-categories">
                <button className="home-button" >AUDIO SERVICES</button>
                </Link> */}
                
                {/* {Auth.role == 2,3 && 
                <Link to="/user/services-categories">
                <button className="home-button" >AUDIO SERVICES</button>
                </Link>
                } */}
                {/* <Link to="/audio-stock">
                <button className="home-button" >AUDIO STOCK</button>
                </Link> */}
            </div>
        </div>

      {/* <div className="home-footer">
          <img className="bg-footer-right" src="images/home-footer-background-graphic-position-right-bottom.gif" />
          
        <div className="footer-box footer-information">
          <h3>INFORMATION</h3>
          <ul>
            <li>The brand</li>
            <li>Store</li>
            <li>Customer service</li>
            <li>Privacy &amp; cookies</li>
            <li>Site map</li>
          </ul>
        </div>

        <div className="footer-box footer-buy">
          <h3>WHY BUY FROM US</h3>
          <ul>
            <li>Best deal</li>
            <li>Secure shopping</li>
            <li>Polices</li>
            <li>Ethical trading</li>
          </ul>
        </div>
              
        <div className="footer-box  footer-account">
          <h3>YOUR ACCOUNT</h3>
          <ul>
            <Link to="/role-selector"><li>Sign in </li></Link>
            <Link to="/role-selector"><li>Sign up</li></Link>
            <Link to="/cart"><li>View cart</li></Link>
          </ul>
        </div>

      </div> */}
    </div>    

    );
  }
}

export default Home;
