import React, { Component } from 'react';
import Service from '../components/Service';
import Icons from '../components/Icons';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import {Auth} from '../components/Auth';
import duix from 'duix';
import AudioServiceFreelancer from "../components/AudioServiceFreelancer";
import AudioServiceBuyer from "../components/AudioServiceBuyer"
import AudioServiceContributor from "../components/AudioServiceContributor"


function AudioServiceSelector (props) {
     
    if (props.role == 1) {
      return <AudioServiceBuyer serviceId={props.serviceId}/>;
    }  
    if (props.role == 2) {
        return <AudioServiceContributor />;
    }
    if (props.role == 3) {
        return <AudioServiceFreelancer categoryId={props.categoryId} />;
    }
    return <h1>No role has been given!</h1>;
}

class UserService extends Component {

    constructor(){
        super();
        this.state={
            role:5,
            categories:[]
        }

    }
    unsubscribe = [];
    
    componentDidMount(){
        setTimeout(()=>{
            this.setState({role: Auth.role})
        }, 100);

        this.unsubscribe[0] = duix.subscribe('changeRole', () => {
            this.setState({role: Auth.role});
       });
    }

    
    // componentWilUnmount(){
    //     this.unsubscribe[0]();
    // }

    
    render(){
        return(
            <div className="content">
                

                <div>
                {/* <p>{Auth.role}{this.state.role} </p> */}
                <AudioServiceSelector role={this.state.role} categoryId={this.props.match.params.categoryId} />

                </div>
               
            </div>
        );
    }
}

export default UserService;