import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import Chat from '../components/Chat';
import Chats from '../components/Chats';
import Aria from '../components/Aria';
import DashboardMenu from '../components/DashboardMenu';

// function ChatsSelector(props) {
     
//     if (!props.userId) {
//       return <Chats   />;
//     }  
//     if (props.userId) {
//         return <Chat userId={props.userId} serviceId={props.serviceId} />;
//     }
// }

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            chats: [],
            alert2:'',
            projectName: '',
            projectDescription: '',
            price:'',
            alert: '',
            fileId: '',
            length:'',
            tempo:'',  
            
        }

    }

    componentDidMount() {
        setTimeout(()=>{
            console.log(this.props.match.params.userId);
            // this.getMessages();
            console.log('auth in messages', Auth);
            this.getChats();
        },1000);
    }

    getChats=()=>{
        Service.get("/users/getChats",{userId: Auth.userId}, (res) => {
            if(res.success){
                this.setState({chats: res.data.chats});
            }
        }); 
    }

    getMessages=()=>{
    
        Service.get("/users/getMessages",{toId: this.props.match.params.userId, fromId:Auth.userId}, (res) => {
            console.log(res);
            if(res.success){
                this.setState({messages: res.data.messages});
            }
        }); 
    }
    
    render(){
        return(
            <div className="dashboard-wrapper">
                <img className="bg-b-r full-width" src="/images/background-dashboard.png" />
                <div className="content">
                    
                    <Chat userId={this.props.match.params.userId} serviceId={this.props.match.params.id} />
                    {/* {this.props.match.params.userId != false &&
                        <div >
                            <h1>Messages</h1>
                            
                            {this.state.messages.map(function (item, index) {
                                return (
                                    <div  key={index}>

                                        <h6>{item.created_at}</h6>
                                        <h3>{item.from_id}</h3>
                                        <p>{item.text}</p>
                                    
                                    </div>
                                )
                                }, this)
                            }
                        </div>
                    } */}

                    {/* {this.props.match.params.userId == false && */}
                        {/* <div >
                            <h1>Messages</h1>
                            
                            {this.state.chats.map(function (item, index) {
                                return (
                                    <div  key={index}>

                                        <h6>{item.correspondent}</h6>
                                        <h3>{item.lastMessage[0].date}</h3>
                                        <p>{item.lastMessage[0].text}</p>
                                    
                                    </div>
                                )
                                }, this)
                            }
                        </div> */}
                    {/* } */}
                    {/* <p>{this.props.match.params.userId}</p> */}
                </div>
            </div>
        );
    }

}
                  
           

export default Messages;