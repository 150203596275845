import React, { Component } from 'react';



class Aria extends Component {
    





    render() {
        return (

            <div className="aria">
                {this.props.message != "" &&
                    <p role='alert'>{this.props.message}</p>
                }
            </div>
        );
       
    }

}

export default Aria;