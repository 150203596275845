import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Service from '../components/Service';



class AdminProject extends Component {

   
    constructor(props) {
        super(props);
        this.state = {
            project: []
             
            
        }

    }



    componentDidMount(){
        this.getProject();
       


    }

    changeStatus=(event)=>{
        console.log(event.target.value);
        Service.get('/admin/changeProjectStatus',{status: event.target.value, projectId: this.props.match.params.projectId},(res)=>{
            console.log(res);
            if(res.success){
                this.getProject();
            }
        })
    }

    getProject=()=>{
        Service.get("/admin/getProject",{projectId: this.props.match.params.projectId}, (res) => {

            console.log(res);
            this.setState({project: res.data.project});
            
        }); 
    }




    render() {
        return (
            <div className="content" >
            <div>
            <h1> Project</h1>
            </div>
            {/* {this.state.description} */}
            {this.state.project.map(function (item, index) {
                    return (
                        <div key={index}>

                            <div className="admin-project-div">
                                <h3>Name:</h3><p>{item.name} </p>
                                <h3>Description:</h3><p>{item.description}</p>
                                <h3>Id:</h3><p>{item.id}</p>
                                <h3>Creation time</h3><p>{item.creation_time}</p>
                                <h3>Price:</h3><p>{item.price} </p>
                                <h3>Approved</h3>
                                {item.status == 0 &&
                                    <p>Pending</p>
                                }
                                {item.status == 1 &&
                                    <p>Approved</p>
                                }
                                {item.status == 9 &&
                                    <p>Rejected</p>
                                }
                                
                                <h3>Change status</h3>
                                <select className="header-select" name="opcija" value={item.status} onChange={this.changeStatus}>
                                    <option value="0" >Pending</option>
                                    <option value="1" >Approve</option>
                                    <option value="9" >Reject</option>
                                </select>
                            </div>
                      

                            
                           

                        </div>
                    )
                }, this)
                }
            
              
            </div>
        );
      }
    }

        
    



export default AdminProject;