import React, { Component } from 'react';
import ProfileBuyer from "../components/ProfileBuyer";
import ProfileContributor from "../components/ProfileContributor";
import ProfileFreelancer from "../components/ProfileFreelancer";
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";



class EditProfile extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
              role: this.props.match.params.type
          
        }

    }

    

    render(){
        return(
            <div className="content">

                <h1>Edit Account</h1>

                {this.state.role == 1 &&
                    <h2>
                    <ProfileBuyer></ProfileBuyer>
                    </h2>
                } 
                {this.state.role == 2 &&
                    <h2>
                    <ProfileContributor></ProfileContributor>
                    </h2>
                } 
                {this.state.role == 3 &&
                    <h2>
                    <ProfileFreelancer></ProfileFreelancer>
                    </h2>
                }
                
                <label>Data:</label>
                <label>Change</label>

                <label>Legal Adress</label>
                <input></input>

                <label>Telephone</label>
                <input></input>

                <label>E-mail:</label>
                <input></input>
                <label>Change password</label>
                <input></input>

                <label>Bank account</label>
                <input></input>

                <label>Delete profile</label>        
                <input></input> 
                
                <Link to="/user/dashboard/">
                    <button>Back to Dashboard</button>
                </Link>

                <button>Save</button>
                
                
            </div>
            
        );
    }

}

export default EditProfile;