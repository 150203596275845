import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import { isTemplateElement } from '@babel/types';
import DashboardMenu from '../components/DashboardMenu';
import {Auth} from '../components/Auth';


class DashboardBuyer extends Component {


   

    
    render(){
        return(
            <div>
                <h2 className="title-left"><span className="dashboard-role-light">Buyer /</span> {Auth.name} {Auth.lastName}</h2>
                
                <DashboardMenu />

            </div>
        );
    }

}

export default DashboardBuyer;