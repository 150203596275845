import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Supported from "../components/Supported";

function resizeContent(){
        let w = window.innerWidth;
        let h = window.innerHeight;
        if(document.getElementById("footer") && document.getElementById("content")){
            let fh = document.getElementById("footer").offsetHeight ;
            let ch = document.getElementsByClassName("content")[0].offsetHeight;
            // console.log(w,h,fh,ch)
            if(ch < h-fh){
            document.getElementsByClassName("content")[0].style.minHeight = (h-fh)+"px";

            }
        }
    }

window.onresize = function(){resizeContent()};


class FooterCart extends Component {

    interval;

    componentDidMount(){
        resizeContent();
        // this.interval = setInterval(()=>{resizeContent()}, 100)
    }

    

    

    render() {
        return (
        <div id="footer">
            <div className="home-footer">
                <div className="footer-info">
                    <div className="footer-box footer-information">
                    <h3>Merchant information</h3>
                    <ul>
                        <li><p>Full Company Name:<br/>Association "Youth Cultural Center of Vojvodina"</p></li>
                        <li><p>Activity Code: 9499</p></li>
                        <li><p>Company Number: 08795827</p></li>
                        <li><p>Tax Identification Number: 103068774</p></li>
                       
                    </ul>
                    </div>

                    <div className="footer-box footer-buy">
                
                    </div>
                        
                    <div className="footer-box  footer-account">
                    <h3 style={{visibility: "hidden"}}>Merchant information</h3>
                    <ul>
                        <li><p>Web address: www.soundsy.net</p></li>
                        <li><p>Address:<br/> Staparski put 10C/5, 25000 Sombor, Serbia</p></li>
                        <li><p>Telephone: +38163/468-834</p></li>
                        <li><p>Email address: kcmv.udruzenje@gmail.com</p></li>
                    </ul>
                    </div>
                </div>
            </div>
            
            <div className="footer-logos">
                    <div>
                        <img src="/images/visa.png" alt="Visa" />
                        <img src="/images/mastercard1.png" alt="MasterCard" />
                        <img src="/images/maestro.png" alt="Maestro" />
                    </div>
                    <div>
                        <a href="http://www.e-services.rs/" target="_blank" rel="noopener noreferrer">
                        <img id="chip-card-logo" src="/images/chipCard-1.png" alt="Chip Card" />
                        </a>
                    </div>
                    <div>
                        <a href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html" target="_blank" rel="noopener noreferrer">
                            <img src="/images/verifiedByVisa.png" alt="Verified by Visa" />
                        </a>
                        <a href="http://www.mastercard.com/rs/consumer/credit-cards.html" target="_blank" rel="noopener noreferrer">
                            <img src="/images/masterCard.png" alt="MasterCard Secure Code" />
                        </a>
                    </div>
            </div>
            {/* <div className="social-links">
            <a href="https://www.facebook.com/usesound"   ><i class="fab fa-facebook-f" title="facebook page"></i></a>
            <a href="https://twitter.com/usesound"   ><i class="fab fa-twitter" title="twitter page"></i></a>
            <a href="https://www.instagram.com/usesound/"><i class="fab fa-instagram" title="instagram page"></i></a>
            </div> */}
            
               
            
    
             <Supported />
            
            <div className="footer-copyright">
                <div>
                    <p>development: <a href="http://brainit.rs/" target="_blank">brainit.rs</a></p>
                    <p>©️ Copyright 2019 Soundsy - All Rights Reserved</p>
                    <p><a href="https://www.borisradivojkov.com/" target="_blank">design by: Boris Radivojkov</a></p>
                </div>
            </div>
        </div>
        );
    }
}

export default FooterCart;
