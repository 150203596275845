import React, { Component } from 'react';



class Upload extends Component {


   

    
    render(){
        return(
            <div className="content">

                <h1>Upload</h1>
            </div>
        );
    }

}

export default Upload;