import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import DashboardMenu from '../components/DashboardMenu'
import {Auth} from '../components/Auth';


class DashboardContributor extends Component {


   

    
    render(){
        return(
            <div>
                {/* <h2 className="title-left">Contributor</h2> */}
                <h2 className="title-left"><span className="dashboard-role-light">Freelancer /</span> {Auth.name} {Auth.lastName}</h2>

                <DashboardMenu />
                {/* <Link to="/user/projects"> Projects</Link> */}
            </div>
        );
    }

}

export default DashboardContributor;