import React, { Component } from 'react';
import Service from './Service';
import Icons from './Icons';
import { BrowserRouter as Router, Route, Link, Read,Redirect } from "react-router-dom";
import StarRatings from 'react-star-ratings';


class AudioServicesFreelancer extends Component {

    constructor(){
        super();
        this.state={
            // services: [],
            // create:false,
            // title:"",
            // price:"",
            // description:"",
            // category:[]
            services: [],
            showList: true,
            currentCategory:""
        }
        this.mainHeading = React.createRef();
    }

    
    componentDidMount(){
        this.getServices();
        this.mainHeading.current.focus();
        this.getCategoryName();
    }

    getCategoryName=()=>{
        Service.get("/categories/getCategory",{id: this.props.categoryId}, (res) =>{
        this.setState({currentCategory: res.data.category.name})
        });
    }

    getServices=()=>{
        Service.get("/services/get",{categoryId: this.props.categoryId}, (res) => {
            this.setState({ services: res.data.users })
            console.log(res)
        });

    }

    // handleInput=(event)=> {
    //     this.setState({[event.target.id]: event.target.value});
    // }

    // getServices=()=>{
    //     Service.get("/services/get",{categoryId: this.props.categoryId}, (res) => {
    //         this.setState({ services: res.data.users })
    //         console.log(res)
    //     });
    // }

    // submitJob=()=>{
    //     Service.get("/services/create",{
    //         title: this.state.title,
    //         description: this.state.description,
    //         price: this.state.price,
    //         category: this.props.categoryId
    //     }, (res) => {
    //         if(res.success){
                
    //             window.location="/audio-service/"+res.data.categoryId
    //         }
    //     });
    // }

    // createJob=()=>{
    //     if(this.state.create){
    //         this.setState({create:false})
    //     }else{
    //         this.setState({create:true})
    //     }
    // }

    


    
    render(){
        return(
            // <div className="content">
            //     <h4 role='contentinfo' tabIndex="0">Create new job by clicking create new job button or browse your job offers on the list bellow.</h4>
            //    <button onClick={this.createJob}>Apply to service</button>
            //     {this.state.create == true && 
            //         <div tabIndex="-1">
            //             <h4 role='alert'>Please enter the new job information.</h4>
            //             <label>Title</label>
            //             <input id="title" value={this.state.title} placeholder="Enter job title..." onChange={this.handleInput} />
            //             <label>Description</label>
            //             <input id="description" value={this.state.description} placeholder="Enter job description..."  onChange={this.handleInput} />
            //             <label>Price</label>
            //             <input id="price" type="number" value={this.state.price} placeholder="Enter job price..."  onChange={this.handleInput} />
            //             <button onClick={this.submitJob}>Submit</button>
            //         </div>
            //     }
            //     <hr></hr>
      
            //     <div className="product-freelancer">
            //         {this.state.services.map(function (item, index) {
            //             return (
            //                 <div className="audio-service-buyer-box" key={index}>
            //                     {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
            //                     {/* <div className="icon"> {Icons.icon("notes")}  </div> */}
            //                     <div className="service-buyer-title">
            //                         <span>
            //                             {item.name}<br></br>
            //                             {item.last_name}
            //                         </span>
            //                     </div>
            //                     <div className="service-buyer-content">
            //                     <p>
            //                         {item.description}
            //                     </p>
            //                         <StarRatings
            //                             //rating={this.state.rating}
            //                             //rating={item.rating}
            //                             // rating={item.rating}
            //                             starDimension="1.3em"
            //                             starSpacing=".1em"
            //                             starRatedColor="#000000"
            //                             // changeRating={this.changeRating}
            //                             numberOfStars={5}
            //                             // name={item.userId.toString()}
            //                         />
            //                     </div>
            //                     <Link to={"/user-service/"+item.categoryId}>STARTING AT {item.price}</Link>
            //                 </div>
            //             )
            //             }, this)
            //         }
            //     </div>   
            // </div>
            <div className="content">
                <h1 ref={this.mainHeading} tabIndex="0" className="title-left"><span className="audio-service-light">Audio Services / </span>{this.state.currentCategory}</h1>
                <div className="product-freelancer">
                    {this.state.services.map(function (item, index) {
                        return (
                            <div className="service-box" key={index}>
                            {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
                            {/* <div className="icon"> {Icons.icon("notes")}  </div> */}
                            <div className="service-buyer-title">
                                <h2>
                                    {item.username}
                                </h2>
                                <p>EUR{item.price}</p>
                            </div>
                            <div className="service-buyer-content">
                            <p>
                                {item.description}
                            </p>
                                <StarRatings
                                    //rating={this.state.rating}
                                    //rating={item.rating}
                                    // rating={item.rating}
                                    starDimension="1.3em"
                                    starSpacing=".1em"
                                    starRatedColor="#000000"
                                    // changeRating={this.changeRating}
                                    numberOfStars={5}
                                    // name={item.userId.toString()}
                                />
                            </div>
                                <Link to={"/audio-service-chat/"+item.categoryId+"/"+item.id}>Apply to service</Link>
                            </div>    
                        )
                    }, this)
                    }
                </div>
            </div>
        );
    }
}

export default AudioServicesFreelancer;