import React, { Component } from 'react';



class Rate extends Component {


   

    
    render(){
        return(
            <div className="content">

                <h1>Rate</h1>
            </div>
        );
    }

}

export default Rate;