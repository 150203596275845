import React, { Component } from 'react';
import Service from '../components/Service';
import DashboardMenu from '../components/DashboardMenu';
import Aria from '../components/Aria';

    
class CreateService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alert:"",
            servicePrice:"",
            serviceDescription:"",
            currentCategory:''
        }
        this.mainHeading = React.createRef();

    }

    componentDidMount(){
        this.getCategoryName();
    }

    getCategoryName=()=>{
        Service.get("/categories/getCategory",{id: this.props.match.params.categoryId}, (res) =>{
        this.setState({currentCategory: res.data.category.name})
        });
    }


    applyToService=()=>{
        if(!this.state.serviceDescription) {
            this.setState({alert: "Please enter service description."});
            return;
        }else if(!this.state.servicePrice) {
            this.setState({alert: "Please enter service price."});
            return;
        }else{
            Service.get('/users/addToService',{
                categoryId: this.props.match.params.categoryId,
                description: this.state.serviceDescription,
                price: this.state.servicePrice
            },(res)=>{
                console.log(res);
                if(res.success){
                    this.setState({alert: 'You successfuly applied for this service category.'})
                }
            })
        }
    }

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    //  Create service on enter press
    defaultButton = (e) => {
        if(e.which === 13) {
        this.applyToService();
        }
    }
    
    render(){
        return(
            <div className="content">
                <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Create Service / {this.state.currentCategory}</h1>
                <DashboardMenu />
                    <div className="create-service-container">
                        <div className="create-service-content">
                            <h2>Service description</h2>
                            <textarea id="serviceDescription" type="text" value={this.state.serviceDescription} onChange={this.handleInput} onKeyPress={this.defaultButton} />
                            <h2>Price from / per minute</h2>
                            <div class="currency-wrap">
                                <span class="currency-code-service">EUR</span>
                                <input id="servicePrice" class="text-currency" data-type="currency" type="text" value={this.state.servicePrice} onChange={this.handleInput} onKeyPress={this.defaultButton}></input>
                            </div>
                            <button className="defaultButton create-service-button" onClick={this.applyToService}>Submit</button>
                        </div>
                        <Aria message={this.state.alert} />
                    </div>
                    
            </div>
        );
    }

}




export default CreateService;