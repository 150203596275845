import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Service from '../components/Service';
import Aria from '../components/Aria';
import {Countries} from '../components/Countries';
import { Auth } from '../components/Auth';
import Select from 'react-select';

const customStyles = {
    container: (base, state) => ({
      ...base,
      width:"108%",
      marginBottom:"2em",
      transition:
        "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
      "&:hover": {
        boxShadow: "0 2px 4px 0 gray"
      }
    }),
    input: (base, state) => ({
      ...base,
      height: "40px",
      position: "initial"
    }),
    control: (base, state) => ({
      ...base,
      borderRadius: "0em",
      border:"2px solid gray",
      margin:"1em 0 1em"
    }),
    valueContainer: (base, state) => ({
        ...base,
        position: "initial",
      }),
      placeholder: (base, state) => ({
        ...base,
        color: "black",
      }),
  };


class SignUp extends Component {
    constructor() {
        super();
        this.state = {
            userName: '',
            password: '',
            email: '',
            legalAdress: '',
            gender: '',
            country: '',
            age: '',
            alert:'',
            // ages: [],
            // years: [],
            // countries:Countries,
            repeatedPassword: ''
            


        }
        this.mainHeading = React.createRef();

    };

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    };

    // getYears=()=>{
    //     let dt = new Date();
    //     let currentYear = parseInt(dt.getFullYear()) - 16;
    //     let a = [];
    //     for(var i = 0; i < 100; i++){
    //         a.push(currentYear - i);
    //     }
    //     this.setState({years: a});
    //     console.log('getYears',a);
    // }

    selectGender = (event) => {

        this.setState({ gender: event.target.value }, () => {
            console.log(this.state);
        });

    };
    agreeTo = (event) => {
        this.setState({ agree: event.target.checked }, () => {
            console.log(this.state.agree);
        });

    };

    componentDidMount(){
        console.log('type',this.props.match.params.type)
        // let a = [];
        // for(var i=18; i<100; i++){
        //     a.push(i);

        // }
        // this.setState({ages: a});
        // this.mainHeading.current.focus();

        // this.getYears();
    }

    handleAge=(event)=>{
        this.setState({age: event.target.value});
        console.log(this.state.age);
    }

    handleGender=(event)=>{
        this.setState({gender: event.target.value});
        console.log(this.state.gender);
    }

    // handleCountry=(event)=>{
    //     this.setState({country: event.target.value});
    //     console.log('kiiiiiiiiiiiiiiiiii')
    //     console.log(this.state.country);
    // }
    handleCountry = country => {
        this.setState({ country });
    };


    signUp = () => {


        if(!this.state.email) {
            this.setState({alert: "Please enter your email."});
            return;
        }

        if(!this.state.userName) {
            this.setState({alert: "Please enter your username."});
            return;
        }

        if(!this.state.name) {
            this.setState({alert: "Please enter your name."});
            return;
        }

        if(!this.state.lastName) {
            this.setState({alert: "Please enter your last name."});
            return;
        }
        
        if(!this.state.password) {
            this.setState({alert: "Please enter password."});
            return;
        }

        if(!this.state.repeatedPassword) {
            this.setState({alert: "Please enter password confirmation."});
            return;
        }

        if(!this.state.age) {
            this.setState({alert: "Please enter your year of birth."});
            return;
        }
        
        

        if(!this.state.country) {
            this.setState({alert: "Please select your country."});
            return;
        }

        

        if (this.state.password !== this.state.repeatedPassword) {
            //alert("Passwords don't match");
            this.setState({alert: "Passwords don't match."});
            return;
        } 
            
        if(!this.state.agree) {
            this.setState({alert: "You have to accept the terms and conditions to sign up."});
            return;
        }
        
        console.log(this.state);
        // return;
        // alert('Aria Test');
       
       
        Service.get('/users/create', {
            userName: this.state.userName,
            email: this.state.email,
            password: this.state.password,
            repeatedPassword: this.state.repeatedPassword,
            role: this.props.match.params.type,
            name: this.state.name,
            lastName: this.state.lastName,
            country:this.state.country.value,
            age:this.state.age,
            gender:this.state.gender
        },(res)=>{
            if(res.success){
                window.location.href = "/registration-success";
                // this.setState({alert: "You successfully created account. The website administrators will review the data you submited and will activate your account soon. You will be noteefied by email. Thank you for contributeing to our community"});
            }else {
                this.setState({alert: res.info})

            }

        });
        

    }

    defaultButton = (e) => {
        if(e.which === 13) {
          this.signUp();
        }
      }


    render() {
        const { country } = this.state;
        return (
            <div className="sign-wrapper">
                <img className="bg-b-r full-width" src="/images/background-sign.png" />
                <div className="content-full">
                {!this.props.match.params.type &&
                <div>
                    <h1 style={{position: "fixed", top: "-500px"}} ref={this.mainHeading} tabIndex="0">Sign Up</h1>
                    <div className="role-selector-container">
                        <div className="role-selector">
                            <div>
                                <h3>I am</h3>
                                <h2>Buyer</h2>
                                <Link to="/sign-up/1/buyer" >
                                    Create Buyer Profile
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="role-selector-container" id="white-div">
                        <div >
                            <div className="role-selector" >
                                <h3>I am</h3>
                                <h2>Freelancer / Contributor</h2>
                                <Link to="/sign-up/2/freelancer" >
                                    Create Freelancer / Contributor Profile
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className="role-selector-container">
                        <div className="role-selector">
                            <div>
                                <h3>I am</h3>
                                <h2>Freelancer</h2>
                                <Link to="/sign-up/3" >
                                    Create Freelancer Profile
                                </Link>
                            </div>
                        </div>
                    </div> */}
                    </div>
                    
                }
                
                {this.props.match.params.type > 0  && 
                <div className="content">
                    <h1 ref={this.mainHeading} tabIndex="0">Sign Up</h1>
                <section className="inputs" id="sign-up-section">
                        <p tabIndex="0">Please enter your information in the fields below.</p>

                        <label for="email">Email</label>
                        <input type="text" value={this.state.email} id='email' onChange={this.handleInput} onKeyPress={this.defaultButton} />

                        <label>Username</label>
                        <input type="text" id="userName" value={this.state.userName} onChange={this.handleInput} onKeyPress={this.defaultButton} />

                        <label for="name">Name</label>
                        <input type="text" value={this.state.name} id='name' onChange={this.handleInput} onKeyPress={this.defaultButton} />

                        <label for="lastName">Last Name</label>
                        <input type="text" value={this.state.lastName} id='lastName' onChange={this.handleInput} onKeyPress={this.defaultButton} />

                        <label for="password">Password</label>
                        <input type="password" value={this.state.password} id='password' onChange={this.handleInput} />

                        <label for="repeatedPassword">Repeat Password</label>
                        <input type="password" value={this.state.repeatedPassword} id='repeatedPassword' onChange={this.handleInput} onKeyPress={this.defaultButton} />

                        
                        {/* <div onChange={this.selectGender} >
                            <input type="radio" name="gender" value="male" /> Male<br />
                            <input type="radio" name="gender" value="female" /> Female<br />

                        </div> */}


                            
                        {/* {this.props.match.params.type > 1  &&  
                            <div>
                                <label for="reason">The cause of your disability </label>
                                <textarea value={this.state.reason} id='reason' onChange={this.handleInput} onKeyPress={this.defaultButton} />
                            </div>
                            }    */}

                            {/* Gender */}
                            <label for="gender">Gender</label><br></br>
                            <label>
                                    <input type="radio" id="gender" value="male" 
                                        checked={this.state.gender === 'male'} 
                                        onChange={this.handleGender} />
                                    Male
                            </label>
            
                            <label>
                                <input type="radio" id="gender" value="female" 
                                    checked={this.state.gender === 'female'} 
                                    onChange={this.handleGender} />
                                Female
                            </label><br></br>
                                {/* <input type="text" value={this.state.gender} id='gender' onChange={this.handleInput} /> */}
                                    
                                <label for="age">Year of birth</label>
                                <input type="text" value={this.state.age} id='age' onChange={this.handleInput} onKeyPress={this.defaultButton} />
                                {/* <select id='age' value={this.state.age} onChange={this.handleAge}>
                                    {this.state.years.map(function (item, index){
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    }, this)
                                    }
                                    
                                </select> */}

                                {/* <label for="languages">Languages</label>
                                <input type="text" value={this.state.languages} id='languages' onChange={this.handleInput} onKeyPress={this.defaultButton} /> */}
                                
                                <label for="country">Country</label>
                                {/* <select id='country' value={this.state.country} onChange={this.handleCountry}>
                                    <option>Select Country</option>
                                {this.state.countries.map(function (item, index){
                                        return (
                                            <option>{item.name}</option>
                                        )
                                    }, this)
                                    }
                                </select> */}
                                <Select
                                    value={country}
                                    defaultValue={Countries[this.state.country]}
                                    onChange={this.handleCountry}
                                    styles={customStyles}
                                    options={Countries} 
                                    placeholder="Select country"   
                                />
                                            
                                                
                            
                                {/* <input type="text" value={this.state.country} id='country' onChange={this.handleInput} ></input> */}

                            
                            
                        
                            
                            
                            

                        <div>
                            <input type="checkbox" name="agree" id="terms-checkbox" value={this.state.agree} onChange={this.agreeTo} aria-label="Terms of use accept" onKeyPress={this.defaultButton}/>
                            <label style={{display: 'inline'}} htmlFor="terms-checkbox"> 
                                I agree with <a style={{display: 'inline'}} className="terms-link" href="/selling-rules" target="_blank">Selling Rules</a>, <a style={{display: 'inline'}} className="terms-link" href="/terms-of-use" target="_blank">Terms Of Use</a> and <a style={{display: 'inline'}} className="terms-link" href="https://ec.europa.eu/info/cookies_en" target="_blank">Privacy policy</a>. 
                            </label>
                        </div>

                        <button onClick={this.signUp} className="sign-up-button">Submit</button>
                        <Aria message={this.state.alert} />

                    {/* </div> */}
                
                </section>
                </div>
                        
                }          
                </div>
            </div>
        );
    }

}

export default SignUp;