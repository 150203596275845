import React, { Component } from 'react';



class Support extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            
        }
        this.mainHeading = React.createRef();

    }

    
    componentDidMount(){    
        this.mainHeading.current.focus()
    }


    
    render(){
        return(
            <div className="content">

                <h1 ref={this.mainHeading} tabIndex="0">Support</h1>
                <ul className='dashboard-list'>
                    <li><a href="#technical-support">Technical support</a></li>
                    <li><a href="#educational-support">Educational Support</a></li>
                </ul>
                
                <section id="tehnical-support">
                    <div id="tehnical">
                        <h2 >Technical support</h2>

                        <p>This is tehnical support page description text.</p>
                    </div>
                    
                </section>

                <secion id="educational-support">
                    <div id="educational">
                        <h2>Educational Support</h2>

                        <p>This is tehnical support page description text.</p>
                    </div>
                </secion>
            </div>
        );
    }

}

export default Support;