import React, { Component } from 'react';
import { Auth } from '../components/Auth';
import Service from '../components/Service';
import Aria from '../components/Aria';
import {Countries} from '../components/Countries';
import DashboardMenu from '../components/DashboardMenu';
import Select from 'react-select';

const customStyles = {
    container: (base, state) => ({
      ...base,
      transition:
        "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
      "&:hover": {
        boxShadow: "0 2px 4px 0 gray"
      }
    }),
    input: (base, state) => ({
      ...base,
      height: "30px",
      position: "initial"
    }),
    control: (base, state) => ({
      ...base,
      borderRadius: "0em",
      border:"2px solid gray",
      margin:"1em 0 1em"
    }),
    valueContainer: (base, state) => ({
        ...base,
        position: "initial",
      }),
      placeholder: (base, state) => ({
        ...base,
        color: "black",
      }),
  };

class EditBankAccount extends Component {

constructor(props) {
    super(props);
    this.state = {
        address: '',
        email: '',
        changePassword: '',
        role:'',
        gender:'',
        selectedCountry:'',
        selectedBankCountry:'',
        languages:'',
        age: 30,
        alert:'',
        ages: [],
        // countries:Countries,
        name: '',
        lastName:'',
        address:'',
        city:'',
        country:'',
        telephone: '',
        bankAccount:'',
        bankName:'',
        bankAddress:'',
        bankCity:'',
        bankCountry:'',
        swiftCode:''
    };
    this.mainHeading = React.createRef();

  }
    componentDidMount(){
        setTimeout(()=>{
        this.getBankAccount();
        },100);
        this.mainHeading.current.focus();
        let a = [];
        for(var i=18; i<100; i++){
            a.push(i);

        }
        this.setState({ages: a});
    }

    // handleAge=(event)=>{
    //     this.setState({age: event.target.value});
    //     console.log(this.state.age);
    // }


   
handleInput=(event)=> {
    this.setState({[event.target.id]: event.target.value});
}

// handleCountry=(event)=>{
//     this.setState({country: event.target.value});
//     console.log(this.state.country);
// }
handleCountry = selectedCountry => {
    this.setState({ selectedCountry });
};

handleBankCountry = selectedBankCountry => {
    this.setState({ selectedBankCountry });
};

getBankAccount=()=>{
    Service.get('/users/getBankAccount',{},(res)=>{
        console.log("res data user",res)
        this.setState({  
            name: res.data.user.name,
            lastName: res.data.user.last_name,
            address: res.data.user.address,
            city: res.data.user.city,
            country: res.data.user.country,
            telephone: res.data.user.telephone,
            bankAccount: res.data.user.bank_account,       
            bankName: res.data.user.bank_name,
            bankAddress: res.data.user.bank_address,
            bankCity: res.data.user.bank_city,
            bankCountry: res.data.user.bank_country,
            swiftCode: res.data.user.swift_code
        })
        console.log(this.state)
    })
}


updateBankAccount=(event)=>{
    event.preventDefault();

    if(!this.state.name) {
        this.setState({alert: "Please enter your name."});
        return;
    }else if(!this.state.lastName) {
        this.setState({alert: "Please enter your Lastname."});
        return;
    }else if(!this.state.address) {
        this.setState({alert: "Please enter your Street and number."});
        return;
    }else if(!this.state.city) {
        this.setState({alert: "Please enter your City."});
        return;
    }else if(!this.state.selectedCountry.value) {
        this.setState({alert: "Please enter your country."});
        return;
    }else if(!this.state.telephone) {
        this.setState({alert: "Please enter your Telephone."});
        return;
    }else if(!this.state.bankAccount) {
        this.setState({alert: "Please enter your Bank Account."});
        return;
    }else if(!this.state.bankName) {
        this.setState({alert: "Please enter Bank name."});
        return;
    }else if(!this.state.bankAddress) {
        this.setState({alert: "Please enter Bank Street and number."});
        return;
    }else if(!this.state.bankCity) {
        this.setState({alert: "Please enter Bank city."});
        return;
    }else if(!this.state.selectedBankCountry.value) {
        this.setState({alert: "Please enter Bank country."});
        return;
    }else if(!this.state.swiftCode) {
        this.setState({alert: "Please enter SWIFT code."});
        return;
    }else{
        console.log(this.state)
        Service.get('/users/updateBankAccount',{
            name: this.state.name,
            lastName:  this.state.lastName,
            address:  this.state.address,    
            city: this.state.city,   
            country: this.state.selectedCountry.value,   
            telephone: this.state.telephone,  
            // Bank
            bankAccount: this.state.bankAccount,
            bankName: this.state.bankName,
            bankAddress: this.state.bankAddress,
            bankCity: this.state.bankCity,
            bankCountry: this.state.selectedBankCountry.value,   
            swiftCode: this.state.swiftCode

        },(res)=>{
            console.log(res);
            if(res.success){
                this.setState({alert: 'You successfuly updated your bank account information.'})
                this.getBankAccount();
            }else {
                this.setState({alert: res.info})
            }
        })
    }

    // const files = Array.from(event.target.files);
    // this.setState({ uploading: true });

    // const formData = new FormData()

    // files.forEach((file, i) => {
    //     formData.append(i, file)
    // });

    // formData.append('address', this.state.address); 
    // formData.append('telephone', this.state.telephone); 
    // formData.append('bankAccount', this.state.bankAccount); 
    // formData.append('country', this.state.country); 
    // formData.append('languages', this.state.languages); 
    // formData.append('age', this.state.age); 
    // formData.append('userName', this.state.userName); 
    // console.log(formData);
    // var request = new XMLHttpRequest();
    // request.open("POST", '/users/update');
    // request.send(formData);
    // request.onprogress = function (res) {
    //     if (res.lengthComputable) {
    //         console.log(res.loaded+  " / " + res.total)
    //     }
    // }
}

// editProfile()
    render(){
        const { selectedCountry, selectedBankCountry } = this.state;
        return(
            <div className="dashboard-wrapper">
                <img className="bg-b-r full-width" src="/images/background-dashboard.png" />
                <div className="content">
                    <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Edit Bank Account</h1>
                    <DashboardMenu />

                    <div className="edit-account-container">
                        <form className="edit-bank-form">
                            <div className="edit-bank-left">
                                <label>Name</label>
                                <input type="text" id="name" className="edit-bank-input" value={this.state.name} onChange={this.handleInput} />

                                <label>Lastname</label>
                                <input type="text" id="lastName" className="edit-bank-input" value={this.state.lastName} onChange={this.handleInput} />

                                <h2>Legal Address</h2>
                                <label>Street and number</label>
                                <input type="text" id="address" className="edit-bank-input" value={this.state.address} onChange={this.handleInput} />

                                <label>City</label>
                                <input type="text" id="city" className="edit-bank-input" value={this.state.city} onChange={this.handleInput} />

                                <label htmlFor="country">Country</label>
                                <Select
                                    classNamePrefix={"select-container"}
                                    value={selectedCountry}
                                    // defaultValue={Countries[1]}
                                    placeholder={this.state.country}
                                    onChange={this.handleCountry}
                                    options={Countries}   
                                    styles={customStyles}
                                />

                                <label>Telephone</label>
                                <input type="text" id="telephone" className="edit-bank-input" value={this.state.telephone} onChange={this.handleInput} />

                                {/* <button className="defaultButton "  name="deleteProfile">Delete Profile</button> */}
                            </div>
                            <div className="edit-bank-right">
                                {/* <label>Telephone</label>
                                <input type="text" id="telephone" value={this.state.telephone} name="telephone" onChange={this.handleInput} />
                             */}
                            
                            <label>Bank Account Number</label>
                            <input type="text" id="bankAccount" className="edit-bank-input" value={this.state.bankAccount} name="bankAccount" onChange={this.handleInput} />

                            <label>Bank Name</label>
                            <input type="text" id="bankName" className="edit-bank-input" value={this.state.bankName} name="bankName" onChange={this.handleInput} />
                            

                            <h2>Bank Address</h2>
                            <label>Street and number</label>
                            <input type="text" id="bankAddress" className="edit-bank-input" value={this.state.bankAddress} onChange={this.handleInput} />

                            <label>City</label>
                            <input type="text" id="bankCity" className="edit-bank-input" value={this.state.bankCity} onChange={this.handleInput} />


                            <label htmlFor="country">Country</label>
                                <Select
                                    classNamePrefix={"select-container"}
                                    value={selectedBankCountry}
                                    // defaultValue={Countries[1]}
                                    placeholder={this.state.bankCountry}
                                    onChange={this.handleBankCountry}
                                    options={Countries}   
                                    styles={customStyles}
                                />

                            <label>SWIFT Code</label>
                            <input type="text" id="swiftCode" className="edit-bank-input" value={this.state.swiftCode} onChange={this.handleInput} />

{/* 
                            <label for="age">Age</label>
                            <select id='age' value={this.state.age} onChange={this.handleAge}>
                                {this.state.ages.map(function (item, index){
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                }, this)
                                }
                                
                            </select> */}
                            {/* <label for="age">Age</label>
                            <input type="number" value={this.state.age} id='age' onChange={this.handleInput} /> */}

                            {/* <label for="languages">Languages</label>
                            <input type="text" value={this.state.languages} id='languages' onChange={this.handleInput} /> */}

                            
                            
                                {/* <select id="country" value={this.state.country} onChange={this.handleCountry}>
                                    <option>Select Country</option>
                                {this.state.countries.map(function (item, index){
                                        return (
                                            <option value={item.name}>{item.name}</option>
                                        )
                                    }, this)
                                    }           
                                </select> */}
                               

                                <button className="defaultButton " onClick={this.updateBankAccount}>Submit</button><br />
                            </div>

                            {/* {Auth.role > 1 && 
                    
                                <button>Upload file</button>

                            } */}     
                        </form>
                        
                        {/* <progress value={this.state.value} max="100"></progress>  */}
                        <Aria message={this.state.alert} />

                        
                    </div>
                </div>
            </div>
        );
    }

}

export default EditBankAccount;