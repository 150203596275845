import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";



class Admin extends Component {





    render() {
        return (
       
        <div className="content admin-content">

                <div className="admin-menu" >

                    
                
                    <ul >
                        <li>
                            <Link to="/admin/user">User</Link>
                        </li>
                        <li>
                            <Link to="/admin/users">Users</Link>
                        </li>
                    
                        <li>
                            <Link to="/admin/chat">Chat</Link>
                        </li>
                        <li>
                            <Link to="/admin/chats">Chats</Link>
                        </li>
                        <li>
                            <Link to="/admin/project">Project</Link>
                        </li>
                        <li>
                            <Link to="/admin/projects">Projects</Link>
                        </li>
                        
                    </ul>
                        
                        
                
                        
                </div>
                
        </div>
        );
    }

}

export default Admin;