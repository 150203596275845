import React, { Component } from "react";
import { BrowserRouter as Router, Switch,Redirect, Route, Link } from "react-router-dom";
import Service from '../components/Service';



class AccountActivation extends Component {
    
  
    


    componentDidMount(){
        this.activate();
    }

    activate=()=>{
        Service.get("/users/accountactivation",{activationCode: this.props.match.params.code}, (res) => {
            console.log(res)
        }); 
    }





  


  render() {
    return (
      <div className="content" >

        <p>You successfuly activated your account. Thank you for joining Soundsy. You can now browse and buy sounds or hire a freelancer for your project.</p>
        <p>If you have applied for freelancer account, please be patient. Site admins will review your account information and approve you as a contributor as soon as possible.
            You will be notified by email when your freelancer account is approved.
        </p>
        <p>Your Soundsy team.</p>



    
        </div>
    );
  }
}

export default AccountActivation;
