import React, { Component } from 'react';
import Supported from "../components/Supported";



class TehnicalSupport extends Component {


   

    
    render(){
        return(
            <div>
            <div className="traka">Tehnical support</div>

            <div className="content">

            <Supported />
            </div>

               
            </div>
            
        );
    }

}

export default TehnicalSupport;