import React, { Component } from 'react';
import Service from '../components/Service';
import { BrowserRouter as Router, Route, Link, Read,Redirect } from "react-router-dom";
import Aria from '../components/Aria';


class UserServicesFreelancer extends Component {

    constructor(){
        super();
        this.state={
            service: [],
            create:false,
            title:"",
            price:"",
            description:"",
            chats:[],
            services:[],
            apply: false,
            serviceDescription: '',
            servicePrice: '',
            alert: ''
            
           
        }

    }

    
    componentDidMount(){
        setTimeout(()=>{
            this.getService();
            this.getChats();
        }, 10);
        
    }




    // getServices=()=>{
    //     Service.get("/services/get",{category: this.props.match.params.categoryId}, (res) => {
    //         this.setState({ services: res.data.services })
    //         console.log(res)
    //     });

    // }


    

    handleInput=(event)=> {
        this.setState({[event.target.id]: event.target.value});
    }

    getService=()=>{
        Service.get("/services/getUserService",{categoryId: this.props.match.params.categoryId}, (res) => {
            if(res.data.jobs.length > 0){
                this.setState({ title: res.data.jobs[0].title,  description: res.data.jobs[0].description, price: res.data.jobs[0].price}) 
            }
            //console.log(res)
        });

    }

    
    submitJob=()=>{
        Service.get("/services/create",{
            title: this.state.title,
            description: this.state.description,
            price: this.state.price,
            category: this.props.match.params.categoryId

        }, (res) => {
            if(res.success){
                
                window.location="/audio-service/"+res.data.serviceId
            }
        });

    }

    updateService=()=>{
        Service.get("/services/update",{
            title: this.state.title,
            description: this.state.description,
            price: this.state.price,
            category: this.props.match.params.categoryId

        }, (res) => {
            if(res.success){
                
                window.location="/audio-service/"+res.data.serviceId
            }
        });

        

    }

    

    
    getChats=()=>{
        console.log('sid', this.props.match.params.serviceId )
        Service.get("/services/getOwnerChats",{categoryId: this.props.match.params.categoryId}, (res) => {
            this.setState({ chats: res.data.chats })
            console.log(res)
        });

    }

    applyToService=()=>{
        Service.get('/users/addToService',{
            categoryId: this.props.match.params.categoryId,
            description: this.state.serviceDescription,
            price: this.state.servicePrice
        },(res)=>{
            console.log(res);
            if(res.success){
                this.setState({alert: 'You successfuly applied for this service category.'})
            }
        })
    }

    toggleApply=()=>{
        if(this.state.apply){
            this.setState({apply: false}) 
        }else{
            this.setState({apply: true})
        }
    }

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    
    render(){
        return(
            <div className="content">
                {this.state.apply == false &&
                    <button onClick={this.toggleApply}>Apply to service</button>
                }
                {this.state.apply == true &&
                    <button onClick={this.toggleApply}>Close</button>
                }
                {this.state.apply == true &&
                    <div>
                        <h2>Service description</h2>
                        <input id="serviceDescription" type="text" value={this.state.serviceDescription} onChange={this.handleInput}></input>
                        <h2>Price from / per minute</h2>
                        <div class="currency-wrap">
                            <span class="currency-code">EUR</span>
                            <input id="servicePrice" class="text-currency" data-type="currency" type="number" value={this.state.servicePrice} onChange={this.handleInput}></input>
                        </div>
                            <button onClick={this.applyToService}>Submit</button>
                    </div>
                }
                <Aria message={this.state.alert} />
                <div className="product-freelancer">

                {this.state.chats.map(function (item, index) {
                            return (
                                <div className="admin-project-div" key={index}>
                                    {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
                                    {/* <div className="icon"> {Icons.icon("notes")}  </div> */}
                                    <h4 tabIndex="0">{item.correspondent.name} {item.correspondent.lastName}</h4>
                                    {/* <p>{item.lastMessage.text}</p> */}
                                    {/* <p role="contentinfo" tabIndex="0" aria-label={"Title, "+item.title} >{item.title}</p>
                                    <p role="contentinfo" tabIndex="0" aria-label={"Description, "+item.description}>{item.description}</p> */}
                                    <Link to={"/user-service-chat/"+this.props.match.params.categoryId+"/"+item.correspondent.id} >Go to messages</Link>
                                    <hr></hr>
                                </div>    
                            )
                        }, this)
                        }
                </div>
                
               
            </div>
        );
    }
}

export default UserServicesFreelancer;