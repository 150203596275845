import React, { Component } from 'react';
import AdminChats from "../components/AdminChats";
import AdminChat from "../components/AdminChat";
import AdminUsers from "../components/AdminUsers";
import AdminProjects from "../components/AdminProjects";
import AdminProject from "../components/AdminProject";
import AdminUser from "../components/AdminUser";

import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";



// function AdminSelector(props) {
     
//     if (props.component == "chats") {
//         return <AdminChats />;
//     }  
//     if (props.component == "chat" ) {
//       return <AdminChat  fromId={props.id} toId={props.id2}  />;
//     }  
//     if (props.component == "users") {
//         return <AdminUsers />;
//     }
//     if (props.component == "user") {
//         return <AdminUser id={props.id} />;
//     }
//     if (props.component == "project") {
//         return <AdminProject id={props.id} />;
//     }
//     if (props.component == "projects") {
//         return <AdminProjects />;
//     }
//     return <h2>Select Option</h2>;
// }



class Admin1 extends Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         component: this.props.match.params.component,
            
    //     }

    // }
    // componentDidMount(){
    //     setTimeout(()=>{this.setState({ component: this.props.match.params.component})},100)
        
    // }

    // componentWillReceiveProps(){
    //     setTimeout(()=>{this.setState({ component: this.props.match.params.component})},100);
    //     console.log(this.props.match.params)
    // }





    // render() {
    //     return (
    //         <div className="content">
               
    //             <AdminDashboard />
                
    //             <AdminSelector component={this.state.component} id={this.props.match.params.id}/>
    //             {/* <AdminChat fromId={this.props.match.params.id} toId={this.props.match.params.id2} /> */}
                


    //         </div>
    //     );
    // }

}

export default Admin1;