import Service from '../components/Service';
import duix from 'duix';


export const Cart = {

    cartArray: [],
    cartTotal: 0,
    cartShipping: 0,

    initCart(){
        if(localStorage.getItem('cartItems')){
            console.log('ls',localStorage);
            this.cartArray = JSON.parse(localStorage.getItem('cartItems'));
        }
    },

    addToCart(product){
        
        console.log('pid: ', product);
        // return;
        

        // for(let p in this.cartArray){
        //     //ukoliko se id poklapa sa isbn koji je prosledjen
        //     if(this.cartArray[p].id === product.id){
        //         // this.cartArray[p].quantity ++;
        //         this.updateCart();
        //         localStorage.setItem('cartItems', JSON.stringify(this.cartArray));
        //         duix.set('modal', 'Proizvod je dodat u korpu.');
        //         //duix.set('cartItems', this.cartArray);
        //         //console.log('DX:',duix.get('cartItems'));
        //         //console.log('items',localStorage);
        //         console.log('ca',localStorage);
        //         console.log('has item');
        //         return;
        //     }else{
                
        //     }
           
        // }
        //dodavanje u niz

        for(let p in this.cartArray){
            if(this.cartArray[p].id === product.id){
                return;
            }
        }

        this.cartArray.push({id:product.id, quantity:1, project: product});
        this.updateCart();
        localStorage.setItem('cartItems', JSON.stringify(this.cartArray));
        duix.set('modal', 'Proizvod je dodat u korpu.');
        duix.set('cartItems', this.cartArray);

        //console.log('duix', duix);
        //console.log('items',localStorage);
        //console.log('ca',this.cartArray);

    },


    clearCart(){
        localStorage.removeItem('cartItems');
        this.cartArray = [];
        this.updateCart();
        //duix.set('cartItems', this.cartArray);
    },

    getTotal(){
        //Service.getNews((result)=>{console.log(result)});
        return this.cartTotal;
    },

    getCartShipping(){
        return this.cartShipping;
    },

    getCart(){
        console.log(this.cartArray);
        console.log("Cart", this.cartArray);
        let r=[];
        this.cartArray.map(function(item){
            r.push(item.id);
        })

        return r;
    },
    getCartElements(){
        
        //cd.test();
        return this.cartArray;
    },
    getCartProductsQuantities(){
        let r = [];
        for(var p in this.cartArray){
            r.push({id: this.cartArray[p].id, quantity: this.cartArray[p].quantity});
        }
        return r;
    },
    getCartProductsNumber(){
        return this.cartArray.length;
    },
    removeIfLastCopy(product){
        this.cartArray.splice(this.cartArray.indexOf(product),1);
        this.updateCart();
    },
    removeFromCart(id){

        for(let p in this.cartArray){
            if(this.cartArray[p].id === id){
                this.cartArray.splice(p,1);
            }
        }
        this.updateCart();
        localStorage.setItem('cartItems', JSON.stringify(this.cartArray));
        duix.set('cartItems', this.cartArray);
    },

    removeOneFromCart(product){
        for(let p in this.cartArray){
            if(this.cartArray[p].id === product.id){
                //isto kao i u funkciji addToCart samo sto smanjuje
                this.cartArray[p].quantity --;
                if(this.cartArray[p].quantity < 1){
                    //ukoliko value dodje na 0, brise se ceo element na p poziciji
                    this.removeIfLastCopy(this.cartArray[p]);
                }
                localStorage.setItem('cartItems', JSON.stringify(this.cartArray));
                this.updateCart();

                return;
            }
           
        }

    },


    updateCart(){
        // Service.getCart({cartProducts: this.getCartProductsQuantities()},(result)=>{
        //     this.cartTotal = result.data.cartTotal;
        //     this.cartShipping = result.data.shippingPrice;
        //     duix.set('cartItems', this.cartArray);
        // });
        //this.cartTotal = Math.round(Math.random() * 1000);
        //this.cartShipping = 180;
    }
    
 
}