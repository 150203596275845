import React, { Component } from 'react';



class RegistrationSuccess extends Component {
  
    
   

    render() {
        return (
            <div className="content">
                <p>
                    You have successfully created an account. 
                    The website administrators will review the data you submitted and after that they will activate your account. 
                    You will be notified by email. Thank you for contributing to our community.
                </p>
            </div>
        );
    }

}

export default RegistrationSuccess;