import React, { Component } from 'react';
import Service from '../components/Service';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            users: []
        }
    }

    componentDidMount=() =>{
        this.updateUsers();  
    }

    updateUsers=()=> {
        Service.get("/users/get",{}, (res) => {
            if(res.success){
                this.setState({users: res.data.users});
            }
            
        });
        
    }
    
   

    render() {
        return (
            <div className="content">
                <h1>Users</h1>
                <div className="users-table">
                    <table>
                        <tr>
                            {/* <th>
                                Role<br></br>
                                <input type="search" placeholder="Search role"></input>
                            </th> */}
                            <th>
                                Name
                                <input type="search" placeholder="Search name"></input>
                            </th>
                            <th>
                                Languages
                                <input type="search" placeholder="Search languages"></input>
                            </th>
                            <th>
                                Country
                                <input type="search" placeholder="Search country"></input>
                            </th>
                        </tr>
                        {this.state.users.map(function (item, index) {
                            return (
                                <tr>
                                    {/* <td>{item.role}</td> */}
                                    <td>
                                        <a href={'user/messages/'+item.id+'/'+item.name}>
                                            {item.name}
                                        </a>
                                    </td>
                                    <td>{item.languages}</td>
                                    <td>{item.country}</td>
                                </tr>
                            )
                        }, this)
                        }
                    </table>
                </div>
            </div>
        );
    }

}

export default Users;