import React, { Component } from 'react';



class ProfileBuyer extends Component {





    render() {
        return (
            <div>

                <h2>Buyer</h2>
            </div>
        );
    }

}

export default ProfileBuyer;