import React, { Component } from 'react';



class Portfolio extends Component {


   

    
    render(){
        return(
            <div className="content">
                
                <h1>FREELANCER PROFILE</h1>
                <div>
                    <h3 className="port-h3"> Freelancer</h3>
                    <div className="port">
                        <img src="../images/avatar.jpg"></img>
                    </div>
                    <div className="port">
                        <p>
                            Lorem ipsum dolor sit amet,<br />
                            consectetur adipiscing elit, <br />
                            sed do eiusmod tempor incididunt<br />
                            ut labore et dolore magna aliqua.<br />
                            Lorem ipsum dolor sit amet,<br />
                            consectetur adipiscing elit, <br />
                            sed do eiusmod tempor incididunt<br />
                            ut labore et dolore magna aliqua.<br />
                            Lorem ipsum dolor sit amet,<br />
                            consectetur adipiscing elit, <br />
                            sed do eiusmod tempor incididunt<br />
                            ut labore et dolore magna aliqua.<br />
                        </p>
                    </div>
                    <div className="port">
                        <button className="port-btn-1">STARTING AT</button>
                        <button>CONTACT FREELANCER</button>
                    </div>
                </div>
            </div>
        );
    }

}

export default Portfolio;