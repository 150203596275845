import React, { Component } from 'react';



class Success extends Component {





    render() {
        return (
            <div className="content">
                

                <h1>Success :) </h1>
            </div>
            
        );
    }

}

export default Success;