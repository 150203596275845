import React, { Component } from 'react';
import Service from '../components/Service';
import Icons from '../components/Icons';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import {Auth} from '../components/Auth';
import duix from 'duix';
import AudioServicesFreelancer from "../components/AudioServicesBuyer";
import AudioServicesBuyer from "../components/AudioServicesFreelancer"
import AudioServicesContributor from "../components/AudioServicesContributor"
import DashboardMenu from '../components/DashboardMenu';
import {Cart} from '../components/Cart';


class UserServicesCategories extends Component {

    constructor(){
        super();
        this.state={
            categories:[],
            services: []
        }
        this.mainHeading = React.createRef();

    }
    unsubscribe = [];
    
        componentDidMount(){
        this.getCategories();
        this.getServiceProjects();
        this.mainHeading.current.focus();
    }

    getCategories=()=>{
        Service.get("/categories/get",{categoryType: 3}, (res) => {
            this.setState({ categories: res.data.categories })
            console.log(res)
        });

    }

    getServiceProjects=()=>{
       Service.get("/users/getServiceProjects",{}, (res) => {
            this.setState({ services: res.data.projects })
        }); 
    }

    addToCart=(project)=>{
        Cart.addToCart(project);
        Cart.getCart();
        
    }

    
    // componentWilUnmount(){
    //     this.unsubscribe[0]();
    // }

    
    render(){
        return(
            <div className="dashboard-wrapper">
                <img className="bg-b-r full-width" src="/images/background-dashboard.png" />
                <div className="content">
                    <h1 ref={this.mainHeading} tabIndex="0" className="title-left">User Services category</h1>
                    <DashboardMenu />
                    {Auth.role > 1 &&
                    <fragment>
                    <p  tabIndex="0" >Please select a category.</p>
                    <div className="div-table-header">

                        {/* <div className="div-h-table-category-name">
                        Category
                        </div>
                        <div className="div-h-table-category-desc">
                            Description
                        </div>
                        <div className="div-h-table-link">
                            Link
                        </div> */}
                    

                        </div>
                        <div className="product-freelancer">
                            {this.state.categories.map(function (item, index) {
                                return (
                                    <div className="div-table" key={index}>



                                    <div className="div-table-category-name">
                                    <b>{item.name}</b> 
                                    </div> 
                                    <div className="div-table-category-desc">
                                        {item.contributor_description}
                                    </div> 
                                    <div className="div-table-link"> 
                                    <Link className="link-button" to={"/user/create-service/"+item.id}>Apply to {item.name} category</Link> 
                                    </div> 
                                
                                
                                

                                        {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
                                        {/* <div className="icon"> {Icons.icon("notes")}  </div>
                                        <h2>{item.name}</h2>
                                        <p>{item.description}</p>
                                        
                                        <Link className="link-button" to={"/user/chats/"+item.id}>Enter {item.name} category</Link> */}
                                    </div>    
                                )
                            }, this)
                            }
                        </div>
                    </fragment>
                    }
                    {Auth.role == 1 &&
                        <div className="product-freelancer">
                            {this.state.services.map(function (item, index) {
                                return (
                                    <div className="div-table" key={index}>

                                        <div className="div-table-category-name">
                                        <b>{item.name}</b> 
                                        </div> 
                                        <div className="div-table-category-desc">
                                            <p>username: {item.user_name}</p>
                                            <p>{item.description}</p>
                                        </div> 
                                        <div className="div-table-link"> 
                                        <button onClick={()=>{this.addToCart(item)}}>Add to cart</button> 
                                        </div> 
                                    </div>    
                                )
                            }, this)
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default UserServicesCategories;