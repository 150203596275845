import React, { Component } from 'react';
import Service from './Service';
import Icons from './Icons';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";

class AudioServiceBuyer extends Component {

    constructor(){
        super();
        this.state={
            messages: [],
            service:[]
          
        }

    }

    
    componentDidMount(){

        this.getMessages();
        this.getService();
        
    }

    getMessages=()=>{
        Service.get("/services/getFreelancerMessages",{serviceId: this.props.serviceId}, (res) => {
            this.setState({ messages: res.data.messages })
            console.log(res)
        });

    }
    getService=()=>{
        Service.get("/services/getSingle",{serviceId: this.props.serviceId}, (res) => {
            this.setState({ service: res.data.service })
            console.log(res)
        });

    }


    
    render(){
        return(
            <div className="content">
                <h1>Audio Services Buyer</h1>
                <h2>{this.state.service.title}</h2>
                <p>{this.state.service.description}</p>
                <p>{this.state.service.price}</p>
                <p>{this.state.service.id}</p>
                
                    <div className="product-freelancer">
                        {this.state.messages.map(function (item, index) {
                            return (
                                <div  key={index}>
                                    {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
                                    {/* <div className="icon"> {Icons.icon("notes")}  </div> */}
                                    <h4>{item.owner_id}</h4>
                                    <p>{item.description}</p>
                                    <p>{item.title}</p>
                                    <Link to={"/projects/"+item.service_id}>More</Link>
                                </div>    
                            )
                        }, this)
                        }
                    </div>
                
               
            </div>
        );
    }
}

export default AudioServiceBuyer;