import React, { Component } from "react";
import { BrowserRouter as Router, Switch,Redirect, Route, Link } from "react-router-dom";
import Service from '../components/Service';
import Icons from '../components/Icons';


class AudioStock extends Component {
    
  constructor(props){
    super(props);
    this.state={
        categories:[]
    }
    this.mainHeading = React.createRef();

  }


  componentDidMount(){
    this.UpdateComponents();
    this.mainHeading.current.focus()

}

UpdateComponents=()=>{
    Service.get("/categories/get",{categoryType: 2}, (res) => {
        this.setState({ categories: res.data.categories })
        console.log(res)
    });
   

}





  


  render() {
    return (
      <div className="audio-stock-wrapper">
        <img className="bg-b-r" src="/images/background-audio-stock.png" />
        <div className="content" >
          <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Audio Stock</h1>
          <p tabIndex="0" className="title-left">Please select a audio stock category.</p>
          <div className="stock-categories">
            {this.state.categories.map(function (item, index) {
              return (
                  <div className="product-view" key={index}>
                    {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
                    <div className="icon"><img src={"../images/"+item.id+"-stock.jpg"} alt={item.title}/></div>
                    <Link to="#">{item.title}</Link>
                    <h2>{item.name}</h2>
                    <p>{item.description}</p>
                    {/* <input value="Search..." /> */}
                    <Link className="link-button"to={"/projects/"+item.id}>SEE MORE>></Link>
                  </div>
                )
              }, this)
            }
          </div>
            <p>{this.props.match.params.this}</p>
          </div>
        </div>
    );
  }
}

export default AudioStock;
