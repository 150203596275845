import React, { Component } from 'react';



class ProfileContributor extends Component {





    render() {
        return (
            <div>

                <h2>Contributor</h2>
            </div>
        );
    }

}

export default ProfileContributor;