import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
// import Icons from './Icons';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Aria from '../components/Aria';


class UserServiceChat extends Component {

    constructor(){
        super();
        this.state={
            messages: [],
            service:[],
            projectName: '',
            projectDescription: '',
            price:'',
            alert: '',
            fileId: '',
            length:'',
            tempo:'',
            alert2:''




          
        }
        this.mainHeading = React.createRef();

    }
    interval;
    
    componentDidMount(){
        setTimeout(()=>{
        this.getChat()
        // this.getService();
        }, 10)
        
        this.interval=setInterval(()=>{
            this.getChat()
            // this.getService();
            }, 3000)
            this.mainHeading.current.focus();
        
    }
    componentWillUnmount(){
        clearInterval(this.interval)

    }


    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }


    getChat=()=>{
        Service.get("/services/getMessages",{categoryId: this.props.match.params.categoryId, userId:this.props.match.params.userId}, (res) => {
            this.setState({ messages: res.data.messages })
            console.log(res)
        });

    }

    getService=()=>{
        Service.get("/services/getSingle",{serviceId: this.props.serviceId}, (res) => {
            this.setState({ service: res.data.service })
            console.log(res)
        });

    }

    handleTextInput=(event)=>{
        this.setState({messageToSend: event.target.value});
    }

    sendMessage=()=>{
        Service.get("/services/sendMessage",{text: this.state.messageToSend, userId: this.props.match.params.userId, categoryId: this.props.match.params.categoryId},()=>{
            this.getChat();
            this.setState({messageToSend: ''});
        });
    }

    upload = (event) => {
        const files = Array.from(event.target.files)
        this.setState({ uploading: true })
    
        const formData = new FormData()
    
        files.forEach((file, i) => {
            formData.append(i, file)
        })

        // const updateProgress = (l, t) => {
        //     this.setState({uploaded: l, max: t})
        // }


        formData.append('fromId', Auth.userId);     //Auth.userId || 567.
        formData.append('toId', this.props.match.params.userId);
        formData.append('categoryId', this.props.match.params.categoryId);
    
        console.log(formData);
        this.setState({alert: 'Uploading file, please wait.'},()=>{

            
            Service.uploadAudioMessages(formData,(res)=>{
                //console.log(res);
                console.log("res",res)
                




                if(res.success){
                    this.setState({alert: 'File successfuly uploaded.'})

                }else {
                    this.setState({alert: 'Error uploading file.'})
                }
                // }
                this.getChat();
            },(res) => {
                console.log("res",res)
                if(res.success){
                    this.setState({alert: 'File successfuly uploaded.'})

                }else {
                    this.setState({alert: 'Error uploading file.'})
                }
            })
        })
    }

    createProject = () => { 
        console.log(this.state);
        if( !this.state.projectName && !this.state.projectDescription  ) { 
            this.setState({alert2: 'Please fill all input fields.'})
            return;
        } else {
            Service.get("/users/createproject",{ 
                    projectName: this.state.projectName,
                    projectDescription: this.state.projectDescription,
                    price: this.state.price,
                    buyerId:this.props.match.params.userId,
                    length:"n/a",
                    tempo:"n/a",
                    categoryId: this.props.match.params.categoryId

                }, (res) => {
                    if(res.success){
                        this.setState({alert2: 'Project successfuly created.',
                        projectName:"",
                        projectDescription:"",
                        price:"",



                
                })
                    }else {
                        this.setState({alert2: 'Error creating project.'})

                    }
                console.log(res)

            });
        }
    }




    
    render(){
        return(
            <div className="content">
                <h1 ref={this.mainHeading} tabIndex="0">Chat</h1>
                {/* <h2>{this.state.service.title}</h2>
                <p>{this.state.service.description}</p>
                <p>{this.state.service.price}</p>
                <p>{this.state.service.id}</p> */}
                
                    <div>
                        {this.state.messages.map(function (item, index) {
                            return (
                                <div className="chat-message" key={index}>
                                    {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
                                    {/* <div className="icon"> {Icons.icon("notes")}  </div> */}
                                    <h5>{item.name}</h5>

                                    {item.type == 'file' && 
                                        <audio controls src={Service.media + '/audio/' + item.text } controlsList="nodownload"></audio> 

                                    }
                                    {item.type == 'project' && 
                                        <a href={"/project/"+item.project_id} title={"View project "+item.text} >View project</a>

                                    }


                                    {/* {item.type == '' &&  */}
                                        <p tabIndex="0">{item.text}</p> 

                                    {/* } */}
                                    <h6>{item.created_at}</h6>


                                    
                                   {/* <Link to={"/projects/"+item.service_id}>More</Link> */}
                                </div>    
                            )
                        }, this)
                        }
                        <div>
                            <textarea id='message-to-send' onChange={this.handleTextInput} value={this.state.messageToSend} ></textarea>
                            <button  onClick={this.sendMessage}>Send message</button>
                        </div>
                        <div>
                            <label for="multi" class="btn">Choose File</label>
                            <input type='file' id='multi' name="userImage[]" onChange={this.upload} multiple data-buttonText={"Upload File"} />
                            <Aria message={this.state.alert} />
                        </div>
                    </div>
                    <div>
                        <h2 tabIndex="0">Create project</h2>
                        
                            <label for="projectName">Project Name</label> 
                            <input id="projectName" type="text" value={this.state.projectName} onChange={this.handleInput}></input>
                       
                            <label for="projectDescription">Project description</label> 
                            <input id="projectDescription" type="text" value={this.state.projectDescription} onChange={this.handleInput}></input>
                       
                            <label for="price">Price</label>
                            <input id="price" type="text" value={this.state.price} onChange={this.handleInput}></input>
                      
                            <label for="keywords">Keywords</label>
                            <input id="keywords" type="text" value={this.state.keywords} onChange={this.handleInput}></input>
                        
                          
                        <button onClick={this.createProject}>CREATE</button>
                        <Aria message={this.state.alert2} />
                    </div>
                
               
            </div>
        );
    }
}

export default UserServiceChat;