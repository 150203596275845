import React, { Component } from 'react';

class ConversionStatment extends Component {
    render() {
        return (
            <div className="conversion-statement">
                <h5>*Association "Youth Cultural Center of Vojvodina" is not in the VAT system</h5>
                <h5>
                    {/* *All payments will be made in the local currency of the Republic of Serbia - Dinar (RSD). The exchange rate is used for the informative display of prices in 
                    other currencies (the middle exchange rate of the National Bank of Serbia). The amount for which your payment card 
                    will be charged will be expressed in your local currency through conversion into the same at the exchange rate used by 
                    card organizations, which cannot be known to us at the time of the transaction. As a result of this conversion, there is a possibility of a 
                    slight difference from the original price listed on our site. Thank you for your understanding. */}
                    *All payments will be effected in Serbian currency – dinar (RSD). The amount your credit 
                    card account will be charged for is obtained through the conversion of the price in Euro into 
                    Serbian dinar according to the current exchange rate of the Serbian National Bank. When charging 
                    your credit card, the same amount is converted into your local currency according to the exchange 
                    rate of credit card associations. As a result of this conversion there is a possibility of a 
                    slight difference from the original price stated in our web site.
                </h5>
            </div>
        );
    }

}

export default ConversionStatment;
