import React, { Component } from 'react';
import Service from './Service';
import Icons from './Icons';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import AudioServiceChat from "../views/AudioServiceChat";

class AudioServiceFreelancer extends Component {

    constructor(){
        super();
        this.state={
            chats: [],
            service:[],
            
          
        }

    }

    
    componentDidMount(){

        this.getChats();
        this.getService();
        
    }

   
    

    getChats=()=>{
        Service.get("/services/getOwnerChats",{categoryId: this.props.categoryId}, (res) => {
            this.setState({ chats: res.data.chats })
            console.log(res)
        });

    }
    getService=()=>{
        Service.get("/services/getSingle",{serviceId: this.props.serviceId}, (res) => {
            this.setState({ service: res.data.service })
            console.log(res)
        });

    }

   


    
    render(){
        return(
            <div className="content">
                <h1>Audio Services Freelancer</h1>
                
                <h2>{this.state.service.title}</h2>
                <p>{this.state.service.description}</p>
                <p>{this.state.service.price}</p>
                <p>{this.state.service.id}</p>
                
                    <div className="product-freelancer">

                        {this.state.chats.map(function (item, index) {
                            return (
                                <div  key={index}>
                                    {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
                                    {/* <div className="icon"> {Icons.icon("notes")}  </div> */}
                                    <h4>{item.correspondent.name}</h4>
                                    
                                    
                                   <Link to={"/audio-service-chat/"+this.props.serviceId+"/"+item.correspondent.id}>More</Link>
                                </div>    
                            )
                        }, this)
                        }
                    </div>
                   
                
               
            </div>
        );
    }
}

export default AudioServiceFreelancer;