import React, { Component } from 'react';


class SellingRules extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
        };
        this.mainHeading = React.createRef();
    }

    componentDidMount() {
        this.mainHeading.current.focus();
    }

    render(){
        return(
            <div className="tof-wrapper">
                 <img className="bg-b-r full-width" src="/images/background-terms-of-use.png" />
                <div className="content">
                    <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Selling Rules</h1>
                    <div className="tof-textbox">
                        {/* Seeling rules */}
                        <p>
                            These Selling Rules represent supplemental terms to the Terms of Use of the
                            www.soundsy.net website. If there is any conflict between Selling Rules and Terms of Use,
                            Selling Rules will control.
                        </p>

                        <p>
                            In order to buy/sell products on soundsy.net the user needs to be registered and logged in.
                        </p>

                        {/* Delivery of purchased goods */}
                        <h3>1. Delivery of purchased goods</h3>
                        <p>1.1. The audio material will be delivered to the customer by downloading from the website www.soundsy.net, immediately after payment.</p>
                        <p>1.2. Audio materials sold in the form of &quot;audio-stock&quot; will be permanently available on the website www.soundsy.net.</p>
                        <p>1.3. Audio materials ordered from the authors will be available on www.soundsy.net in a maximum of 90 days after payment has been made. The authors negotiate the price of the specially ordered material directly with the customers.</p>
                        <p>1.4. The Association &quot;Youth Cultural Center of Vojvodina&quot; is not obliged to pay customs or any
                            other duties on the delivered goods. Buyers are liable to pay all fiscal duties under the
                            legislation of the domicile states.</p>
                        
                        {/* Fee and commission */}
                        <h3>2. Fee and commission</h3>
                        <p>2.1. Association &quot;Youth Cultural Center of Vojvodina&quot;, as the organizer of the site www.soundsy.net has 
                            the right to a commission in the amount of 20% of each purchase made through the site, on behalf of the 
                            costs of money transactions, marketing and site maintenance and other administration.

                        </p>
                        <p>2.2. Additionally to the stated in point 2.1.  of these Rules, the costs of transactions of domestic and foreign banks, 
                            the costs of currency exchange are borne by the author, in the amount of the actual costs charged by commercial banks, 
                            which will be shown to him when withdrawing money.


                        </p>
                        <p>
                            2.3. The author may request the transfer of funds to his account in the amount of at least
                            EUR 100.00, which amount is reduced by the commission from item 2.1. of these Rules and
                            the costs from item 2.2. of these Rules.
                        </p>

                        {/* 2. Complaint */}
                        <h3>2. Complaint</h3>
                        <p>
                            2.1. When buying ready-made audio materials in the form of "audio-stock", 
                            which are published on the site www.soundsy.net, customers do not have 
                            the opportunity to complain or refund, since it is possible to listen to 
                            all audio material before purchase, except in the case technical malfunctions 
                            of the downloaded audio file.
                        </p>
                        <p>
                            2.2. If the audio material ordered by the author, and not published on the site in the form of 
                            "audio-stock", does not comply with the agreement between the buyer and the author, or the quality 
                            of audio material does not match the agreed quality, or it is not delivered within the agreed time, 
                            the buyer has right to issue a written complaint to the site administration by sending an e-mail to <a href="mailto:contact@soundsy.net" className="contact-email">contact@soundsy.net</a>, 
                            and if the complaint is grounded, the buyer has the right to a refund or to hire another author 
                            to create audio material.
                        </p>
                        
                        <p>
                            2.3. The deadline for the complaint referred to in item 2.2 of these Rules is 15 days from the day of taking over the audio material, 
                            and the deadline for the decision on the complaint is 15 days from the day of submitting the complaint.
                        </p>

                        {/* 3. Sending commercial messages */}
                        <h3>3. Sending commercial messages</h3>
                        <p>
                            3.1. Sending a commercial message electronically is allowed only with the prior consent of
                            the person for whom this type of message is intended.
                        </p>
                        <p>
                            3.2. The service provider is obliged to regularly check and accept the revocation of consent
                            given by a person who does not wish to receive such commercial messages.
                        </p>
                        <p>
                            3.3. Each user of the site www.soundsy.net can choose whether he wants to receive
                            notifications by mail about the new offers of the site. Mail addresses will not be used for
                            marketing. Every user of the site will be able to send a request to be removed from the mail
                            list at any time.
                        </p>

                        {/* 4. Protection of confidential transaction data */}
                        <h3>4. Protection of confidential transaction data</h3>
                        <p>
                            4.1. When entering payment card data, confidential information is transmitted via a public
                            network in a protected (encrypted) form using SSL protocols and PKI systems, as currently
                            the most modern cryptographic technology. Data security during the purchase is
                            guaranteed by the payment card processor ChipCard a.d Belgrade, so the complete billing
                            process is performed on the ChipCard website. At no time is payment card information
                            available to our system.
                        </p>

                        {/* 5. Refund */}
                        <h3>5. Refund</h3>
                        <p>
                            5.1. In case of return of goods and return of funds to the buyer, in accordance with the
                            provisions of point 2.2 of these Rules, which the buyer previously paid to one of the
                            payment cards, in part or in full, and regardless of the reason for return, the obligation to
                            make a refund exclusively through VISA, EC / MC and Maestro payment methods.
                        </p>

                        <h3>Conversion Statment</h3>
                        <p>
                            All payments will be effected in Serbian currency – dinar (RSD). The amount your credit 
                            card account will be charged for is obtained through the conversion of the price in Euro into 
                            Serbian dinar according to the current exchange rate of the Serbian National Bank. When charging 
                            your credit card, the same amount is converted into your local currency according to the exchange 
                            rate of credit card associations. As a result of this conversion there is a possibility of a 
                            slight difference from the original price stated in our web site. 
                        </p>
                    </div>
                </div>
            </div>
        );
    }

}

export default SellingRules;