import React, { Component } from 'react';



class FAQ extends Component {

    constructor(props) {
        super(props);

        this.mainHeading = React.createRef();

    }


    componentDidMount() {
        this.mainHeading.current.focus();
    }


    render() {
        return (
            <div className="faq-wrapper">
                 <img className="bg-b-r full-width" src="/images/background-faq.png" />
                <div className="content">
                    <h1 ref={this.mainHeading} tabIndex="0"className="title-left">FAQ</h1>

                    <div className="faq-int">
                        <h3>May I create a profile and sell audio files or offer services if I am not a visually impaired person?</h3>
                        <p>
                            You cannot create a profile if you are not a visually impaired person. This platform is dedicated to persons with visual impairments, with the goal of supporting self-employment. 
                        </p>

                        <h3>Who can be the buyer?</h3>
                        <p>
                            The buyer is a private person or company which wants to buy audio files for commercial purposes, or to hire a freelancer who offers audio production services.
                        </p>

                        <h3>Is the usage of the stock file time-limited after the purchase? </h3>
                        <p>
                            No. After buying a stock file you can use it without a time limit. This means you can use the file unlimited number of times for commercial purposes.
                        </p>

                        <h3>How to register as a buyer?</h3>
                        <p>
                            Choose option “Sign in” at home page, then choose option “Sign up”. There you can select a profile for the buyer, then follow the steps.
                        </p>

                        <h3>Can I register a buyer profile if I am not a visually impaired person?</h3>
                        <p>
                            The buyer does not have to be a visually impaired person.
                        </p>

                        <h3>How can I register myself as a freelancer?</h3>
                        <p>
                            Choose option “Sign in” at home page then choose option “Sign up”. There you can select a profile for a freelancer, then follow the steps.
                        </p>

                        <h3>What`s the role of the freelancer`s profile?</h3>
                        <p>
                            Freelancer profiles are exclusive to persons with visual impairments.  The freelancer can sell audio stock files and offer their services for audio production.
                        </p>

                        <h3>Can I register one profile with more different roles?</h3>
                        <p>
                            If you register yourself as a freelancer, your buyer profile will be automatically activated. If you register yourself as a buyer, you will not get the role of freelancer automatically; in this case you can only be a buyer. 
                        </p>

                        <h3>What are the supported audio formats for stock selling on this platform?</h3>
                        <p>
                            Stock audio files need to be in WAV format, minimum 44,100 Hz sample rate and 16 bit rate.
                        </p>

                        <h3>What's the maximum file size?</h3>
                        <p>
                            Maximum file size that you can upload for stock selling is 200 MB. If you wish to deliver your work to the buyer, you can upload a file up to 2 GB in size. The file will be available for download for seven days, after which it will be permanently deleted from the server.
                        </p>

                        <h3>Is there a maximum limit of files I can purchase?</h3>
                        <p>
                            No, there is no limit for this. You can buy as many files as you want. 
                        </p>

                        <h3>Is there a maximum limit of files I can upload for selling?</h3>
                        <p>
                            No, there is no limit on how many files you can upload for sale.
                        </p>

                        <h3>How can I make a purchase?</h3>
                        <p>
                            Simply choose a file that you want to buy and click “add to cart”, then follow the steps.
                        </p>

                        <h3>How can I pay for a purchase?</h3>
                        <p>
                            The only option for paying for a purchase is by credit card.
                        </p>

                        <h3>Can I report a problem?</h3>
                        <p>
                            Ofcourse! Be free to send us an email with an explanation of a problem you’re facing to our email address: contact@soundsy.net
                        </p>

                        <h3>Can I negotiate the price of service that I am offering?</h3>
                        <p>
                            You can negotiate the price of the service that you are offering to a buyer – directly communicating with the buyer. 
                            You can set the price for the work that the buyer requested from you. 
                        </p>

                        <h3>Can I negotiate the price of a file that I am selling on stock?</h3>
                        <p>
                            No, you cannot negotiate the price of a file that you are selling on stock. Price is set by administrator after reviewing file uploaded for sell on stock.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

}

export default FAQ;