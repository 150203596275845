import React, { Component } from 'react';
import Service from '../components/Service';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import {Auth} from '../components/Auth';
import duix from 'duix';
import UserProjectsBuyer from "../components/UserProjectsBuyer";
import UserProjectsContributor from "../components/UserProjectsContributor";
import UserProjectsFreelancer from "../components/UserProjectsFreelancer";



function UserProjectsSelector (props) {
     
    if (props.role == 1) {
      return <UserProjectsBuyer />;
    }  
    if (props.role == 2) {
        return <UserProjectsContributor />;
    }
    if (props.role == 3) {
        return <UserProjectsFreelancer />;
    }
    return <h1>No role has been given!</h1>;
}



class UserProjects extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            role:5,
            projects: [],
            
        }

    }

    unsubscribe = [];
    
    componentDidMount(){
        setTimeout(()=>{
            this.setState({role: Auth.role})
        }, 100);

        this.unsubscribe[0] = duix.subscribe('changeRole', () => {
            this.setState({role: Auth.role});
       });
    }

    
    // componentWilUnmount(){
    //     this.unsubscribe[0]();
    // }


    render() {
        return (
            <div className="dashboard-wrapper">
                <img className="bg-b-r full-width" src="/images/background-dashboard.png" />
                <div className="content">
                    <div>
                    
                    <UserProjectsSelector role={this.state.role}/>

                    </div>
                </div>
            </div>
            
        );
    }
}

export default UserProjects;