import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import duix from 'duix';
import CartDisplay from '../components/CartDisplay';
import Select from 'react-select';


function resizeContent(){
        let w = window.innerWidth;
        let h = window.innerHeight;
        let fh = document.getElementById("footer").offsetHeight ;
        let ch = document.getElementsByClassName("content")[0].offsetHeight;
        console.log(w,h,fh,ch)
        if(ch < h-fh){
        document.getElementsByClassName("content")[0].style.minHeight = (h-fh)+"px";

        }
    }

// window.onresize = function(){resizeContent()};
const options = [
  { value: '1', label: 'Buyer' },
  { value: '2', label: 'Freelancer' },
  { value: '9', label: 'Sign out' }
];

const customStyles = {
  container: (base, state) => ({
    ...base,
    transition:
      "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
    "&:hover": {
      boxShadow: "0 2px 4px 0 gray"
    }
  }),
  control: (base, state) => ({
    ...base,
    background: "black",
    borderRadius: "0em",
    border:"1px solid white"
  }),
  option: (provided, state) => ({
    ...provided,
    // color: state.isSelected ? 'gray' : 'white',
    backgroundColor: state.isFocused ? '#333' : 'black',
    color: state.isFocused ? 'white' : 'white',
    borderBottom: '1px solid gray',
  }),
  menu: (base, state) => ({
    ...base,
    background: "black",
    borderRadius:"0em"
  }),
  valueContainer: (base, state) => ({
    ...base,
    background: "black"
  }),
  singleValue: (base, state) => ({
    ...base,
    color: "white"
  }),
  placeholder: (base, state) => ({
    ...base,
    fontSize: ".9em",
    color:"white"
  }),
};


class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      role: '',
      menuVisible: 'block',
      chats:[],
    }
  
  }

  

  componentDidMount() {
      setTimeout(()=>{
            this.setState({role: Auth.role});
      },100);
      
      document.title = 'Soundsy';
      if(window.screen.width < 720){
          this.setState({menuVisible: 'none'});
      }
      
      setInterval(()=>{
        this.getChats()},10000);
      
      }

   
   getChats=()=>{
     Service.get("/users/getChats",{userId: Auth.userId}, (res) => {
        console.log(res);
        this.setState({chats: res.data});
    }); 
}

    changeRole = role => {
      if(role.value == '9'){
        Auth.logout(
         ()=>{ window.location = '/'; }
        );
      }else{
        Service.get('/users/changeRole',{newRole: role.value},(res)=>{
          console.log(res);
          if(res.success){
              Auth.changeRole(res.role,()=>{
                  this.setState(role);
                  console.log('changed', res.role);
              });
              
          }
        })
      }
    };
    
    // changeRole=(event)=>{
    //     console.log(event.target.value);
    //     Service.get('/users/changeRole',{newRole: event.target.value},(res)=>{
    //         console.log(res);
    //         if(res.success){
    //             Auth.changeRole(res.role,()=>{
    //                 this.setState({role: Auth.role});
    //             });
                
    //         }
    //     })
    // }

    toggleMenu=()=>{
        if(this.state.menuVisible == 'block'){
            this.setState({menuVisible: 'none'});
        }else{
            this.setState({menuVisible: 'block'});
        }
    }

    closeMenu=()=>{
        if(window.screen.width < 720){ // samo ako je mali ekran da se hajduje meni
            this.setState({menuVisible: 'none'});
        }
        setTimeout(()=>{
            resizeContent();
        },100)
    }
    


  render() {
    const roleSelected = this.state.role - 1;
    //console.log()

    return (
      <div id="header">
        <div className="header-logo">

            <Link to="/"><img src="/images/logo2.png" alt="Soundsy" /></Link>
            <a href="#" className="hamburger" onClick={this.toggleMenu} title="menu"><i className="fas fa-bars"></i></a>
        </div>

        <div className="header-navigation">
          <ul style={{display: this.state.menuVisible}} onMouseUp={this.closeMenu}>
            <li>
              <Link to="/">Home</Link>
            </li>
            {/* {Auth.role == 1 &&  */}
            <li>
              <Link to="/audio-stock">Audio Stock</Link>
            </li>
            <li>
              <Link to="/audio-services-categories">Audio Services</Link>
            </li>
            {/* } */}
            <li>
              <Link to="/about">About </Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            {Auth.isAuthenticated === true && 
            <li> 
              <Link to="/user/dashboard">Dashboard</Link>
            </li>
            } 


          {/* { Auth.isAuthenticated === true && 
          <li> 
          <Link to="/user/messages/">Inbox <p>{this.state.chats.totalUnreadMessageCount }</p></Link>
          </li>
          }  */}
          {/* {  Auth.isAuthenticated === false && 
          <li> 
          <Link to="/sign-up">Sign up</Link>
          </li>
          }  */}
            <li>
              <Link to="/role-selector">  
                {  Auth.isAuthenticated === false && 
                  <button className="header-button">Sign In</button>
                }
                {/* {  Auth.isAuthenticated === true && 
                  <button className="header-button" onClick={()=>{Auth.signout(()=>{window.location="/"})}}>Sign out</button>
                } */}
              </Link>
            </li>
           {Auth.isAuthenticated === true && 
            <li>
              {/* <select className="header-select" name="opcija" value={this.state.role} onChange={this.changeRole}>
                <option value="1" >Buyer</option>
                <option value="2" >Contributor</option>
                <option value="3" >Freelancer</option>
              </select> */}
              {/* <pre>Lokok"{options.label[1]}"</pre> */}
              <Select
                className={"header-select-container"}
                classNamePrefix={"header-select-container"}
                styles={customStyles}
                value={options[this.state.role - 1]}
                defaultValue={options[this.state.role - 1]}
                onChange={this.changeRole}
                options={options}
              />
            </li>
            }
            {this.state.role == 1 &&
              <li>
                <Link to="/cart"><CartDisplay ref={this.cartDisplay}/></Link>
              </li>
            } 
            <li>
              <input type="search" placeholder="Search" className="search-header"></input>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Header;
