import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import Aria from '../components/Aria';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Chats from '../components/Chats';
import DashboardMenu from '../components/DashboardMenu';
import Report from '../components/Report';


class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            chats: [],
            messageToSend: '',
            uploading: false,
            fileId: '',
            fromId: '',
            toId: '',
            uploaded: 0,
            max: 0,
            projectName: '',
            projectDescription: '',
            price:'',
            alert: '',
            length:'',
            tempo:'',
            alert2:'',
            create:false,
            isProject:'',
            currentName:'',
            currentLastName:'',
            currentUsername:'',
            showingReport: true
        };
        this.mainHeading = React.createRef();
    }

    interval;
    
    handleTextInput=(event)=>{
        this.setState({messageToSend: event.target.value});
    }
    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    getChats=()=>{
        Service.get("/users/getChats",{userId: Auth.userId}, (res) => {
           if(res.success){
            this.setState({chats: res.data.chats}, ()=>{
                if(this.props.userId){
                    this.updateCurrentUsername();
                }
            });
           }
       }); 
   }

//    updateCurrentName=()=>{
//     let cn = "";
//     let cln = "";
//     let cun= "";
//     this.state.chats.map(function(item) {
//         console.log('chats', item)
//         if(item.correspondent.id == this.props.userId){
//             cn = item.correspondent.name;
//             cln = item.correspondent.lastName;
//             cun = item.correspondent.username;
//         } 
//     },this)
//    this.setState({
//        currentName: cn,
//        currentLastName: cln,
//        currentUsername: cun
//     });
// }
    
    
    componentDidMount() {
        setTimeout(()=>{
            this.getMessages();
        },1000);

        this.mainHeading.current.focus();

        this.interval = setInterval(()=>{
            //console.log('interval chat.js');
            this.getMessages();
        }, 2000);

        setTimeout(()=>{
            console.log(this.props.userId);
            this.getChats();
        },1000);
    }

    componentWillUnmount(){
        clearInterval(this.interval);
    }

    sendMessage=()=>{
        Service.get("/users/sendMessage",{text: this.state.messageToSend, toId: this.props.userId, fromId:Auth.userId ,isProject:this.state.isProject,
            projectName:this.state.projectName, projectDescription:this.state.projectDescription,price:this.state.price},()=>{
            this.getMessages();
            this.getChats();
            this.setState({messageToSend: ''});

        });
    }

    scrollBarBottom=()=>{
        var messageBody = document.querySelector('#chat-right-content');
        messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }

    getMessages=()=>{
    
        Service.get("/users/getMessages",{toId: this.props.userId, fromId: Auth.userId}, (res) => {
            if(res.success){
                this.setState({messages: res.data.messages},()=> {
                    this.scrollBarBottom();
                    if(this.props.userId){
                        this.updateCurrentUsername();
                    }
                })
            }
        }); 
    }

    updateCurrentUsername=()=>{
        Service.get("/users/getUsername",{userId: this.props.userId}, (res) => {
            this.setState({ currentUsername: res.data.user_name })
            console.log("res", res)
        });
    }


    // upload = (event) => {
    //     const files = Array.from(event.target.files)
    //     this.setState({ uploading: true })
    
    //     const formData = new FormData()
    
    //     files.forEach((file, i) => {
    //         formData.append(i, file)
    //     })

    //     const updateProgress = (l, t) => {
    //         this.setState({uploaded: l, max: t})
    //     }

    //     formData.append('userId');
    
    //     console.log(formData);

    //     var request = new XMLHttpRequest();
    //     request.open("POST", Service.api+'/media/uploadAudio');
    //     //request.send(formData);
    //     // console.log('lngth',formData.getLengthSync());
    //     // request.onprogress = function (res) {
    //     //     console.log(res);
    //     //     //if (res.lengthComputable) {
    //     //         console.log(res.loaded+  " / " + res.total)
    //     //     //}
    //     // }
    //     request.upload.addEventListener("progress", function(evt){
    //     if (evt.lengthComputable) {
    //         //this.setState({uploaded: evt.loaded, max: evt.total})
    //         updateProgress(evt.loaded, evt.total);
    //         console.log("add upload event-listener" + evt.loaded + "/" + evt.total);
    //     }
    //     }, false);

    //     request.send(formData);
    // }

    upload = (event) => {
        const files = Array.from(event.target.files)
        this.setState({ uploading: true })
    
        const formData = new FormData()
    
        files.forEach((file, i) => {
            formData.append(i, file)
        })

        formData.append('fromId', Auth.userId);     //Auth.userId || 567.
        formData.append('toId', this.props.userId);
        formData.append('categoryId', '1');
        
        console.log(formData);
        this.setState({alert: 'Uploading file, please wait.'},()=>{

            Service.uploadAudioMessages(formData,(res)=>{
                //console.log(res);
                console.log("res",res)
                if(res.success){
                    this.setState({alert: 'File successfuly uploaded.'})

                }else {
                    this.setState({alert: 'Error uploading file.'})
                }
                // }
                this.getMessages();
                },(res) => {
                    console.log("res",res)
                    if(res.success){
                        this.setState({alert: 'File successfuly uploaded.'})

                    }else {
                        this.setState({alert: 'Error uploading file.'})
                    }
                })
        })
    }

    createServiceProject = () => { 
        console.log(this.state);
        if( !this.state.projectName && !this.state.projectDescription  ) { 
            this.setState({alert2: 'Please fill all input fields.'})
            return;
        } else {
            Service.get("/users/createServiceProject",{ 
                    projectName: this.state.projectName,
                    projectDescription: this.state.projectDescription,
                    price: this.state.price,
                    buyerId:this.props.buyerId,
                    length:"n/a",
                    tempo:"n/a",
                    categoryId: this.props.categoryId,
                    serviceId:this.props.serviceId

                }, (res) => {
                    if(res.success){
                        this.setState({alert2: 'Project successfuly created.',
                        projectName:"",
                        projectDescription:"",
                        price:"",



                
                })
                    }else {
                        this.setState({alert2: 'Error creating project.'})
                        
                    }
                    console.log(res)
                

            });
        }
    }

    createJob=()=>{
        if(this.state.create){
            this.setState({create:false})
        }else{
            this.setState({create:true})


        }

    
    }



 myMessage=(a,b)=>{
    if(parseFloat(a)===parseFloat(b)){
    return " my-message";
    }else{
        return '';
    }
 }

//  Send message on enter press
 defaultButton = (e) => {
    if(e.which === 13) {
      this.sendMessage();
    }
  }

  createServiceProject=()=>{

  }
    
    render(){
        const { showingReport } = this.state;
        let userId = this.props.userId;
        return(

            <div>
                <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Messages</h1>
                <DashboardMenu />
                <div className="chat-container"> 
                        <div className="chat-left">
                            <div className="chat-title-left">
                            <h2>Inbox</h2>
                            </div>
                            <div className="chat-left-content">
                                {this.state.chats.map(function (item, index) {
                                    return (
                                        <div class="chat-left-box"  id={userId === item.correspondent.id ? 'current-chat' : ''}>
                                            <Link to  ={"/user/messages/"+item.correspondent.id}> 
                                                <h3> {item.correspondent.username}</h3>
                                                <p>{item.lastMessage[0].text}</p>         
                                            </Link>    
                                        </div>  
                                        );
                                        
                                    }
                                )}
                            </div>
                        </div>
                        <div className="chat-right">
                            <div className="chat-title-right">
                                <h2>{this.state.currentUsername}</h2>
                                {this.state.currentUsername &&
                                    <div className="report-button">
                                        <button className="report" onClick={() => this.setState({ showingReport: !showingReport })}>Report user</button>
                                    </div>
                                }
                            </div>
                            <div className="chat-right-content" id="chat-right-content">
                            {this.state.messages.map(function (item, index) {
                                return (

                                    
                                <div> 
                                    {Auth.userId != item.to_id &&
                                        <div className={"chat-message my-message"}  id="my-message">
                                      
                                      
                                            {/* <h3>{item.from_id}</h3> */}
                                          
                                          
                                            {item.type == 'file' && 
                                                <audio controls src={Service.media + '/audio/' + item.text } controlsList="nodownload"></audio> 

                                            }

                                            {item.type == 'text' && 
                                                <p>{item.text}</p> 

                                            }

                                            {item.type == 'project' && 
                                                <a href={"/project/"+item.project_id} title={"View service "+item.text} >View service </a>

                                            }
                                        
                                        
                                           

                                            <h6>{item.created_at}</h6>
                            
                            
                                        </div>
                                    }

                            {Auth.userId != item.from_id  &&
                                        <div className={"chat-message "}>
                                      
                                      
                                            {/* <h3>{item.from_id}</h3> */}
                                          
                                          
                                            {item.type == 'file' && 
                                                <audio controls src={Service.media + '/audio/' + item.text } controlsList="nodownload"></audio> 

                                            }

                                            {item.type == 'text' && 
                                                <p>{item.text}</p> 

                                            }

                                            {item.type == 'project' && 
                                                <a href={"/project/"+item.project_id} title={"View service "+item.text} >View service </a>

                                            }
                                        
                                        
                                           

                                            <h6>{item.created_at}</h6>
                            
                            
                                        </div>
                                    }




                                </div>
                                        
                                    
                                )
                                }, this)
                            }

                            </div>
                        </div>
                        <div className="chat-send-field">
                            <div className="chat-send-input">
                                <input id='message-to-send' onChange={this.handleTextInput} onKeyPress={this.defaultButton} value={this.state.messageToSend} placeholder="Type a message..." />
                                <button  onClick={this.sendMessage}><i className="fa fa-paper-plane fa-lg" onKeyPress={this.defaultButton} aria-hidden="true"></i></button>
                            </div>
                            <div className="chat-send-file">
                                <label for="multi" class="btn" id="send-file-button">+</label>
                                <input type='file' id='multi' name="userImage[]" onChange={this.upload} multiple/> 
                            </div>
                            
                            
                        </div>
                        
                </div> 
                <br />
                {Auth.role > 1 &&
                    <div className="add-new-buttons"><a href={`/user/projects/create/${this.props.userId}`} >Create Service Project for buyer {this.state.currentUsername}</a></div>
                 }
                <Aria message={this.state.alert} /> 
                <div>
                    {/* <label for="multi" class="btn">Choose File</label>
                    <input type='file' id='multi' name="userImage[]" onChange={this.upload} multiple data-buttonText={"Upload File"} /> */}
                    {/* <progress  value={this.state.uploaded} max={this.state.max}></progress> */}
                </div>
                <div>

                <div>
                    { !showingReport
                        ? <div>
                            <Report id={this.props.userId} />
                        </div>
                        : null
                    }
                </div> 

                {/* <button onClick={this.createJob}>Apply to service</button> */}

                {this.state.create == true && 
                    <div>
                        <h2 tabIndex="0">Create service</h2>
                        
                            <label for="projectName">Project Name</label> 
                            <input id="projectName" type="text" value={this.state.projectName} onChange={this.handleInput}></input>
                       
                            <label for="projectDescription">Project Description</label> 
                            <input id="projectDescription" type="text" value={this.state.projectDescription} onChange={this.handleInput}></input>
                       
                            <label for="price">Price</label>
                            <input id="price" type="text" value={this.state.price} onChange={this.handleInput}></input>
                      
                            <label for="keywords">Keywords</label>
                            <input id="keywords" type="text" value={this.state.keywords} onChange={this.handleInput}></input>
                        
                          
                        {/* <button onClick={this.createServiceProject} >CREATE</button> */}
                        <Aria message={this.state.alert2} />
                        
                    </div>
               
            
                    
                }
                </div>
            </div>
            

        );
        
    }

}
                  
           

export default Chat;