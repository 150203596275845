import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Service from '../components/Service';



class AdminUsers extends Component {


    constructor(props) {
        super(props);
        this.state = {
            users: [],
             
            
        }

    }
    componentDidMount(){
        this.updateUsers();


    }

    updateUsers=()=>{
        Service.get("/admin/getUsers",{}, (res) => {

            console.log(res);
            this.setState({users: res.data.users});
            
        }); 
    }





    render() {
        return (
          <div className="content admin-content" >
            <div>
            <h1>Admin Users</h1>
            </div>

            <table id="admin-users">
                <tr>
                    <th>Id</th>
                    <th>Name / Username</th>
                    <th>Role</th>
                    <th>Country</th>
                    <th>Role status</th>
                    <th>Email</th>
                    {/* <th>Cause of disability</th> */}
                    <th>Link</th>
                </tr>
                {this.state.users.map(function (item, index) {
                    return (
                        <tr key={index}>
                                <td>
                                    {item.id}
                                </td> 
                                <td>
                                    {item.name} {item.last_name} / {item.user_name}
                                </td> 
                                <td> 
                                    {item.role}
                                </td> 
                                <td> 
                                    {item.country}
                                </td> 
                                <td> 
                                    {item.contributor_status == 0 &&
                                        <p>Pending</p>
                                    }
                                    {item.contributor_status == 1 &&
                                        <p>Approved</p>
                                    }
                                    {item.contributor_status == 9 &&
                                        <p>Rejected</p>
                                    }
                                </td> 
                                <td>
                                    {item.email}
                                </td>
                                {/* <td>
                                    {item.reason}
                                </td> */}
                                <td>
                                <Link to ={"/admin/user/"+item.id}>Go to</Link>
                                </td>
                           
                               
                        </tr>
                    )
                }, this)
                }
            </table> 
            </div>
        );
      }
    }
    

export default AdminUsers;