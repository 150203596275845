import React, { Component } from 'react';
import Service from './Service';
import Icons from './Icons';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import { isTerminatorless } from '@babel/types';
import StarRatings from 'react-star-ratings';
import ConversionStatment from './ConversionStatment';

class AudioServicesBuyer extends Component {

    constructor(){
        super();
        this.state={
            services: [],
            showList: true,
            currentCategory:""

        }
        this.mainHeading = React.createRef();

    }

    
    componentDidMount(){

        this.getServices();
        this.mainHeading.current.focus();
        this.getCategoryName();
        
    }

    getCategoryName=()=>{
        Service.get("/categories/getCategory",{id: this.props.categoryId}, (res) =>{
        this.setState({currentCategory: res.data.category.name})
        });
    }

    getServices=()=>{
        Service.get("/services/get",{categoryId: this.props.categoryId}, (res) => {
            this.setState({ services: res.data.services })
            console.log(res)
        });

    }

    rsdFormat=(number)=>{
        let a = number.replace(',','.');
        let fa= a.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return fa;
    }

    eurFormat=(number)=>{
        let ea = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return ea;
    }
    
    render(){
        return(
            <div className="content">
                <h1 ref={this.mainHeading} tabIndex="0" className="title-left"><span className="audio-service-light">Audio Services / </span>{this.state.currentCategory}</h1>
                
                    <div className="product-freelancer">
                        {this.state.services.map(function (item, index) {
                            return (
                                <div className="service-box" key={index}>
                                    {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
                                    {/* <div className="icon"> {Icons.icon("notes")}  </div> */}
                                    <div className="service-buyer-title">
                                        <h2>
                                            {item.username}
                                        </h2>
                                        <p>EUR {this.eurFormat(item.price)}<span style={{fontWeight: "100", color:"#C2C2C2"}}>/{this.rsdFormat(item.price_rsd)} RSD</span> per minute</p>
                                    </div>
                                    <div className="service-buyer-content">
                                    <p classname="service-buyer-desc">
                                        {item.description}
                                    </p>
                                        <StarRatings
                                            //rating={this.state.rating}
                                            //rating={item.rating}
                                            // rating={item.rating}
                                            starDimension="1.3em"
                                            starSpacing=".1em"
                                            starRatedColor="#000000"
                                            // changeRating={this.changeRating}
                                            numberOfStars={5}
                                            // name={item.userId.toString()}
                                        />
                                    </div>
                                    <Link classname="service-box-apply" to={"/user/messages/"+item.user_id}>Apply to service</Link>
                                    {/* <Link to={"/audio-service-chat/"+item.categoryId+"/"+item.id}>Apply to service</Link> */}
                                </div>    
                            )
                        }, this)
                        }
                    </div>

                        
                    {this.state.services.length !== 0 &&
                        <ConversionStatment/>
                    }
            </div>
        );
    }
}

export default AudioServicesBuyer;