import React, { Component } from 'react';
import Service from '../components/Service';
import { Auth } from '../components/Auth';
import Aria from '../components/Aria';
import ConversionStatment from '../components/ConversionStatment';
class Status extends Component {
constructor(props) {
    super(props);
    this.state = {
        api:'',
        amount:'',
        currency:'',
        companyAddress:'',
        companyName:'',
        companyPib:'',
        orderId:'',
        orderList:[],
        orderTotal:'',
        pgOrderId:'',
        pgTranApprCode:'',
        pgTranDate:'',
        pgTranId:'',
        pgTranRefId:'',
        responseCode:'',
        responseMsg:'',
        userAddress:'',
        userEmail:'',
        userFirstName:'',
        userLastname:'',
        userName:'',
        vat:'',
        errorCode:'',
        alert:'',
        projectsId:[]
    }
}

componentDidMount(){
    this.getOrderStatus();
}

getOrderStatus=()=>{
    Service.get("/orders/getOrderStatus",{hash: this.props.match.params.hash}, (res) => {
        if(res.data.transactionStatus === 'AP'){
            this.setState({responseMsg: 'APPROVED'})
        }
        this.setState({
            api: res.data.api,
            amount: res.data.amount,
            currency: res.data.currency,
            companyAddress: res.data.companyAddress,
            companyName: res.data.companyName,
            companyPib: res.data.companyPib,
            orderId: res.data.order.id,
            orderList: res.data.order.projects,
            orderTotal: res.data.order.total,
            pgOrderId: res.data.pgOrderId,
            pgTranApprCode: res.data.pgTranApprCode,
            pgTranDate: res.data.pgTranDate,
            pgTranId: res.data.pgTranId,
            pgTranRefId: res.data.pgTranRefId,
            responseCode: res.data.pgTranReturnCode,
            // responseMsg: res.data.transactionStatus,
            userAddress: res.data.userAddress,
            userEmail: res.data.user.email,
            userFirstName: res.data.user.name,
            userLastname: res.data.user.last_name,
            userName: res.data.user.user_name,
            vat: res.data.vat
        },()=>{
            this.setOrderProjectsId();
            if(res.data.pgTranReturnCode !== "00"){
                this.setState({errorCode: res.data.errorCode})
            }
        })
    }); 
}

agreeTo = (event) => {
    this.setState({ agree: event.target.checked }, () => {
        console.log("without enter",this.state.agree);
    });

};

setOrderProjectsId=()=>{
    let projects=[];
    this.state.orderList.map(function (item, index){
        projects.push(item.id);
    })    

    this.setState({projectId: projects},()=>{
        console.log("PROJECTS ID", this.state.projectId)
    })
}

createOrder=()=>{
    if(!this.state.agree) {
        this.setState({alert: "You have to accept the Selling Rules and Terms of Use."});
        return;
    }
    if(Service.sandbox == false){
        this.setState({alert: "Payment is currently unavailable due technical problems. Please try again later. Sorry for the inconvenience."});
    }
    if(Service.sandbox == true){
        Service.get('/orders/create',
        {projects: this.state.projectId},
        (res)=>{
            window.location.href= res.data.url;
        })
    }
}

rsdFormat=(number)=>{
    let rsdValue = number.toString();
    let a = rsdValue.replace(',','.');
    let fa= a.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return fa;
}

eurFormat=(number)=>{
    let ea = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return ea;
}

    render() {
        return (
            <div className="content">

                {this.state.responseCode === "00" &&
                    <div className="transaction-response success">
                        <h1>Transaction successful!</h1>
                        <p>Action completed successfully.</p>
                    </div> 
                }
                {this.state.responseCode !== "00" && 
                    <div className="transaction-response error">
                        <h1>Transaction failed!</h1>
                        {/* <p>Action failed because of a general type error. A general type error can either appear during the
                        processing of the action, or an error appeared in the payment gateway system, which is not yet
                        mapped in our database.</p>
                        <p>Please, check credit card information you entered and try again.</p> */}
                        <p>
                            Payment failed, your account has not been charged. The most common reason is incorrectly entered payment card number, 
                            expiration date or security code. Please try again, and in case of repeated errors, contact your Bank.
                        </p>
                    </div> 
                }

                <div className="transaction-details-container">
                    <div className="payment-details">
                        <h2>Payment Details</h2>
                        <p>Order ID: #{this.state.orderId}</p>
                        {this.state.responseCode === "00" &&
                            <div>
                                <p>Transaction Approval Code: {this.state.pgTranApprCode}</p>
                                <p>Transaction status: {this.state.responseMsg}</p>
                                <p>Transaction status Code: {this.state.responseCode}</p>
                            </div>     
                        }
                        {/* {this.state.responseCode !== "00" &&
                            <p>Transaction Error Code: {this.state.errorCode}</p>
                        } */}
                        <p>Transaction ID: #{this.state.pgTranId}</p>
                        {/* <p>Datum transakcije: {this.state.pgTranDate}</p> */}
                        <p>Amount: {this.rsdFormat(this.state.amount)}{this.state.currency}</p>
                        <p>Payment referent ID: #{this.state.pgTranRefId}</p>
                    </div>
                    
                    <div className="customer-details">
                        <h2>Customer</h2>
                        {/* <p>Name: {this.state.userFirstName}</p>
                        <p>Last Name: {this.state.userLastname}</p> */}
                        <p>Username: {this.state.userName}</p>
                        <p>Email: {this.state.userEmail}</p>
                        {this.state.address &&
                            <p>Address: {this.state.userAddress}</p>
                        }
                        
                    </div>
                    <div className="merchant-details">
                        <h2>Merchant</h2>
                        <p>Company name: {this.state.companyName}</p>
                        <p>Tax Identification Number: {this.state.companyPib}</p>
                        <p>Company Address: {this.state.companyAddress}</p>
                    </div>
                </div>
                
                <div className="order-container">
                    <div className="order-top">
                        <h2>Order #{this.state.orderId}</h2>
                    </div>
                    <div className="div-table-header order-table">
                        <div className="div-h-table-order-name">
                            Name
                        </div>
                        <div className="div-h-table-order-from">
                            Freelancer
                        </div>
                        <div className="div-h-table-order-desc">
                            Description
                        </div>
                        <div className="div-h-table-order-price">
                            Price
                        </div>
                        {this.state.responseCode === "00" &&
                            <div className="div-h-table-order-button">
                                Download
                            </div>
                        }
                    </div>
                
                    {this.state.orderList.map(function (item, index) {
                                return ( 
                                    <div key={index}>
                                        <div className="div-table order-table"> 
                                            <div className="div-h-table-order-name">
                                                {item.name}
                                            </div> 
                                            <div className="div-h-table-order-from">
                                                {item.userName}
                                            </div> 
                                            <div className="div-h-table-order-desc"> 
                                                {item.description}  
                                            </div> 
                                            <div className="div-h-table-order-price"> 
                                                EUR {this.eurFormat(item.price)}
                                            </div> 
                                            {this.state.responseCode === "00" &&
                                                <div className="div-h-table-order-button"> 
                                                    <form method="post" action={this.state.api+"/api/users/download"}>
                                                        <input name="orderId" value={this.state.orderId} hidden />
                                                        <input name="fileId" value={item.file_id} hidden /> 
                                                        <input name="Auth" value={JSON.stringify(Auth)} hidden /> 
                                                        <button type="submit">DOWNLOAD</button>
                                                    </form>
                                                    {/* <button onClick={this.downloadFile} data-file-name={item.id}>DOWNLOAD</button> */}
                                                </div>
                                            }
                                        </div>
                                        <hr></hr>
                                    </div>  
                                )
                        }, this)
                    }
                    
                    <h2 className="order-total">Total: EUR {this.eurFormat(this.state.orderTotal)}</h2>
                </div>
                {this.state.responseCode !== "00" &&
                    <div className="order-error-button">
                        <input type="checkbox" name="agree" id="terms-checkbox" value={this.state.agree} onChange={this.agreeTo} aria-label="Selling rules accept"/>
                        <label style={{display: 'inline'}} htmlFor="terms-checkbox"> 
                            I agree with <a style={{display: 'inline'}} className="terms-link" href="/selling-rules" target="_blank">Selling Rules</a>, <a style={{display: 'inline'}} className="terms-link" href="/terms-of-use" target="_blank">Terms Of Use</a> and <a style={{display: 'inline'}} className="terms-link" href="https://ec.europa.eu/info/cookies_en" target="_blank">Privacy policy</a>. 
                        </label>                        <button className="defaultButton" onClick={this.createOrder}>TRY AGAIN</button>
                        <Aria message={this.state.alert} />
                    </div>
                }

                <ConversionStatment/>
            </div>
            
        );
    }

}

export default Status;