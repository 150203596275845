import React, { Component } from "react";


class Supported extends Component {





    render() {
        return (
            <div>
               
                <div className="logo-image">
                    <a href="https://kcmv.udruzenje.org/projects.html" target="_blank"><img src="/images/footer-logo-kcmv.gif" alt="Association Youth Cultural Center of Vojvodina" ></img></a>
                    <a href="https://eacea.ec.europa.eu/homepage_en" target="_blank"><img src="/images/footer-logo-erasmus.gif" alt="Co-funded by the Erasmus+ Programme of the EU"></img></a>
                </div>
            </div>
            
        );
    }

}

export default Supported;