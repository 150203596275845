import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Redirect, Route, Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';    
import CookieConsent from "react-cookie-consent";

import duix from 'duix';

import Home from "./views/Home";
import About from "./views/About";
import AudioServices from "./views/AudioServices";
import AudioServicesCategories from "./views/AudioServicesCategories";
import AudioStock from "./views/AudioStock";
import FAQ from "./views/FAQ";
import TehnicalSupport from "./views/TehicalSupport";
import EducationalSupport from "./views/EducationalSupport";
import Contact from "./views/Contact";
import SignIn from "./views/SignIn";
import SignUp from "./views/SignUp";
import Users from "./views/Users";
import EditProfile from "./views/EditProfile";
import AuthorTerms from "./views/AuthorTerms";
import EditAccount from "./views/EditAccount";
import FileMenager from "./views/FileMenager";
import Messages from "./views/Messages";
import Rate from "./views/Rate";
import RegistrationSuccess from "./views/RegistrationSuccess";
import Dashboard from "./views/Dashboard";
import Portfolio from "./views/Portfolio";
import TermsOfUse from "./views/TermsOfUse";
import GeneralSiteRules from "./views/GeneralSiteRules";
import SellingRules from "./views/SellingRules";
import Upload from "./views/Upload";
import Profile from "./views/Profile";
import CreateProject from "./views/CreateProject";
import CreateService from "./views/CreateService";
import Projects from "./views/Projects";
import AccountActivation from "./views/AccountActivation";
import UploadFile from "./components/UploadFile";
import admin1 from "./views/Admin1";
import UserProjects from "./views/UserProjects";
import Project from "./views/Project";
import AudioService from "./views/AudioService";
import AudioServiceChat from "./views/AudioServiceChat";
import UserServicesCategories from "./views/UserServicesCategories";
import UserServicesFreelancer from "./components/UserServicesFreelancer";
import UserService from "./views/UserService";
import UserServiceChat from "./views/UserServiceChat";
import UserChats from "./views/UserChats";
import EditBankAccount from "./views/EditBankAccount"
import PurchaseHistory from "./views/PurchaseHistory"
import OrderPage from "./views/OrderPage"
import RoleSelector from "./views/RoleSelector"
import Fail from "./views/Fail"
import Success from "./views/Success"
import Status from "./views/Status"

import DashboardMenu from './components/DashboardMenu'
import Aria from './components/Aria';

import Admin from './components/Admin';
import AdminChat from './components/AdminChat';
import AdminChats from './components/AdminChats';
import AdminUser from './components/AdminUser';
import AdminUsers from './components/AdminUsers';
import AdminProject from './components/AdminProject';
import AdminProjects from './components/AdminProjects';
import CartView from './views/CartView';
import {Auth} from './components/Auth';
import Header from "./components/Header";
import Footer from "./components/Footer";
import FooterBrending from "./components/FooterBrending";
import FooterHome from "./components/FooterHome";
import FooterCart from "./components/FooterCart";
import Support from "./views/Support";
import Service from "./components/Service";
import Soon from "./views/Soon";

var currentTime = Math.round((new Date()).getTime() / 1000);
console.log(currentTime);

const checkLoginStatus = () => {
  console.log('ls', localStorage);
if (localStorage.getItem('token') && localStorage.getItem('userId') && localStorage.getItem('sy_ted') && parseInt(localStorage.getItem('sy_ted')) > currentTime ) {
  Auth.isAuthenticated = true;
  Auth.token = localStorage.getItem('token');
  Auth.userId = localStorage.getItem('userId');
  Auth.name = localStorage.getItem('name');
  Auth.lastName = localStorage.getItem('lastName');
  Auth.role = localStorage.getItem('role');
  duix.set('registration', 1);
  console.log(Auth);
}else {
    Auth.signout();
    Auth.isAuthenticated = false;
}
    console.log('auth', Auth);
}

{checkLoginStatus()}

const PrivateRoute = ({ component: Component, ...rest }) => (
<Route {...rest} render={(props) => (
  Auth.isAuthenticated === true
    ? <Component {...props} />
    : <Redirect to={{
        pathname: '/role-selector',
        state: { from: props.location }
      }} />
)} />
)



const ChangeFooter = (props) => {
  const { location } = props;
  console.log('loc', location.pathname.slice(0, 5), location.pathname.slice(0, 6).match("/admin"));

  if (location.pathname.match(/\/$/)){
    return <FooterHome />;
  }else if(location.pathname.match("/cart")){
    return <FooterCart />;
  }else if(location.pathname.slice(0, 6).match("/admin")){
    return null;
  }else if(location.pathname.slice(0, 9).match("/projects") || location.pathname.slice(0, 13).match("/order-status") || 
          location.pathname.slice(0, 25).match("/user/services-categories") || location.pathname.slice(0, 11).match("/order-page") ||
          location.pathname.slice(0, 15).match("/audio-services")){
    return <FooterBrending />;
  }else{
    return <Footer />;
  }
}

// checkLoginStatus();



class App extends Component {
  constructor(props){
      super(props);
      this.header = React.createRef();
  }

  componentDidMount(){
    //   checkLoginStatus();
  }

  


  render() {
    const FooterToShow = withRouter(ChangeFooter);
    return (
      
      <div className="wrapper">
        <Router>
          <Header />
          
          <Route exact path="/" component={Home} />
          <PrivateRoute exact path="/purchase-history" component={PurchaseHistory} />
          <Route exact path="/order-page/:orderId" component={OrderPage} />
          {/* {Service.sandbox == true &&
             <fragment> */}
                <Route exact path="/audio-services-categories" component={AudioServicesCategories} />
                <PrivateRoute exact path="/user/services-categories/:categoryId?" component={UserServicesCategories} />
                <PrivateRoute exact path="/user/services/:categoryId?" component={UserServicesFreelancer} />
                <PrivateRoute exact path="/user-service/:categoryId?" component={UserService} />
                <PrivateRoute exact path="/user-service-chat/:categoryId/:userId" component={UserServiceChat} />
            {/* </fragment>
            }
            {Service.sandbox == false &&
                <fragment>
                    <Route exact path="/audio-services-categories" component={Soon} />
                    <PrivateRoute exact path="/user/services-categories/:categoryId?" component={Soon} />
                    <PrivateRoute exact path="/user/services/:categoryId?" component={Soon} />
                    <PrivateRoute exact path="/user-service/:categoryId?" component={Soon} />
                    <PrivateRoute exact path="/user-service-chat/:categoryId/:userId" component={Soon} />
                </fragment>
            } */}
          <Route exact path="/audio-services/:categoryId?" component={AudioServices} />
          <Route exact path="/audio-service/:id?" component={AudioService} />
          <PrivateRoute exact path="/audio-service-chat/:categoryId/:userId" component={AudioServiceChat} />
          <Route exact path="/audio-stock/:this?" component={AudioStock} />
          <Route exact path="/role-selector" component={RoleSelector} />
          <Route exact path="/faq" component={FAQ} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/sign-in" component={SignIn} />
          <Route path="/sign-up/:type?/:role?" component={SignUp} />
          <Route path="/author-terms" component={AuthorTerms} />
          <Route path="/edit-account" component={EditAccount} />
          <Route path="/edit-bank-account" component={EditBankAccount} />
          <Route path="/file-menager" component={FileMenager} />
          <PrivateRoute path="/user/messages/:userId?/:buyerId?/:serviceId?" component={Messages} />
          <Route path="/rate" component={Rate} />
          <Route path="/registration-success" component={RegistrationSuccess} />
          <PrivateRoute path="/user/dashboard/:role?" component={Dashboard} />
          <Route path="/portfolio/:userId?" component={Portfolio} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/general-site-rules" component={GeneralSiteRules} />
          <Route path="/selling-rules" component={SellingRules} />
          <Route path="/upload" component={Upload} />
          <Route path="/users" component={Users} />
          <Route exact path="/user/profil/:type?" component={EditProfile} />
          <PrivateRoute exact path="/user/projects/create/:buyerId?" component={CreateProject} />
          <Route exact path="/user/create-service/:categoryId" component={CreateService} />
          <PrivateRoute exact path="/user/projects" component={UserProjects} />
          
          <PrivateRoute exact path="/user/chats/:categoryId" component={UserChats} />
          <Route path="/support" component={Support} />
          <Route path="/accountactivation/:code?" component={AccountActivation} />
          <Route path="/upload-test" component={UploadFile} />
          {/* <Route path="/admin1/:component?/:id?/:id2?" component={Admin1}/> */}
          <Route path="/projects/:category?/:page?" component={Projects}/>
          <Route path="/project/:id?" component={Project}/>
          <PrivateRoute path="/cart" component={CartView}/>
          <Route path="/fail" component={Fail}/>
          <Route path="/success" component={Success}/>
          <Route path="/order-status/:hash?" component={Status}/>
          <PrivateRoute path="/admin" component={Admin}/>
          <Route path="/chat" component={AdminChat}/>
          <PrivateRoute path="/admin/chats" component={AdminChats}/>
          <PrivateRoute path="/admin/user/:userId" component={AdminUser}/>
          <PrivateRoute path="/admin/users" component={AdminUsers}/>
          <PrivateRoute path="/admin/project/:projectId" component={AdminProject}/>
          <PrivateRoute path="/admin/projects" component={AdminProjects}/>
          
          
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="soundsyCookie"
            style={{ background: "#000000", fontSize: '1em',  boxShadow: '0px -5px 10px rgba(0, 0, 0, .3)' }}
            buttonStyle={{ color: "#333", background:"white",fontSize: "16px", fontWeight: 'bold' }}
            expires={150}
          >
            This website uses cookies to ensure you get the best experience on our website. 
            To find out more, read our <a href="/terms-of-use" style={{ textDecoration: 'underline', color:"rgb(194, 194, 194)"}} >Terms of Use</a>. 
          </CookieConsent>

          <FooterToShow />
        </Router>
      </div>
    );
  }
}

export default App;