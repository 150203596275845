import React, { Component } from 'react';



class Soon extends Component {





    render() {
        return (
            <div className="content">
                

                <h1>Coming soon...</h1>
            </div>
            
        );
    }

}

export default Soon;