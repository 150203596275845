import React, { Component } from "react";
import Supported from "../components/Supported";

function resizeContent(){
        let w = window.innerWidth;
        let h = window.innerHeight;
        if(document.getElementById("footer") && document.getElementById("content")){
            let fh = document.getElementById("footer").offsetHeight ;
            let ch = document.getElementsByClassName("content")[0].offsetHeight;
            // console.log(w,h,fh,ch)
            if(ch < h-fh){
            document.getElementsByClassName("content")[0].style.minHeight = (h-fh)+"px";

            }
        }
    }

window.onresize = function(){resizeContent()};


class FooterBrending extends Component {

    interval;

    componentDidMount(){
        resizeContent();
    }

    render() {
        return (
        <div id="footer">    
            <div className="footer-logos">
                    <div>
                        <img src="/images/visa.png" alt="Visa" />
                        <img src="/images/mastercard1.png" alt="MasterCard" />
                        <img src="/images/maestro.png" alt="Maestro" />
                    </div>
                    <div>
                        <a href="http://www.e-services.rs/" target="_blank" rel="noopener noreferrer">
                        <img id="chip-card-logo" src="/images/chipCard-1.png" alt="Chip Card" />
                        </a>
                    </div>
                    <div>
                        <a href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html" target="_blank" rel="noopener noreferrer">
                            <img src="/images/verifiedByVisa.png" alt="Verified by Visa" />
                        </a>
                        <a href="http://www.mastercard.com/rs/consumer/credit-cards.html" target="_blank" rel="noopener noreferrer">
                            <img src="/images/masterCard.png" alt="MasterCard Secure Code" />
                        </a>
                    </div>
            </div>

            <Supported />
            
            <div className="footer-copyright">
                <div>
                    <p>development: <a href="http://brainit.rs/" target="_blank">brainit.rs</a></p>
                    <p>©️ Copyright 2019 Soundsy - All Rights Reserved</p>
                    <p><a href="https://www.borisradivojkov.com/" target="_blank">design by: Boris Radivojkov</a></p>
                </div>
            </div>
        </div>
        );
    }
}

export default FooterBrending;
