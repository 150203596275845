import React, { Component } from 'react';



class Profile extends Component {
  
    
   

    render() {
        return (
            <div className="content">
                
            </div>
        );
    }

}

export default Profile;