import React, { Component } from 'react';
import Aria from '../components/Aria';

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            reportDescription:'',
            alert: ''
        }
    }

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    reportProject = () => { 
        if(!this.state.reportDescription){
            this.setState({alert: "Please enter  Report Message."});
            return;
        }else{
            console.log("report description",this.state.reportDescription)
            console.log("id", this.props.id)
            this.setState({alert: "You successfully send report message."});
        }
        // Service.get("/users/createproject",{ 
        //         projectName: this.state.projectName,
        //     },
            
        //     (res) => {
        //         if(res.success){
        //             this.setState({alert: "You successfully created a new project."});
        //         }else{
        //             this.setState({alert: res.info})
        //         }
        // });
    }

    render(){
        return(
            <div className="report-container">
                <h3>Report message</h3> 
                <textarea id="reportDescription" className="report-textarea" value={this.state.reportDescription} onChange={this.handleInput} />

                <button className="report" onClick={this.reportProject}>Submit</button>
                <Aria message={this.state.alert} />
            </div>
        )
    }
}

export default Report;


                      
      