import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
// import Icons from './Icons';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Chats from '../components/Chats'
import Aria from '../components/Aria';
import Report from '../components/Report';
class AudioServiceChat extends Component {

    constructor(){
        super();
        this.state={
            messages: [],
            chats: [],
            service:[],
            projectName: '',
            projectDescription: '',
            price:'',
            alert:'',
            currentUsername:''
        }
        this.mainHeading = React.createRef();

    }

    interval;
    
    componentDidMount(){
        setTimeout(()=>{
        this.getChat()
        // this.getService();
        }, 1000)
        this.interval=setInterval(()=>{
            this.getChat()
           
            // this.getService();
            }, 400)
            this.mainHeading.current.focus();

        setTimeout(()=>{
            console.log(this.props.userId);
            this.getChats();
        },1000);
        
    }
    componentWillUnmount(){
        clearInterval(this.interval)
    }

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    scrollBarBottom=()=>{
        var messageBody = document.querySelector('#chat-right-content');
        messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }


    getChat=()=>{
        Service.get("/users/getMessages",{toId: this.props.match.params.userId, fromId: Auth.userId}, (res) => {
            this.setState({messages: res.data.messages},()=> {
                this.scrollBarBottom();
                this.updateCurrentUsername();
            })
        });

    }

    // getChat=()=>{
    //     Service.get("/services/getMessages",{categoryId: this.props.match.params.categoryId, userId:this.props.match.params.userId}, (res) => {
    //         this.setState({ messages: res.data.messages })
    //         console.log(res)
    //     });

    // }

    getChats=()=>{
        Service.get("/users/getChats",{userId: Auth.userId}, (res) => {
           console.log(res);
           this.setState({chats: res.data.chats}, ()=>{
                this.updateCurrentUsername();
            });
       }); 
    }

    getService=()=>{
        Service.get("/services/getSingle",{serviceId: this.props.serviceId}, (res) => {
            this.setState({ service: res.data.service })
            console.log(res)
        });

    }

    handleTextInput=(event)=>{
        this.setState({messageToSend: event.target.value});
    }

    sendMessage=()=>{
        Service.get("/services/sendMessage",{text: this.state.messageToSend, userId: this.props.match.params.userId, categoryId: this.props.match.params.categoryId},()=>{
            this.getChat();
            this.setState({messageToSend: ''});
        });
    }

    upload = (event) => {
        const files = Array.from(event.target.files)
        this.setState({ uploading: true })
    
        const formData = new FormData()
    
        files.forEach((file, i) => {
            formData.append(i, file)
        })

        formData.append('fromId', Auth.userId);     //Auth.userId || 567.
        formData.append('toId', this.props.match.params.userId);
        formData.append('categoryId', this.props.match.params.categoryId);;
    
        console.log(formData);
    
        Service.uploadAudioMessages(formData,(res)=>{
            console.log(res);
            if(res.success){
                this.setState({alert: 'File successfuly uploaded.'})

            }else {
                this.setState({alert: 'Error uploading file.'})
            }
            // }
            this.getChat();
        },(res) => {
            console.log("res",res)
            if(res.success){
                this.setState({alert: 'File successfuly uploaded.'})

            }else {
                this.setState({alert: 'Error uploading file.'})
            }
            // this.setState({uploading: false});
            // if(res.success){
            //     this.setState({FormData: res.FormData});    //res.data.fileId ???
                
            // }
            this.getChat();
        })
    
    }

    createProject = () => { 
        console.log(this.state);
        if( !this.state.projectName && !this.state.projectDescription  ) { 
            alert("missing data");
            return;
        } else {
            Service.get("/users/createproject",{ 
                    projectName: this.state.projectName,
                    projectDescription: this.state.projectDescription,
                    price: this.state.price,
                    buyerId:this.props.match.params.userId,
                    length:"n/a",
                    tempo:"n/a"

                }, (res) => {
                console.log(res)
            });
        }
    }

    //  Send message on enter press
    defaultButton = (e) => {
        if(e.which === 13) {
        this.sendMessage();
        }
    }

    updateCurrentUsername=()=>{
        Service.get("/users/getUsername",{userId: this.props.match.params.userId}, (res) => {
            this.setState({ currentUsername: res.data.user_name })
            console.log("res", res)
        });
    }
    
    render(){
        const { showingReport } = this.state;
        let categoryId = this.props.match.params.categoryId;
        let userId = this.props.match.params.userId;
        return(
            <div className="content">
                <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Chat</h1>
                {/* <h2>{this.state.service.title}</h2>
                <p>{this.state.service.description}</p>
                <p>{this.state.service.price}</p>
                <p>{this.state.service.id}</p> */}
                <div>
                <div className="chat-container chat-audio-service"> 
                    <div className="chat-left">
                        <div className="chat-title-left">
                            <h2>Inbox</h2>
                        </div>
                        <div className="chat-left-content">
                            {this.state.chats.map(function (item, index) {
                                return (
                                    <div class="chat-left-box" id={userId === item.correspondent.id ? 'current-chat' : ''}>
                                        <Link to  ={"/audio-service-chat/"+categoryId+"/"+item.correspondent.id}> 
                                            <h3> {item.correspondent.username}</h3>
                                            <p>{item.lastMessage[0].text}</p>         
                                        </Link>    
                                    </div>     
                                );     
                            }
                            )}
                        </div>
                    </div>

                    <div className="chat-right">
                            <div className="chat-title-right">
                                <h2>{this.state.currentUsername}</h2>
                                {this.state.currentUsername  &&
                                    <div className="report-button">
                                        <button className="report" onClick={() => this.setState({ showingReport: !showingReport })}>Report user</button>
                                    </div>
                                }
                            </div>
                            <div className="chat-right-content" id="chat-right-content">
                            {this.state.messages.map(function (item, index) {
                                return (

                                    
                                <div> 
                                    {Auth.userId != item.to_id &&
                                        <div className={"chat-message my-message"}  id="my-message">
                                      
                                      
                                            {/* <h3>{item.from_id}</h3> */}
                                          
                                          
                                            {item.type == 'file' && 
                                                <audio controls src={Service.media + '/audio/' + item.text } controlsList="nodownload"></audio> 

                                            }

                                            {item.type == 'text' && 
                                                <p>{item.text}</p> 

                                            }

                                            {item.type == 'project' && 
                                                <a href={"/project/"+item.project_id} title={"View service "+item.text} >View service </a>

                                            }
                                        
                                        
                                           

                                            <h6>{item.created_at}</h6>
                            
                            
                                        </div>
                                    }

                            {Auth.userId != item.from_id  &&
                                        <div className={"chat-message "}>
                                      
                                      
                                            {/* <h3>{item.from_id}</h3> */}
                                          
                                          
                                            {item.type == 'file' && 
                                                <audio controls src={Service.media + '/audio/' + item.text } controlsList="nodownload"></audio> 

                                            }

                                            {item.type == 'text' && 
                                                <p>{item.text}</p> 

                                            }

                                            {item.type == 'project' && 
                                                <a href={"/project/"+item.project_id} title={"View service "+item.text} >View service </a>

                                            }
                                        
                                        
                                           

                                            <h6>{item.created_at}</h6>
                            
                            
                                        </div>
                                    }




                                </div>
                                        
                                    
                                )
                                }, this)
                            }

                            </div>
                        </div>

                        <div className="chat-send-field">
                            <div className="chat-send-input">
                                <input id='message-to-send' onChange={this.handleTextInput} onKeyPress={this.defaultButton} value={this.state.messageToSend} placeholder="Type a message..." />
                                <button  onClick={this.sendMessage}><i className="fa fa-paper-plane fa-lg" onKeyPress={this.defaultButton} aria-hidden="true"></i></button>
                            </div>
                            <div className="chat-send-file">
                                <label for="multi" class="btn" id="send-file-button">+</label>
                                <input type='file' id='multi' name="userImage[]" onChange={this.upload} multiple/>
                                {/* <Aria message={this.state.alert} /> */}
                              
                            </div>
                        </div>
                </div>
            </div>
            <div>
                    { showingReport
                        ? <div>
                            <Report id={this.props.match.params.userId}/>
                        </div>
                        : null
                    }
                </div> 

            </div>
        );
    }
}

export default AudioServiceChat;