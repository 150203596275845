import duix from 'duix';

var api = "http://api.us.cekaonica.com/api";
var media = "http://api.us.cekaonica.com";

// dev
if(window.location.origin === 'http://localhost:3000' || window.location.origin === 'http://us.cekaonica.com'){
    api = 'http://api.us.cekaonica.com/api';
    media = "http://api.us.cekaonica.com";
}
if(window.location.origin === 'http://soundsy.net' || window.location.origin === 'https://soundsy.net' || window.location.origin === 'http://www.soundsy.net' || window.location.origin === 'https://www.soundsy.net'){
    api = 'https://api.sy.cekaonica.com/api';
    media = "https://api.sy.cekaonica.com";
}

export const Auth = {
  isAuthenticated: false,
  userId: false,
  name: false,
  lastName: false,
  token: false,
  role: false,
  ted: false,
  api: api,
  //api: Service.api,
  authenticate(password, cb, cb2) {
      fetch(`${this.api}/users/login`,
           {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
                 email: this.email,
                password: password
             })
            })
         .then(res => res.json())
      .then(
        (result) => {
            if(result.success){
                this.isAuthenticated = true;
                this.userId = result.data.id;
                this.name = result.data.name;
                this.lastName = result.data.lastName;
                this.token = result.data.token;
                this.role = result.data.role;
                this.ted = result.data.ted;
                localStorage.setItem('token', result.data.token);
                localStorage.setItem('userId', result.data.id);
                localStorage.setItem('name', result.data.name);
                localStorage.setItem('lastName', result.data.lastName);
                localStorage.setItem('role', result.data.role);
                localStorage.setItem('sy_ted', result.data.ted);
                // setTimeout(cb, 100);
                cb(result)
                console.log('login', result);
                
            }else{
                console.log('Login error',result);
                this.isAuthenticated = false;
                cb2(result.info);
                //return result.info;
            }
        },
        (error) => {
            console.log('error');
        }
      )
  },

  changeRole(role, cb){
      this.role = role;
      localStorage.setItem('role', role);
      cb();
      duix.set('changeRole', role);
      window.location = '/user/dashboard';
  },

  logout(cb=null) {
      fetch(`${this.api}/users/logout`,
           {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
               Auth: this,
                 userId: this.userId,
                token: this.token
             })
            })
         .then(res => res.json())
      .then(
        (result) => {
            this.isAuthenticated = false;
            this.userId = false;
            this.name = false;
            this.lastName= false;
            this.token = false;
            this.role = false;
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('name');
            localStorage.removeItem('lastName');
            localStorage.removeItem('role');
            if(cb){
                cb(result);
            }
        },
        (error) => {
            console.log('error');
        }
      )
  },
  signout(cb) {
    this.logout();
    this.isAuthenticated = false
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('name');
    localStorage.removeItem('lastName');
    localStorage.removeItem('role');
    localStorage.removeItem('sy_ted');
    localStorage.clear();
    console.log(this);
    //window.location.href = '/';
    setTimeout(cb, 100)
  }
 }