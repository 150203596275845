import React, { Component } from 'react';
import Service from '../components/Service';
import Icons from '../components/Icons';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import {Auth} from '../components/Auth';
import duix from 'duix';
import AudioServicesFreelancer from "../components/AudioServicesBuyer";
import AudioServicesBuyer from "../components/AudioServicesFreelancer"
import AudioServicesContributor from "../components/AudioServicesContributor"


class AudioServicesCategories extends Component {

    constructor(){
        super();
        this.state={
            categories:[]
        }
        this.mainHeading = React.createRef();

    }
    unsubscribe = [];
    
        componentDidMount(){
        this.getCategories();
    }

    getCategories=()=>{
        Service.get("/categories/get",{categoryType: 3}, (res) => {
            this.setState({ categories: res.data.categories })
            console.log(res)
        });
        this.mainHeading.current.focus()

    }

    
    // componentWilUnmount(){
    //     this.unsubscribe[0]();
    // }

    
    render(){
        
        return(

            <div className="audio-services-wrapper">
                <img className="bg-b-r audio-service-categories" src="/images/background-audio-services.png" />
                <div className="content">
                    <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Audio Services</h1>
                    <p  tabIndex="0" className="title-left">Please select a audio service category.</p>
                        <div className="product-freelancer services-categories-product">
                            {this.state.categories.map(function (item, index) {
                                return (
                                    <div className="product-view services-categories-view" key={index}>
                                        {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
                                        <div className="icon"> <img src={"../images/"+item.id+"-services.jpg"} alt={item.title}/>  </div>
                                        <h2>{item.name}</h2>
                                        <p>{item.description}</p>
                                        
                                        <Link className="link-button" to={"/audio-services/"+item.id}>SEE MORE>></Link>
                                    </div>    
                                )
                            }, this)
                            }
                        </div>
                
                </div>
            </div>
        );
    }
}

export default AudioServicesCategories;