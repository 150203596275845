
import {Auth} from '../components/Auth';

var api = "http://api.us.cekaonica.com/api";
var media = "http://api.us.cekaonica.com";
var downloadFile = "http://api.us.cekaonica.com/production/";
var sandbox;

// dev
if(window.location.origin === 'http://localhost:3000' || window.location.origin === 'http://us.cekaonica.com'){
    api = 'http://api.us.cekaonica.com/api';
    media = "http://api.us.cekaonica.com";
    downloadFile = "http://api.us.cekaonica.com/production/";
    sandbox = true;
}
if(window.location.origin === 'http://soundsy.net' || window.location.origin === 'https://soundsy.net' || window.location.origin === 'http://www.soundsy.net' || window.location.origin === 'https://www.soundsy.net'){
    api = 'https://api.sy.cekaonica.com/api';
    media = "https://api.sy.cekaonica.com";
    downloadFile = "https://api.sy.cekaonica.com/production/";
    sandbox = false;
}

const Service = {

  api: api,
  media: media,
  downloadFile: downloadFile,
  sandbox: sandbox,


  get(url, params, cb=null){
    params.Auth = Auth;
    fetch(`${this.api}${url}`,
      {
        method: 'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: JSON.stringify(params)
       })
      .then(res => res.json())
      .then(
        (result) => {
            if(cb){
                cb(result);
            }
        },
        (error) => {
            console.log('error', error);
        }
    )
},

uploadNewsImages(formData, cb){
    fetch(`${this.api}/media/uploadAudio`,
      {
        method: 'post',
        body: formData
       })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
            cb(result);
            // this.setState({image: result.data});
            //this.getNews();
          
        },
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )
    },

    uploadAudioMessages(formData, cb){
      fetch(`${this.api}/media/sendAudio`,
        {
          method: 'post',
          body: formData
         })
        .then(res => res.json())
        .then(
          (result) => {
            // console.log(result);
              cb(result);
              // this.setState({image: result.data});
              //this.getNews();
            
          },
          (error) => {
            this.setState({
              // isLoaded: true,
              // error
            });
          }
        )
      },

  getUsers(params, cb = null) {

    fetch(`${this.api}/test/frontEndTest`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: JSON.stringify(params)
      })
      .then(res => res.json())
      .then(
        (result) => {
          if (cb) {
            cb(result);
          }
        },
        (error) => {
          console.log('error', error);
        }
      )
  },

  createUser(params, cb = null) {

    console.log(params);
    if (cb) {
      cb("test2");
    }
    fetch(`${this.api}/users/create`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: JSON.stringify(params)
      })
      .then(res => res.json())
      .then(
        (result) => {
          if (cb) {
            cb(result);
          }
        },
        (error) => {
          console.log('error', error);
        }
      )
  },

  Register(params, cb = null) {

    fetch(`${this.api}/users/logIn`,
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: JSON.stringify(params)
      })
      .then(res => res.json())
      .then(
        (result) => {
          if (cb) {
            cb(result);
          }
        },
        (error) => {
          console.log('error', error);
        }
      )
  }





}

export default Service;