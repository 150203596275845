import React, { Component } from 'react';
import Service from '../components/Service';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Auth from '../components/Auth';
import {Cart} from '../components/Cart';
import Aria from '../components/Aria';
import StarRatings from 'react-star-ratings';
import ConversionStatment from '../components/ConversionStatment';


class Projects extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            projects: [],
            currentCategory:'',
            totalProjects:'',
            totalPages:'',
            perPage:'',
            currentPage:1,
            filter:'',
            alert:''
        }
        this.mainHeading = React.createRef();

    }

    componentDidMount=() =>{
        this.mainHeading.current.focus();
        this.getCategoryName()
        if(this.props.match.params.page){
            this.setState({currentPage: this.props.match.params.page},()=>{
                this.updateProjects(); 
            })
        }else{
            this.updateProjects(); 
        }
    }

   

    updateProjects=()=>{
        Service.get("/projects/getAllProjects" , {page: this.state.currentPage, filter: this.state.search, categoryId: this.props.match.params.category} , (res) =>{
            this.setState({
                projects: res.data.projects, 
                totalProjects: res.data.total_projects,
                totalPages: res.data.total_pages,
                perPage: res.data.per_page
            });
        } )
    }

    searchProjects=(event)=> {
        this.setState({search: event.target.value},()=>{
            this.updateProjects();
            console.log("state search", this.state.search)
        });
    }

    getCategoryName=()=>{
        Service.get("/categories/getCategory",{id: this.props.match.params.category}, (res) =>{
        this.setState({currentCategory: res.data.category.name})
        });
    }

    addToCart=(project)=>{
        Cart.addToCart(project);
        this.setState({alert: project.name+" successfully added to cart"})
        Cart.getCart();
    }

    rsdFormat=(number)=>{
        let a = number.replace(',','.');
        let fa= a.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return fa;
    }

    eurFormat=(number)=>{
        let ea = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return ea;
    }

    render() {
        let nextPage = parseInt(this.state.currentPage) + 1;
        let nextPageLocation = "/projects/"+this.props.match.params.category+"/"+nextPage;
        return (
            <div className="content">

            <h1 ref={this.mainHeading} tabIndex="0" className="title-left">{"Audio stock - "+this.state.currentCategory}</h1>
               
                <label id="label-search" htmlFor="search">Search projects:</label> 
                <input id="search" type="text" value={this.state.search} onChange={this.searchProjects} placeholder="search here" ></input>
                
                
            <div className="project-list-container">
                {this.state.projects.map(function (item, index) {
                   return (
                      
                    <div key={index} className="project-stock">
                            <div className="stock-top-left">
                                <h2>{item.name}</h2>
                                <br className="break"></br>
                                {/* <p>{item.length} min.</p>
                                | */}
                                <p>
                                    {item.tempo != 0 && 
                                        item.tempo
                                    }
                                    {(item.tempo != 0 && (item.category_id) == 15) && 
                                        "bpm"
                                    }
                                    
                                </p>
                            </div>
                            <div className="stock-top-right">
                                <p>EUR{this.eurFormat(item.price)}<span style={{fontWeight: "100", color:"#C2C2C2"}}>/{this.rsdFormat(item.price_rsd)}RSD</span></p>
                                {/* <Link to ={"/project/"+item.id}>Add to cart</Link> */}
                                <button onClick={()=>{this.addToCart(item)}}>Add to cart</button>
                            </div>

                    <p className="project-username">{item.userName}</p>
                    
                    {/* <StarRatings
                        //rating={this.state.rating}
                        //rating={item.rating}
                        // rating={item.rating}
                        starDimension="1.3em"
                        starSpacing=".1em"
                        starRatedColor="#000000"
                        // changeRating={this.changeRating}
                        numberOfStars={5}
                        // name={item.userId.toString()}
                    /> */}
                    <p className="project-description">{item.description}</p>
                    <p className="project-keywords">{item.keywords}</p>
                    <audio controls src={Service.media + '/audio/' + item.fileName } controlsList="nodownload"></audio> 
                </div>
                        )
            }, this)
                }
            </div>
            <Aria message={this.state.alert} />
            <div id="pagination">
                {this.state.currentPage > 1 &&
                    <a className="pagination-button" href={'/projects/'+this.props.match.params.category+'/'+(this.state.currentPage-1)}>Previous</a>
                }
                <p className="pagination-pages">{this.state.currentPage+" / "+this.state.totalPages}</p>
                {this.state.currentPage < this.state.totalPages &&
                    <a className="pagination-button" href={nextPageLocation}>Next</a>
                }
            </div>
            
            <ConversionStatment/>
            </div>
                
            );
        }

    }

    export default Projects;


                      
      