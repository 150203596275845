import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import { BrowserRouter as Router, Route, Link ,Redirect} from "react-router-dom";
import Aria from '../components/Aria';


class RoleSelector extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            repeatedPassword: '',
            passwordError: '',
            emailError: '',
            role: '',
            name: '',
            lastName: '',
            alert:"",
            confirmPassword: ''
            
        }
        this.mainHeading = React.createRef();
     

    }

    componentDidMount(){
        document.title = 'Your page title here';
        this.mainHeading.current.focus();
        
    }
   
    SelectRedirect(){
        window.location="/sign-in";
        
    }
   
    
    state = {
        redirect: false
      }
      setRedirect = () => {
        this.setState({
          redirect: true
        })
      }
      renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/user/dashboard' />
        }
    }




    handleInput = (event) => {
        
        this.setState({ [event.target.id]: event.target.value });
         
      
    }

    


    submit = () => {
        const isValid = this.validate();
        if (isValid) {
           
        }
    
       
        Service.Register({ email: this.state.email, pass: this.state.password, role: this.state.role }, (result) => {

            console.log(result)
        });
       
      

       


        // console.log(this.state);
    }

    validate = () => {
        let passwordError = "";
        let emailError = "";
        
        

        if (!this.state.password) { 
            passwordError = "name cannot be blank";
        }

        if (!this.state.email.includes('@','.com')) {
            emailError = 'invalid email';
        }

    

        if (emailError || passwordError) {
            this.setState({ emailError, passwordError });
            return false;
        }

        
        return true;

    }

    selectRole = (event) => {

        this.setState({ role: event.target.value }, () => {
            console.log(this.state);
        });

    };

    logIn = () => {
        Auth.email = this.state.email;
        Auth.authenticate(this.state.password, (res) =>{
            if(res.success){
                // this.setRedirect()
                window.location="/"
                console.log('redirect')
            
            }

        },(res)=>{
            if(res == 'no-email-provided'){
                this.setState({alert: 'You have to enter the email.'})
                return;
            }
            if(res == 'no-password-provided'){
                this.setState({alert: 'You have to enter the password.'})
                return;
            }
            if(res == 'no-user'){
                this.setState({alert: 'User with this email is not registered. Please sign up.'})
                return;
            }
            if(res == 'no-confirmed-email'){
                this.setState({alert: 'This email is not confirmed. Please check your email and click the activation link to confirm your email.'})
                return;
            }
            if(res == 'wrong-password'){
                this.setState({alert: 'You have entered the wrong password'})
                return;
            }
            

            this.setState({alert: 'An error occurred, please try again later.'})
            return;
        
        });

    
    }




    render() {
        return (
            <div className="role-selector-wrapper">
                <img className="bg-b-r full-width" src="/images/background-role-selector.png" />
                <div className="content">
                    {/* {this.renderRedirect()} */}
                    <div className="role-selector">
                        <h1 className="sign" ref={this.mainHeading} tabIndex="0">ACCOUNT</h1>
                        <div className="sign-in">
                            <h2>SIGN IN</h2>
                            <label>Choose profile</label>
                            <ul>
                                <li>
                                <a href="/sign-in">Freelancer</a>
                                </li>
                                <li>
                                <a href="/sign-in">Buyer</a>
                                </li>
                            </ul>
        
                        </div>
                        <div className="sign-in sign-up">
                            <h2>SIGN UP</h2>
                            <label>Choose profile</label>
                            <ul>
                                <li>
                                <a href="/sign-up/2/freelancer">Freelancer</a>
                                </li>
                                <li>
                                <a href="/sign-up/1/buyer">Buyer</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default RoleSelector;