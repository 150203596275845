import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import DashboardMenu from '../components/DashboardMenu'



class Chats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            chats: []  
            
        }

    }

    componentDidMount() {
        setTimeout(()=>{
            console.log(this.props.userId);
            this.getChats();
        },1000);
    }
    
    
    getChats=()=>{
        Service.get("/users/getChats",{userId: Auth.userId}, (res) => {
           console.log(res);
           this.setState({chats: res.data.chats});
       }); 
   }

    

    sendMessage=()=>{
        Service.get("/users/getChats",{userId: Auth.userId}, (res) => {
            console.log(res);
            this.setState({chats: res.data.chats});
        }); 
    }
    
    render(){
        return(
            <div >
                <h1>Chats</h1>
                <DashboardMenu />
                {this.state.chats.map(function (item, index) {
                    return (
                      
                           <div  className="chats-message" key={index}>

                        <Link to  ={"/user/messages/"+item.correspondent.id}> 
                            <h2> {item.correspondent.name} {item.correspondent.lastName}</h2>
                            <h3>Unread messages: {item.unreadCount}</h3>
                            
                        </Link>
                           
                            <hr></hr>
                        
                        </div>
                        
                        
                         
                        
                    )
                    }, this)
                }
            </div> 
        );
    }

}
                  
           

export default Chats;