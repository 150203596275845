import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import {Auth} from '../components/Auth';


class DashboardMenu extends Component {





    render() {
        return (
            <div className="content">
                {/* Buyer */}
                {Auth.role == 1 && 
                    <div className="dashboard-menu dashboard-menu-buyer">
                        <ul>
                            {/* <li><Link to="#">Statistic</Link></li>   */}
                            <li><Link to="/purchase-history">File manager</Link></li>   
                            <li><Link to="/user/services-categories">Services</Link></li>  
                            <li><Link to="/edit-account">Edit Account/Portfolio</Link></li> 
                            <li><Link to="/user/messages/"> Messages</Link></li> 
                            <li><Link to="#">Rate</Link></li>  
                        </ul>
                    </div>
                }
                {/* Freelancer */}
                   {Auth.role == 2 && 
                   <div>
                       <div className="add-new-buttons">
                        <a href="/user/projects/create">Add new file</a>
                        <a href="/user/services-categories">Add new service</a>
                       </div>
                        <div className="dashboard-menu dashboard-menu-freelancer">
                            <ul>
                                {/* <li><Link to="#">Statistic</Link></li>   */}
                                <li><Link to="#">Balance</Link></li>
                                <li><Link to="/user/projects">File manager</Link></li>   
                                <li><Link to="/user/services-categories">Services</Link></li>  
                                <li><Link to="/edit-bank-account"> Edit Bank Account </Link></li>
                                <li><Link to="/edit-account">Edit Account/Portfolio</Link></li> 
                                <li><Link to="/user/messages/"> Messages</Link></li> 
                                <li><Link to="#">Rate</Link></li>  
                            </ul>
                        </div>
                    </div>
                }
            </div>
            
        );
    }

}

export default DashboardMenu;