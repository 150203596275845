import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import DashboardMenu from '../components/DashboardMenu'



class DashboardFreelancer extends Component {


   

    
    render(){
        return(
            <div>
                <h2 className="title-left">Freelancer</h2>
                <DashboardMenu />

                {/* <div>
                    <ul className='dashboard-list'> 
                        <li><Link to="#">Statistic</Link></li>  
                        <li><Link to="#">Balance</Link></li>
                        <li><Link to="/user/projects">File manager</Link></li>   
                        <li><Link to="/user/services-categories">Services</Link></li>  
                        <li><Link to="/edit-bank-account"> Edit Bank Account </Link></li>
                        <li><Link to="/edit-account">Edit Account/Portfolio</Link></li> 
                        <li><Link to="/user/messages/"> Messages</Link></li> 
                        <li><Link to="#">Rate</Link></li>   */}
                        {/* <li><Link to="/portfolio">Portfolio</Link></li>   */}
                    {/* </ul>  
                    
                </div> */}
                {/* <Link to="/user/projects"> Projects</Link> */}
          </div>
        );
    }

}

export default DashboardFreelancer;