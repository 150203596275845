import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import Aria from '../components/Aria';
import DashboardMenu from '../components/DashboardMenu';
import UploadFile from '../components/UploadFile';




class CreateProject extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            projectName: '',
            projectDescription: '',
            keywords: '',
            uploading: false,
            fileId: '',
            price: '',
            categories:[1,2,3],
            category:0,
            // length:'',
            tempo:'',
            alert: '',
            uploaded: 0,
            max: 0,
            show: false,
            buyer: '',
            buyerId: 0
            

        }
        this.mainHeading = React.createRef();

    }

    toggle = () => this.setState((currentState) => ({show: !currentState.show}));

    componentDidMount(){
       
      this.getStockCategories();
      this.mainHeading.current.focus();
      this.getBuyerId();
      this.getBuyerUsername();
    }          

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    reqListener = (res) => {
        let fileId = JSON.parse(res.target.response).fileId;
        console.log('fid',fileId);
        this.setState({fileId: fileId});
    } 

    upload = (event) => {

       
        // if(!this.state.projectName) {
        //     this.setState({alert: "Please enter your Project name."});
        //     return;
        // }
    
        // if(!this.state.projectDescription) {
        //     this.setState({alert: "Please enter  Description."});
        //     return;
        // }
    
        // if(!this.state.keywords) {
        //     this.setState({alert: "Please enter  Keywords."});
        //     return;
        // }
    
        // if(!this.state.price) {
        //     this.setState({alert: "Please enter Price."});
        //     return;
        // }
    
        // if(!this.state.length) {
        //     this.setState({alert: "Please enter  Lenght."});
        //     return;
        // }

        // if(!this.state.tempo) {
        //     this.setState({alert: "Please enter  Tempo."});
        //     return;
        // }




        const files = Array.from(event.target.files)
        this.setState({ uploading: true })
    
        const formData = new FormData()
    
        files.forEach((file, i) => {
            formData.append(i, file)
        })

        const updateProgress = (l, t) => {
            this.setState({uploaded: l, max: t})
        }

        formData.append('userId', Auth.userId);
    
        console.log(formData);

        var request = new XMLHttpRequest();
        request.open("POST", Service.api+'/media/uploadAudio');
        //request.send(formData);
        // console.log('lngth',formData.getLengthSync());
        // request.onprogress = function (res) {
        //     console.log(res);
        //     //if (res.lengthComputable) {
        //         console.log(res.loaded+  " / " + res.total)
        //     //}
        // }

        // function updateFileId(fileId){
        //     this.setState({fileId: fileId});

        // }
        // function reqListener(res){
        //     updateFileId(res.fileId);
        //     console.log(res);
        // }

        // request.addEventListener("load", ()=>{reqListener(this)});

        

        

        request.addEventListener("loadend", this.reqListener);
        
        request.upload.addEventListener("progress", function(evt){
        if (evt.lengthComputable) {
            //this.setState({uploaded: evt.loaded, max: evt.total})
            updateProgress(evt.loaded, evt.total);
            console.log("add upload event-listener" + evt.loaded + "/" + evt.total);
        }
        }, false);
        

        request.send(formData);
        // Service.uploadNewsImages(formData,(res)=>{
        //     console.log(res);
        //     this.setState({uploading: false});
        //     if(res.success){
        //         this.setState({fileId: res.fileId});    //res.data.fileId ???
        //     }
        // })
    



    }

    getBuyerId = () => {
        if(this.props.match.params.buyerId){
            this.setState({buyerId: parseInt(this.props.match.params.buyerId)})
        }
    }

    createProject = () => { 
            Service.get("/users/createproject",{ 
                    projectName: this.state.projectName,
                    projectDescription: this.state.projectDescription,
                    keywords: this.state.keywords,
                    fileId: this.state.fileId,
                    price: this.state.price,
                    categoryId:this.state.category,
                    buyerId: this.state.buyerId,
                    tempo:this.state.tempo


                },
                
                 (res) => {
                if(res.success){
                    this.setState({alert: "You successfully created a new project."});
                }else{
                    this.setState({alert: res.info})
                }
            });
    }

    getStockCategories=()=>{
        Service.get("/categories/getStockCategories",{},(res)=>{
            this.setState({
                 
              categories: res.data.categories,
              category: res.data.categories[0].id
                
    
            })
           



        })
            
        
    
    }

    changeCategory=(event)=>{
        this.setState({category: event.target.value},()=>{
            console.log(this.state)

        })

        
    }

    getBuyerUsername=()=>{
        Service.get("/users/getUsername",{userId: this.props.match.params.buyerId}, (res) => {
            this.setState({ buyer: res.data.user_name })
            console.log("res", res)
        });
    }

    
    setFileId=(fileId)=>{
        console.log('fileId', fileId);
        this.setState({fileId: fileId});
    }
        
    


    render() {
        return (
            <div className="content">
                <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Create Project</h1>
                <DashboardMenu />
                {this.props.match.params.buyerId > 0 &&
                    <p tabindex="0">Creating <b>Service Project</b> for user: {this.state.buyer}</p>
                }
                <div className="create-project-container">
                    
                    <div className="create-project-left">
                        <div>
                            <h3>Project Name</h3> 
                            <input id="projectName" className="create-project-input" type="text" value={this.state.projectName} onChange={this.handleInput}></input>
                        </div>
                        <div>
                            <h3>Description</h3> 
                            {/* <input id="projectDescription" className="create-project-input" type="text" value={this.state.projectDescription} onChange={this.handleInput}></input> */}
                            <textarea id="projectDescription" className="create-project-textarea" maxlength="200" value={this.state.projectDescription} onChange={this.handleInput} />
                        </div>
                        {this.state.buyerId == 0 &&
                            <div>
                                <h3>Keywords</h3> 
                                <input id="keywords" type="text" className="create-project-input" value={this.state.keywords} onChange={this.handleInput}></input>
                            </div>
                        }
                        {this.state.buyerId > 0 &&
                        <div>
                            <h3>Price</h3> 
                            <div className="currency-wrap">
                                <span className="currency-code">EUR</span>
                                <input id="price" className="create-project-input" type="text" value={this.state.price} onChange={this.handleInput}></input>
                            </div>
                        </div> 
                        }
                    </div> 
                <div className="create-project-right">
                    {/* Upload file */}
                    {this.state.buyerId == 0 &&
                    <fragment>
                    <UploadFile onUpload={this.setFileId}/>
                    <h1>Upload</h1>

                    <div>
                        <h3>Tempo</h3> 
                        <div className="currency-wrap">
                            {(this.state.category == 15) &&
                                <span className="currency-code">bpm</span>
                            }
                        <input id="tempo" className="create-project-input" type="text" value={this.state.tempo} onChange={this.handleInput}></input>
                        </div>
                    </div>  
                
                <div>
                    <h3>Please select a audio stock category</h3>
                    <select onChange={this.changeCategory} value={this.state.category} id="create-project-select">
                        {this.state.categories.map(function(item,index){
                            return(
                            <option value={item.id}>{item.name}</option>
                        
                            )})
                        }
                    
                        
                    </select>
                </div>
                </fragment>
                }
               
                <div>
                    <button onClick={this.createProject} className="create-project-button">CREATE</button>
              
                </div>
                </div>
                </div>
                <Aria message={this.state.alert} />
            </div>
        );
    }



}


export default CreateProject;