import React, { Component } from 'react';
import Supported from "../components/Supported";



class Contact extends Component {
       
  constructor(props){
        super(props);
        this.state={
        
        }
        this.mainHeading = React.createRef();

    }

  
    // componentDidMount(){    
    //     this.mainHeading.current.focus()
    // }





    render() {
        return (

            <div className="contact-wrapper">
                <img className="bg-t-r" src="/images/background-contact.png" />
            <div className="content">
                <h1 className="title-left">CONTACTS</h1>
                <div className="contact-support-container">
                    <div className="contact-left support">
                        <h3>TEHNICAL SUPPORT</h3>
                        <p>
                            For tehincal issues contact<br></br>
                            <i className="fa fa-envelope contact-icon" aria-hidden="true"></i> <a href="mailto:tehnicalsupport@soundsy.net" className="contact-email">tehnicalsupport@soundsy.net</a><br></br>
                            <i className="fa fa-clock contact-icon" aria-hidden="true"></i> Monday-Friday: 9am to 3pm<br></br>
                        </p>
                    </div>
                    <div className="contact-right support">
                        <h3>FINANCIAL SUPPORT</h3>
                        <p>
                            Financy and contracts<br></br>
                            <i className="fa fa-envelope contact-icon" aria-hidden="true"></i> <a href="mailto:financy@soundsy.net" className="contact-email">financy@soundsy.net</a><br></br>
                            <i className="fa fa-clock contact-icon" aria-hidden="true"></i> Monday-Friday: 9am to 3pm<br></br>

                        </p>
                    </div>
                    <div className="contact-support-center">
                        <h3>EDUCATIONAL SUPPORT</h3>
                        <p>
                            Educational support will be provided by organizations <br className="mobile-break"></br>
                            in each country for young people who wish to<br className="mobile-break"></br>
                            become contributors/freelancers.

                        </p>
                    </div>

                </div>


                <h3>PARTENER ORGANIZATIONS:</h3>
                <div className="about-partner-contact">
                    <div className="container-about-side about-side-contact">   
                        <div className="support">
                            <h3>MONTENEGRO</h3> 
                            <h3>The Union of the Blind of Montenegro</h3>
                            <p>   
                                +382 20 665 368<br></br>
                                savezslijepihcg@gmail.com<br></br>
                            </p>
                            <a href="http://www.ss-cg.org" target="_blank"><button>VIEW DETAILS</button></a>
                        </div>

                        <div className="support">
                            <h3>GREECE</h3>
                            <h3>Break The Borders</h3>
                            <p>
                                E-mail: somethuying@gmail.com<br></br>
                                +382 45 56 678 987<br></br>   
                            </p>
                            <a href="https://www.facebook.com/BreakTheBordersNGO/" target="_blank"><button>VIEW DETAILS</button></a>
                        </div>
                    </div>

                    <div className="container-about">
                        {/* ITALY */}
                        <div className="support">
                            <h3>ITALY</h3>
                            <h3>Young Men’s Christian Association Parthenope</h3>
                            <p>
                                +39 340 782 13 63<br></br>
                                luca2310@hotmail.com<br></br>  
                            </p>
                            <a href="http://www.ymcaparthenope.eu" target="_blank"><button>VIEW DETAILS</button></a>
                        </div>

                        <div className="support">
                            <h3>SERBIA</h3>
                            <h3>Association “Youth Cultural Center of Vojvodina”</h3>
                            <p>
                                +381 63 669 744<br></br>
                                kcmv.udruzenje@gmail.com<br></br>  
                            </p>
                            <a href="http://www.kcmv.udruzenje.org" target="_blank"><button>VIEW DETAILS</button></a>
                        </div>

                        <div className="support">
                            <h3>ALBANIA</h3>
                            <h3>European Integration and Development</h3>
                            <p>
                                +355 69 938 21 46<br></br>
                                sindihaxhija_93@hotmail.com<br></br>  
                            </p>
                            <a href="https://www.facebook.com/European-Integration-Development-857451267660613/?ref=aymt_homepage_panel" target="_blank"><button>VIEW DETAILS</button></a>
                        </div>
                    </div>

                    <div className="container-about-side about-side-contact">
                        <div className="support">
                            <h3>TURKEY</h3>
                            <h3>System and Generation</h3>
                            <p>
                                +90 533 425 52 99<br></br>    
                                systemandgenerationoffice@gmail.com<br></br>          
                            </p>
                            <a href="http://www.systemandgeneration.com" target="_blank"><button>VIEW DETAILS</button></a>
                        </div>

                        <div className="support">
                            <h3>BULGARIA</h3>
                            <h3>The Change is in You</h3>
                            <p>
                                + 359 898 55 23 42 <br></br>
                                stanimir.chukov@gmail.com<br></br>                         
                            </p>
                            <a href="http://www.CYA.Tryavna.eu" target="_blank"><button>VIEW DETAILS</button></a>
                        </div>
                    </div>
                </div>

                <div className="support sup-contact">
                <h3>CONTACT</h3>
                <i className="fa fa-envelope contact-icon" aria-hidden="true"></i> contact@soundsy.net 
                </div>
            </div>
            </div>

          





                 
                // <div className="map"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d71372.48032777864!2d-122.47318713269037!3d37.76078741001474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085874a311220bb%3A0x6a56ca6f837ff84e!2sUniversity+of+San+Francisco!5e0!3m2!1sen!2srs!4v1563989029257!5m2!1sen!2srs" ></iframe></div>
                // <div className="contact-form" >
                //     <h1 ref={this.mainHeading} tabIndex="0">Contacts</h1>
                //     <img src="/images/anv-logo.png" alt="logo" ></img>
                //     <h2>YCCV</h2>
                //     <h3>Ready for offers and cooperation</h3>
                //     <p>Email: Usesound.com</p>
                //     <p>Email: Usesound.com</p>
                //     <div className="contact-inputs">
                //         <div className="contact-inputs-left">
                //             <input  type="text" placeholder="Your name" />
                //         </div>
                //         <div className="contact-inputs-right" >
                //             <input type="text" placeholder="Phone" /><br />
                //         </div>
                //         <input type="text" placeholder="Email" /> <br />
                //         <input type="text" className="message-input" placeholder="Message" />
                //     </div>
                // </div> 
                //  <div>
                //     <button className="home-button">send message</button>
                // </div> 
                //  <Supported /> 





            

        );
    }

}

export default Contact;