import React, { Component } from 'react';
import Supported from "../components/Supported";



class EducationalSupport extends Component {


   

    
    render(){
        return(
            <div>
            <div className="traka">Educational support</div>

            <div className="content">
            <Supported />
            
            </div>


                
            </div>
            
        );
    }

}

export default EducationalSupport;