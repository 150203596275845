import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Service from '../components/Service';
import Icons from '../components/Icons';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import {Auth} from '../components/Auth';
import duix from 'duix';
import AudioServicesBuyer from "../components/AudioServicesBuyer";
import AudioServicesFreelancer from "../components/AudioServicesFreelancer"
import AudioServicesContributor from "../components/AudioServicesContributor"


function AudioServicesSelector (props) {
     return <AudioServicesBuyer categoryId={props.categoryId}/>;
    if (props.role == 1) {
      return <AudioServicesBuyer categoryId={props.categoryId}/>;
    }  
    if (props.role == 2) {
        return <AudioServicesFreelancer categoryId={props.categoryId}/>;
    }
    if (props.role == 3) {
        return <AudioServicesFreelancer categoryId={props.categoryId}/>;
    }
    // return <Redirect to='/role-selector' />
    return <h1>No role has been given!</h1>;
}

class AudioServices extends Component {

    constructor(){
        super();
        this.state={
            role:5,
            categories:[],
            
        }

    }
    unsubscribe = [];
    
    componentDidMount(){
        setTimeout(()=>{
            this.setState({role: Auth.role})
        }, 100);

        this.unsubscribe[0] = duix.subscribe('changeRole', () => {
            this.setState({role: Auth.role});
       });
    }

   

    // componentWilUnmount(){
    //     this.unsubscribe[0]();
    // }

    
    render(){
        return(
            <div className="audio-service-wrapper">
                <img className="bg-b-r full-width" src="/images/background-audio-service.png" />

                <div className="content">
                    {/* <h1 ref={this.mainHeading} tabIndex="0">Audio services. </h1> */}
                    
                    
                    
            

                    <div>
                    {/* <p>{Auth.role}{this.state.role} </p> */}
                    <AudioServicesSelector role={this.state.role} categoryId={this.props.match.params.categoryId} />

                    </div>
                
                </div>
            </div>
        );
    }
}

export default AudioServices;