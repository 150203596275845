import React, { Component } from 'react';
import Service from './Service';
import Icons from './Icons';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";

class AudioServiceContributor extends Component {

    constructor(){
        super();
        this.state={
            categories:[]
        }

    }
    
    componentDidMount(){
        this.UpdateComponents();

    }

    UpdateComponents=()=>{
        Service.get("/categories/get",{categoryType: 3}, (res) => {
            this.setState({ categories: res.data.categories })
            console.log(res)
        });

    }


    
    render(){
        return(
            <div className="content">
                
                <h1>Please switch role to buyer or freelancer to access this section.</h1>
            </div>
        );
    }
}

export default AudioServiceContributor;