import React, { Component } from 'react';
import Service from '../components/Service';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Auth from '../components/Auth';
import DashboardMenu from '../components/DashboardMenu';


class UserProjectsBuyer extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            projects: [],
            title:'',
            description:'',
            price:''
            
        }
        this.mainHeading = React.createRef();

    }
    

    componentDidMount=() =>{
        this.updateProjects();  
        // this.getAllProjects(); 
        this.mainHeading.current.focus();
        
    }

    handleInput = (event) => {

        this.setState({ [event.target.id]: event.target.value });
    }

    updateProjects=()=> {
        Service.get("/users/getBuyerProjects",{}, (res) => {
            console.log(res);
            if(res.success){
                this.setState({projects: res.data.projects});
            }
            
        });
    }

    // getAllProjects=()=>{
    //     Service.get("/projects/getAllProjects" , {} , (res) =>{
    //         this.setState({projects: res.data.projects});
    //     } )
    // }

    createJob = () => {
        
        Service.get('/services/create', {
            title: this.state.title,
            description: this.state.description,
            price: this.state.price,
       
        })
   
    }

    render() {
        return (
            <div>

                <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Projects</h1>
                <DashboardMenu />

                <div className="div-table-header">

                    <div className="div-h-table-filemenager-name">
                        Name
                    </div>
                    <div className="div-h-table-filemenager-file">
                        File
                    </div>
                    <div className="div-h-table-filemenager-desc">
                        Description
                    </div>
                    <div className="div-h-table-filemenager-lenght">
                        Lenght
                    </div>
                    <div className="div-h-table-filemenager-price">
                        Price
                    </div>
                    <div className="div-h-table-filemenager-link">
                        Enter  project
                    </div>
                
                </div>
            
            <div className='project-list-container'>
                
                {this.state.projects.map(function (item, index) {
                    return (
                        <div key={index}>
                            <div className="div-table"> 

                                <div className="div-h-table-filemenager-name">
                                    {item.name}
                                </div> 
                                <div className="div-h-table-filemenager-file">
                                <audio controls src={Service.media + '/audio/' + item.fileName } controlsList="nodownload"></audio> 
                                </div> 
                                <div className="div-h-table-filemenager-desc"> 
                                    {item.description}  
                                </div> 
                                <div className="div-h-table-filemenager-lenght"> 
                                    {item.length}
                                </div> 
                                <div className="div-h-table-filemenager-price"> 
                                    {item.price}€
                                </div> 
                                <div className="div-h-table-filemenager-link"> 
                                <Link  className="button-link"to ={"/project/"+item.id}>Enter project</Link>
                                </div>
                            </div>
                        </div>
                        
                    )
                }, this)
                }
            </div>
            {/* <div>

                <hr></hr>
            
            
                <label for="title">Title</label>
                    <input type="title" value={this.state.title} id='title' onChange={this.handleInput} />
                <label for="description">Description</label>
                    <input type="description" value={this.state.description} id='description' onChange={this.handleInput} />
                <label for="price">Price</label>
                    <input type="price" value={this.state.price} id='price' onChange={this.handleInput} />
            </div>
            <button onClick={this.createJob}>Create job</button> */}
            </div>
        );
    }
}

export default UserProjectsBuyer;