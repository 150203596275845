import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Service from '../components/Service';



class AdminUser extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            chats:[]
   
        }
    }

    componentDidMount(){
        this.getUser();
        // this.UpdateUserChats();  
    }

    changeStatus=(event)=>{
            console.log(event.target.value);
            Service.get('/admin/changeUserStatus',{status: event.target.value, userId: this.props.match.params.userId},(res)=>{
                console.log(res);
                if(res.success){
                    this.getUser();
                }
            })
        }


    getUser=()=>{
        Service.get("/admin/getUser",{userId: this.props.match.params.userId}, (res) => {

            console.log(res);
            this.setState({user: res.data.user});
            
        }); 
    }

    render() {
        return (
            <div className="content" >
            <div>
            <h1> User</h1>
            </div>
            <div>
            {this.state.user.map(function (item, index) {
                    return (
                        <div key={index}>
                            <div className="admin-project-div">
                                <h3>Name:</h3><p>{item.name} </p>
                                <h3>Lastname:</h3><p>{item.last_name}</p>
                                <h3>Username:</h3><p>{item.user_name}</p>
                                {/* <h3>Cause of disability:</h3><p>{item.reason} </p> */}
                                <h3>Id:</h3><p>{item.id}</p>
                                <h3>Approved</h3>
                                {item.contributor_status == 0 &&
                                    <p>Pending</p>
                                }
                                {item.contributor_status == 1 &&
                                    <p>Approved</p>
                                }
                                {item.contributor_status == 9 &&
                                    <p>Rejected</p>
                                }
                                
                                <h3>Change status</h3>
                                <select className="header-select" name="opcija" value={item.contributor_status} onChange={this.changeStatus}>
                                    <option value="0" >Pending</option>
                                    <option value="1" >Approve</option>
                                    <option value="9" >Reject</option>
                                </select>
                            </div>
                        </div>           
                    )
                }, this)
                }

            </div>
            <hr></hr>     
        </div>
        );
      }
    }


export default AdminUser;