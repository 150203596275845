import React, { Component } from 'react';



class AuthorTerms extends Component {


   

    
    render(){
        return(
            <div>

                <h1>Author Terms</h1>
            </div>
        );
    }

}

export default AuthorTerms;