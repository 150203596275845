import React, { Component } from 'react';
import DashboardBuyer from "../components/DashboardBuyer";
import DashboardContributor from "../components/DashboardContributor";
import DashboardFreelancer from "../components/DashboardFreelancer";
import {Auth} from '../components/Auth';
import Service from '../components/Service';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import duix from 'duix';


function DashboardSelector(props) {
     
    if (props.role == 1) {
      return <DashboardBuyer />;
    }  
    if (props.role == 2) {
        return <DashboardContributor />;
    }
    if (props.role == 3) {
        return <DashboardFreelancer />;
    }
    return <h1>No role has been given!</h1>;
}
    
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            role: 5,
            chats: [],
            
        }
        this.mainHeading = React.createRef();

    }

    unsubscribe = [];

    componentDidMount(){
        setTimeout(()=>{
            this.setState({role: Auth.role})
        }, 100);

        this.unsubscribe[0] = duix.subscribe('changeRole', () => {
            this.setState({role: Auth.role});
       });
       this.mainHeading.current.focus()

    }

    componentWilUnmount(){
        this.unsubscribe[0]();
    }

    
    render(){
        return(
            <div className="dashboard-wrapper">
                <img className="bg-b-r full-width" src="/images/background-dashboard.png" />
                <div className="content">
                    <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Dashboard</h1>
                
                
                    <div>
                        {/* <p>{Auth.role}{this.state.role} </p> */}
                    
                        <DashboardSelector role={this.state.role}/>

                    </div>
                </div>
            </div>
        );
    }

}




export default Dashboard;