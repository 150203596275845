import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';


const time = {currentTime: new Date().getTime()};
console.log("time", time.currentTime)
const ext = ['mp3', 'wav']
class UploadFile extends Component {


    constructor(props){
        super(props);
        this.state={
            // image:[]
            // uploading: false
            files:'',
            blob:'',
            start: 0,
            part: 1,
            chunk:'',
            flag: false,
            size:'',
            xhr:'',
            totalNumOfChunks: 0,
            fileId:'',
            BYTES_PER_CHUNK: 1048576, // 90MB chunk sizes = 94371840 : 1MB chunk size 1‬048576
            end: 0,
            fileName: ''
            

        }

    }

    reqListener = (res) => {
        let fileId = JSON.parse(res.target.response).fileId;
        console.log('fid',fileId);
        this.setState({fileId: fileId});
    } 

    setFile = (event) =>{
        this.setState({files: Array.from(event.target.files)},()=>{
            time.currentTime = new Date().getTime();
            this.uploadChunk();
            this.setState({uploadStatus: 'Processing file, please wait, it can take a while...'})
        })
        
    }
    uploadChunk = (event) => {
        console.log('res chunk', event);
        //let fileId = res.response.data.fileId
        // let size = files[0].size;
        // console.log("size", size)
       // console.log("kiiiiiiiiiiiiki", this.state.files[0]);
        //start
        //part
        //end
        //size
        //if(strat < size)
        //chunk = size/bytePerChunks
        //uploadFile
        //start+=bytesPerChunk
        //part++
        this.setState({
            size: this.state.files[0].size,
            totalNumOfChunks: Math.ceil(this.state.files[0].size/this.state.BYTES_PER_CHUNK),
            extension: this.state.files[0].name.split('.').pop(),
            fileName: time.currentTime + '-' + this.state.files[0].name.split('.')[0].replace(/\s/g, '')
            },()=>{

        //     let numOfChunks =  
        // console.log("num of chunks", numOfChunks)      

        // this.setState({totalNumOfChunks: numOfChunks},()=>{
        //     console.log("total num", this.state.totalNumOfChunks)
            
        // })

        
            if(this.state.start > this.state.size){
                console.log("done!")
                this.setState({uploadStatus: 'Upload finished, you can go ahead and create a project.'})
                //call parent method to set fileId
                //this.props.onUpload(55);
            }else{
                this.setState({chunk: this.state.files[0].slice(this.state.start, this.state.start + this.state.BYTES_PER_CHUNK)},()=>{
                    this.uploadFile(this.state.files, this.state.chunk, this.state.part, this.state.end);
                
                    this.setState({start: this.state.start += this.state.BYTES_PER_CHUNK},()=>{
                        console.log(this.state.part, this.state.totalNumOfChunks);
                        this.setState({part: this.state.part + 1},()=>{
                            if(parseInt(this.state.part) >= parseInt(this.state.totalNumOfChunks)){
                                this.setState({end: 1});
                                console.log('end vojno');
                                
                            }
                        
                        });
                    })
                })

            }
        })
        

    
    }

        // this.setState({extension: files[0].name.split('.').pop()},()=>{
        //     console.log("extension", this.state.extension);
        //     this.setState({size: files[0].size},()=>{
                
        //         if(ext.includes(this.state.extension)){
        //             if( this.state.start > this.state.size ) {
        //                 console.log('done');

        //                 //location.reload(true);
        //                 window.location.reload(true);
        //                 return;
        //             }
                    
        //             this.setState({start: this.state.start += this.state.BYTES_PER_CHUNK},()=>{
        //                 this.setState({chunk: files[0].slice(this.state.start, this.state.start + this.state.BYTES_PER_CHUNK)},()=>{
        //                     if(Math.ceil(this.state.size/this.state.BYTES_PER_CHUNK) > this.state.part){
        //                         this.setState({part: this.state.part + 1},()=>{
        //                             this.uploadFile(files, this.state.chunk, this.state.part, this.state.end);
        //                         });
        //                     }
        //                 });
        //             });
                  

                    
                    

                    

        //         }else{
        //             console.log('The file extension is not allowed!');
        //         }
        //     });
        // });
        
        

        // this.setState({ uploading: true })
    
        // const formData = new FormData()
    
        // files.forEach((file, i) => {
        //     formData.append(i, file)
        // })

        // const updateProgress = (l, t) => {
        //     this.setState({uploaded: l, max: t})
        // }

        // formData.append('userId', Auth.userId);
    
        // console.log(formData);

        // var request = new XMLHttpRequest();
        // request.open("POST", Service.api+'/media/uploadAudio');
 
        // request.addEventListener("loadend", this.reqListener);
        
        // request.upload.addEventListener("progress", function(evt){
        // if (evt.lengthComputable) {
        //     //this.setState({uploaded: evt.loaded, max: evt.total})
        //     updateProgress(evt.loaded, evt.total);
        //     console.log("add upload event-listener" + evt.loaded + "/" + evt.total);
        // }
        // }, false);
        
        // request.send(formData);
    // }

    uploadFile=(files,blobFile,part,end)=> {
        console.log("kristina")
        console.log("files", files)
        console.log("blob file", blobFile)
        console.log("part", part)
        console.log("end", end)

        var fd = new FormData();
        fd.append("fileToUpload", blobFile);

        console.log("blobFile", blobFile, "drugo", files)
	    //console.log(blobFile);
        
        var xhr = new XMLHttpRequest();
	    xhr.addEventListener("loadend", this.uploadChunk, false);

        const setFileid = (fid) => {
            console.log('set file id: ', fid);
            this.props.onUpload(fid);
            //this.props.onUpload(fid)
        }

        xhr.onload = function () {
        // Do something with the retrieved data ( found in xmlhttp.response )
            console.log('loaded   ...', xhr.response);
            let res = JSON.parse(xhr.response);
            setFileid(res.data.fileId);
        };

        xhr.upload.addEventListener("progress", function(evt){
        if (evt.lengthComputable) {
            //this.setState({uploaded: evt.loaded, max: evt.total})
            updateProgress(evt.loaded, evt.total);
            console.log("add upload event-listener" + evt.loaded + "/" + evt.total);
        }
        }, false);

	 //    xhr.onreadystatechange = function() {
		// 	if (xhr.readyState == XMLHttpRequest.DONE) {
		//         alert(xhr.responseText);
		//     }
		// }
        // let numOfChunks = Math.ceil(this.state.files[0].size/this.state.BYTES_PER_CHUNK); 
        // console.log("num of chunks", numOfChunks)      

        // this.setState({totalNumOfChunks: numOfChunks},()=>{
        //     console.log("total num", this.state.totalNumOfChunks)
            
        // })
       
        // if(Math.ceil(this.state.files[0].size/this.state.BYTES_PER_CHUNK) >= this.state.part){
        //         this.setState({end: "1"},()=>{
        //             console.log("K::I", this.state.end)
        //         });
        //         //console.log('end of file', Math.ceil(size/BYTES_PER_CHUNK));
        //     }
    
	    // files.forEach((file, i) => {
        //     fd.append(i, file)
        // })

        const updateProgress = (l, t) => {
            
            let u = (l / this.state.totalNumOfChunks * this.state.part);
            console.log(l, t, u);
            this.setState({uploaded: u, max: t})
        }

        fd.append('userId', Auth.userId);
        fd.append('file', this.state.fileName);
        fd.append('num', this.state.part);
        fd.append('totalChunks', this.state.totalNumOfChunks);
        fd.append('fileId', this.state.totalNumOfChunks);
        fd.append('extension', this.state.extension);
        fd.append('end', this.state.end);
        

        // var xhr = new XMLHttpRequest();
        xhr.open("POST", Service.api+'/media/uploadFile');
 
        
        xhr.send(fd);
   
	    // ext =  file.name.split('.').pop();
	    // if(file.name.includes('.')){
	    //     fileName = file.name.split('.');
	    // }else{
	    //     fileName = file.name.split(',');
	    // }
	    
	    // //ext = '.'+ext;
	    // xhr.open("POST", "/admin/upload"+"?"+"file="+fileName[0]+"&courseid="+<?php echo $courseId; ?>+"&num="+part+"&end="+end+"&totalChunks="+totalNumOfChunks+"&fileId="+fileId+"&extension="+ext+"&video="+video+"&image="+image+"&document="+doc);
	    // xhr.setRequestHeader('Cache-Control','no-cache');

	    // xhr.send(fd);
	    // return;
	}




    render() {
        return (
            <div>
            {/* <div className="content"> */}
                {/* <br /> */}
                {/* <h2>Select File</h2> */}
                
            <div>
                <h3>Upload File</h3>
                <input type='file' id='create-project-multi' name="userImage[]" onChange={this.setFile}  multiple data-buttonText={"Upload File"} />
                <progress aria-label='uploading'  value={this.state.uploaded} max={this.state.max}></progress>
                <p>{this.state.uploadStatus}</p>
            </div>
          {/* </div> */}

            </div>
        );
    }

}

export default UploadFile;