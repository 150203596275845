import React, { Component } from 'react';
import Service from '../components/Service';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Auth from '../components/Auth';
import {Cart} from '../components/Cart';
import Report from '../components/Report';


class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            project: [],
            showingReport: true
        }
    }

    componentDidMount=() =>{
        setTimeout(()=>{
            this.updateProjects();  
            this.getProject(); 
            // this.getService();
        }, 100)   
    }

    updateProjects=()=> {
        Service.get("/users/getallprojects",{}, (res) => {
            console.log(res);
            if(res.success){
                this.setState({projects: res.data.projects});
            }
        });
    }

    addToCart=(project)=>{
        Cart.addToCart(project);
        Cart.getCart();
        
    }

    getProject=()=>{
        Service.get("/projects/getProject" , {projectId:this.props.match.params.id} , (res) =>{
            this.setState({project: res.data.project});
        } )
    }

    render() {
        const { showingReport } = this.state;
        return (
            <div className="content"> 
                <div>
                    <h1> PRODUCT PAGE</h1>
                </div>
        
                {this.state.project.map(function (item, index) {
                    return(
                        <div className="project">
                            <div className="project-img" key={index}>
                                <img src="../images/voice-over.jpg" alt="slika"/>
                            </div>
                            
                            <div className="admin-project-div">
                                <h2>{item.name}</h2>
                                <p>Description:</p>
                                <p>{item.description}</p>
                                <p>Rating: {item.rating}</p>
                                <p>Price: {item.price} € </p>
                                <audio controls src={Service.media + '/audio/'+item.fileName} controlsList="nodownload"></audio>
                                <button className="addToCart" onClick={()=>{this.addToCart(item)}}>ADD TO CART</button>
                                <button className="report" onClick={() => this.setState({ showingReport: !showingReport })}>Report project</button>
                            </div>

                            <div>
                                { !showingReport
                                    ? <div>
                                        <Report id={item.id} type="project" />
                                    </div>
                                    : null
                                }
                            </div> 
                        </div>
                    )
                }, this)
                }
            </div>   
        );
    }
}

export default Project;