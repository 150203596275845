import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Aria from '../components/Aria';
import DashboardMenu from '../components/DashboardMenu';
import ConversionStatment from '../components/ConversionStatment';

class PurchaseHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            
    
        }
        this.mainHeading = React.createRef();
    }
    componentDidMount=()=>{
        // setTimeout(()=>{
        //     this.buyerHistory()
        //     // this.getService();
        //     }, 10)
        this.buyerHistory();
        this.mainHeading.current.focus();
        }
      
    


    buyerHistory=()=>{
        Service.get("/users/getorderhistory",{}, (res) => {
            console.log("buyer history", res);
            this.setState({orders: res.orders});
        }); 
    }

    rsdFormat=(number)=>{
        let a = number.replace(',','.');
        let fa= a.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return fa;
    }

    eurFormat=(number)=>{
        let ea = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return ea;
    }

    render() {
        return (
            <div className="content">
                <h1 ref={this.mainHeading} tabIndex="0" className="title-left">File Manager</h1>
                <DashboardMenu />

                <div className="div-table-header order-table">

                    <div className="div-h-table-history-name">
                        Order
                    </div>
                    <div className="div-h-table-history-time">
                        Status
                    </div>
                    <div className="div-h-table-history-price">
                        Total Price
                    </div>
                    <div className="div-h-table-history-owner">
                        Details
                    </div>
                </div>

                {this.state.orders.map(function (item, index) {
                     return (
                        <div className="div-table order-table" key={index}>
                            <div className="div-table-history-name">
                                Order #{item.id}
                            </div> 
                            <div className="div-table-history-time">
                                {item.response_code === "00" && 
                                    "Successful"
                                }
                                {item.response_code !== "00" && 
                                    "Failed"
                                }
                                
                            </div> 
                            <div className="div-table-history-price"> 
                                EUR {this.eurFormat(item.total)}<span style={{color:"#C2C2C2"}}>/{this.rsdFormat(item.amount)}RSD</span>
                            </div>   
                            <div className="div-table-history-owner"> 
                                <Link className="link-button" to={"/order-page/"+item.id}>Details #{item.id}</Link>
                            </div> 
                            <hr></hr>
                        </div>                    
                    )
                }, this)
                }

               <ConversionStatment/>
            </div> 
            
            
            
        );
    }

}

export default PurchaseHistory;