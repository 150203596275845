import React, { Component } from 'react';
import Service from './Service';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Auth from './Auth';
import DashboardMenu from '../components/DashboardMenu';
// import axios from 'axios'
import ConversionStatment from './ConversionStatment';

class UserProjectsContributor extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            projects: [],
           
            
        }
        this.mainHeading = React.createRef();

    }

    componentDidMount=() =>{
        this.updateProjects();  
        // this.getAllProjects(); 
        this.mainHeading.current.focus();
    }

    updateProjects=()=> {
        Service.get("/users/getallprojects",{}, (res) => {
            console.log(res);
            if(res.success){
                this.setState({projects: res.data.projects});
            }
            
        });
        
    }

    rsdFormat=(number)=>{
        let a = number.replace(',','.');
        let fa= a.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return fa;
    }

    eurFormat=(number)=>{
        let ea = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return ea;
    }

    // getAllProjects=()=>{
    //     Service.get("/projects/getAllProjects" , {} , (res) =>{
    //         this.setState({projects: res.data.projects});
    //     } )
    // }

    render() {
        return (
            <div>

                <h1 ref={this.mainHeading} tabIndex="0" className="title-left">My projects</h1>
                <DashboardMenu />
                <p tabIndex="0">
                    Browse or create projects
                </p>
                <Link to="/user/projects/create">Create New Project</Link><br />

                
                <div className="div-table-header">

                    <div className="div-h-table-filemenager-name">
                        Name
                    </div>
                    <div className="div-h-table-filemenager-file">
                        File
                    </div>
                    <div className="div-h-table-filemenager-desc">
                        Description
                    </div>
                    {/* <div className="div-h-table-filemenager-lenght">
                        Tempo
                    </div> */}
                    <div className="div-h-table-filemenager-price">
                        Price
                    </div>
                    <div className="div-h-table-filemenager-link">
                        Enter  project
                    </div>
                
                </div>

               

                
            
            <div className='project-list-container'>
                
            {this.state.projects.map(function (item, index) {
                   return (
                        <div key={index}>
                            <div className="div-table"> 

                                <div className="div-h-table-filemenager-name">
                                    {item.name}
                                </div> 
                                <div className="div-h-table-filemenager-file">
                                <audio controls src={Service.media + '/audio/' + item.fileName } controlsList="nodownload"></audio> 
                                </div> 
                                <div className="div-h-table-filemenager-desc"> 
                                    {item.description}  
                                </div> 
                                {/* <div className="div-h-table-filemenager-lenght"> 
                                    {item.tempo}{(item.category_id) == 15 && "bpm"}
                                </div>  */}
                                <div className="div-h-table-filemenager-price"> 
                                    EUR{this.eurFormat(item.price)}<span style={{color:"#C2C2C2"}}>/{this.rsdFormat(item.price_rsd)}RSD</span>
                                </div> 
                                <div className="div-h-table-filemenager-link"> 
                                <Link className="button-link">Delete</Link>
                                </div>
                            </div>
                        </div>
                   )
               }, this)
               }
               </div>
               <ConversionStatment/>
            </div>
            
        );
    }
}

export default UserProjectsContributor;