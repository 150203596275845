import React, { Component } from 'react';



class GeneralSiteRules extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
        };
        this.mainHeading = React.createRef();
    }

    componentDidMount() {
        this.mainHeading.current.focus();
    }

    render(){
        return(
            <div className="tof-wrapper">
                 <img className="bg-b-r full-width" src="/images/background-terms-of-use.png" />
                <div className="content">
                    <h1 ref={this.mainHeading} tabIndex="0" className="title-left">General Site Rules</h1>
                    <div className="tof-textbox">
                        {/* General site rules */}
                       <p>
                            By opening an account on our platform, you have agreed to abide by the rules listed below 
                            (the “Site Rules”), the Terms of Use, and that your account will comply with our Membership Requirements 
                            at all times. 
                       </p>
                       <p>
                            The Site Rules can and do change, so be sure to keep yourself up-to-date on these rules.
                       </p>
                       <p>
                           <i>
                                Remember: Unfamiliarity with the rules does not excuse anyone from the consequences of breaking them!
                           </i>
                        </p>
                        <p>
                            We expect everyone utilizing platform to adhere to the following rules and guidelines. 
                        </p>
                        <p>
                            The ultimate discretion in determining acceptable behavior and content besides with the administration teams.
                        </p>
                        {/* Dangerous Content */}
                        <h3>Dangerous Content</h3>
                        <p>
                            You shall not use to network for dangerous content or activities of any kind.
                        </p>
                        <p>
                            Do not link to pornographic websites.
                        </p>
                        <p>
                            This section applies to all areas of Soundsy.net platform as well as of site communications.
                        </p>
                        {/* Copyright Violations */}
                        <h3>Copyright Violations</h3>
                        <p>
                            As a website dedicated to working artists, we take intellectual property laws very seriously. 
                            Violations of copyright may result in removal of the profile or rejection of a membership application.
                        </p>
                        <p>
                            We do not want your inspirations – we want your work.
                        </p>
                        <p>
                            We consider violations of copyrights, trademarks and service marks, to include, (but is not limited to):
                        </p>
                        <ul>
                            <li>Uploading other people’s content and claiming it as your own</li>
                            <li>Uploading content, you do not have permission to utilize</li>
                            <li>Attempting to mislead others by using audio that appears to be from existing brands, advertisements, etc.</li>
                        </ul>
                        <p>
                            Content posted or shared should have a clear establishment of copyright. 
                            Admins of the Soundsy platform may ask for proof of copyright when investigating ownership rights.
                        </p>
                        <ul>
                            <li>If you are the creator, you should be able to provide proof of creation by providing an original file.</li>
                            <li>If you are using any plugins elements, you should be able to produce proof of permission or proper licensing to utilize that.</li>
                        </ul>

                        {/* Dishonest Account Ownership */}
                        <p><i>Dishonest Account Ownership</i></p>
                        <p>Because we are a networking site for real-life work between individual artists, we expect everyone who registers as a member to be who they say they are. </p>
                        <p>Only individuals are eligible Freelancer Member accounts. Buyers can be companies, organizations and private persons.</p>
                        <p>We do not tolerate dishonest account ownership or dishonest account maintenance.</p>
                        <p>The following behaviors are included in dishonest account ownership, but are not limited to:</p>
                        <ul>
                            <li>Creating an account for someone else</li>
                            <li>Allowing anyone but the person whose work is represented to login or access an account - this includes assistants, managers, or agents. Exceptions are spouses and/or life-partners as well as personal assistants which work as a team</li>
                            <li>Pretending to be someone else and/or maintaining a fake profile</li>
                            <li>We may request proof of identity if there are any questions regarding account ownership to ensure the proper account owner is in control of the account</li>
                        </ul>

                        {/* Advertising Competing Services */}
                        <p><i>Advertising Competing Services</i></p>
                        <p>
                            We know and understand that there are other audio portfolio and networking sites out there, but our platform is not the venue to promote, advertise, or recruit for those sites or other kinds of competing services. This behavior is prohibited 
                            anywhere on our website, such as through personal messages, posting work calls, tags, text on the profile, etc.
                        </p>
                        <p>This rule excludes the usage of links to profiles on other website, portfolio, or networking sites.</p>
                        <p>Examples:</p>
                        <ul>
                            <li>Prohibited behavior: A private message to someone that says: “Visit this link, this is the work that I had done last, it’s great!”</li>
                            <li>Permitted behavior: A blurb in the About Me section of the profile: “Here is a link to my profile.”</li>
                        </ul>

                        {/* 1. Prohibited Content and Conduct */}
                        <h3>1. Prohibited Content and Conduct</h3>
                        <p>The following content and conduct are strictly prohibited when using the audio/stock platform:</p>
                        <ul>
                            <li>using strong, vulgar, obscene, or otherwise harmful language;</li>
                            <li>using racially, ethnically, or otherwise objectionable language;</li>
                            <li>harassing, intimidating, stalking, or threatening other platform members;</li>
                            <li>using libelous, defamatory, or otherwise tortious language;</li>
                            <li>online vandalism;</li>
                            <li>impersonating a third party;</li>
                            <li>posting, distributing, transmitting, or promoting illegal content;</li>
                            <li>using fraudulent or misleading means to obtain money or personal information from other users;</li>
                            <li>posting fake casting calls or notices for any purpose, including but not limited to illegal purposes;</li>
                            <li>infringing a third-party’s rights, including but not limited to such third-party’s privacy and personal rights;</li>
                            <li>content featuring anyone younger than eighteen (18) years of age at the time of creation;</li>
                            <li>conduct that may harm anyone younger than eighteen (18) years of age;</li>
                            <li>using the Platform Services if you are a convicted sex offender;</li>
                            <li>posting, providing, transmitting, or otherwise making available any junk mail or spam;</li>
                            <li>
                                posting, uploading, providing, or otherwise transmitting any material that contains malicious computer code, or 
                                reverse engineering or hacking any materials associated with the UseSoun.net platform service.
                            </li>
                            <li>asking for the log-in information or accessing an account belonging to a third party.</li>
                        </ul>
                        {/* General Conduct */}
                        <h3>General Conduct</h3>
                        <ul>
                            <li>Keep it professional.</li>
                            <li>
                                Audio/stock platform is a lot of things, but we’re not a dating site, a place to offer or seek housing, or a network to offer management or 
                                to be managed. Do not use to network for intimate or personal interactions. Use our site appropriately.
                            </li>
                            <li>It is not a place to populate your website or network.</li>
                            <li>Be polite and respectful when communicating with others.</li>
                            <li>Do not use the platform to upload or share content that is considered otherwise harmful or objectionable.</li>
                            <li>Harassment of any kind is not acceptable.</li>
                            <li>Do not shame, degrade, insult, bully, or otherwise belittle other members.</li>
                            <li>“Outing” is the act of publicly shaming someone or calling out their actions. Do not out other people.</li>
                            <li>If you would like to share someone’s illegal or other inappropriate behavior, please inform platform admins by email: <a href="mailto:admin@soundsy.net">admin@soundsy.net</a></li>
                            <li>Private messages discussing the matter are permitted when requested.</li>
                            <li>Politely discuss the quality of the work, never the person.</li>
                            <li>Be safe!</li>
                            <li>Keep your login information private.</li>
                            <li>Administrators and moderators will never ask you for your password or credit card number.</li>
                            <li>Never enter your login information at any other site</li>
                            <li>Watch out for scams! Always log in from our domain: <a href="www.soundsy.net">www.soundsy.net</a>. Do not give your passwords to anyone. Do not open links that are sent by someone you do not know. </li>
                        </ul>
                        <p>Read more on how to recognize scams: <a href="https://en.wikipedia.org/wiki/Advance-fee_scam">https://en.wikipedia.org/wiki/Advance-fee_scam</a></p>
                        
                        {/* 2. Use of Sound.net Employees and Admins */}
                        <h3>2. Use of Sound.net Employees and Admins</h3>
                        <p>This platform is owned by the Association “Youth Cultural Centre of Vojvodina”.</p>
                        <p>
                            Our employees will only contact you using an @soundsy.net email address. Do not respond to people or emails claiming to be from our team that use any other 
                            domain, for example Gmail, Yahoo or Hotmail, or any social media accounts that are not our verified, legitimate accounts. 
                        </p>
                        <p>We will never contact you to offer a job and we will never request your Soundsy.net password.</p>
                        <p>Admins will only contact you via messages on the platform. Please flag any other messages claiming to be from admins of Soundsy.net</p>
                        <p>Admins only have the ability to approve profiles on Soundsy.net</p>
                        
                        {/* Profiles */}
                        <h3>Profiles</h3>
                        <p>Member profiles are intended for:</p>
                        <ul>
                            <li>Individual artists under our approved categories stock Contributor and Freelancer.</li>
                            <li>Companies under category – Buyers.</li>
                            <li>Artists whose business is a reflection of their individual artistry are permitted to utilize their account with their business name.</li>
                            <li>Spouses and/or life-partners who work as a team and identical twins with model profiles are permitted to share a profile.</li>
                            <li>Member profiles are not to be used on behalf of third parties, or on behalf of businesses where a member works outside the capacity for which their profiles were approved.</li>
                            <li>In order to maintain a member profile, you must meet our membership requirements at all times: at least 3 audio works pertaining to your membership type and a biography.</li>
                            <li>Profiles that do not meet the membership requirements can be suspended </li>
                            <li>You must be at least 18 years old and have the correct birthday listed.</li>
                            <li>Validation of age may be required.</li>
                        </ul>

                        {/* Spam */}
                        <h3>Spam</h3>
                        <p>
                            Do not spam. Spam is any messages, ads, or other information that is unsolicited and indiscriminately posted or shared across any location across the site. 
                            Spamming may result in removal of the profile or suspension of all posting privileges.
                        </p>

                        {/* Disputes */}
                        <h3>Disputes</h3>
                        <ul>
                            <li>If you have a bad working experience with someone, you are welcome to talk about the experience, but not discuss the person publicly.</li>
                            <li>Remember that you are liable for any posts and comments you make, so ensure you consider what you share carefully.</li>
                            <li>You may say that you do not recommend someone publicly, such as “I do not recommend Soundsy.net member John Doe #123456”</li>
                            <li>You may provide more details when asked.</li>
                            <li>These situations are best resolved privately and directly, however please report any illegal or fraudulent.</li>
                            <li>A member of the platform staff will review your report.</li>
                            <li>If a private solution cannot be reached, then you will need to explore your legal options.</li>
                        </ul>

                        {/* Member Account Requirements: */}
                        <h3>Member Account Requirements:</h3>
                        {/* Automatic Ejection Policy */}
                        <h3>Automatic Ejection Policy</h3>
                        <p>
                            We try to maintain a safe network for all of our members, which means there are several things that will result in immediate rejection 
                            of a profile here on Soundsy.net. Please note that these ejections can occur without notice or warning, and include, but are not limited to, the following:
                        </p>
                        <ul>
                            <li>networking for sexual, pornographic, or imminently dangerous content of any kind;</li>
                            <li>copyright violations;</li>
                            <li>opening or maintaining a profile that represents someone else;</li>
                            <li>opening or maintaining someone else’s profile.</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

}

export default GeneralSiteRules;