import React, { Component } from 'react';
import Service from './Service';
import Icons from './Icons';
import { BrowserRouter as Router, Route, Link, Read,Redirect } from "react-router-dom";

class UserServicesFreelancer extends Component {

    constructor(){
        super();
        this.state={
            service: [],
            create:false,
            title:"",
            price:"",
            description:"",
            chats:[],
            services:[]
            
           
        }

    }

    
    componentDidMount(){
        setTimeout(()=>{
            this.getService();
            this.getServices();
        }, 10);
        
    }




    getServices=()=>{
        Service.get("/services/get",{category: this.props.match.params.categoryId}, (res) => {
            this.setState({ services: res.data.services })
            console.log(res)
        });

    }


    

    handleInput=(event)=> {
        this.setState({[event.target.id]: event.target.value});
    }

    getService=()=>{
        Service.get("/services/getUserService",{categoryId: this.props.match.params.categoryId}, (res) => {
            if(res.data.jobs){
                this.setState({ title: res.data.jobs[0].title,  description: res.data.jobs[0].description, price: res.data.jobs[0].price}) 
            }
            //console.log(res)
        });

    }

    
    submitJob=()=>{
        Service.get("/services/create",{
            title: this.state.title,
            description: this.state.description,
            price: this.state.price,
            category: this.props.match.params.categoryId

        }, (res) => {
            if(res.success){
                
                window.location="/audio-service/"+res.data.serviceId
            }
        });

    }

    updateService=()=>{
        Service.get("/services/update",{
            title: this.state.title,
            description: this.state.description,
            price: this.state.price,
            category: this.props.match.params.categoryId

        }, (res) => {
            if(res.success){
                
                window.location="/audio-service/"+res.data.serviceId
            }
        });

    }

    

    
    getChats=()=>{
        console.log('sid', this.props.match.params.serviceId )
        // Service.get("/services/getOwnerChats",{serviceId: this.props.match.params.categoryId}, (res) => {
        //     this.setState({ chats: res.data.chats })
        //     console.log(res)
        // });

    }

    
    render(){
        return(
            <div className="content">
                
                {/* <h4 role='contentinfo' tabIndex="0">Create new job by clicking create new job button or browse your job offers on the list bellow.</h4> */}
              

               {/* /* <button onClick={this.createJob}>Apply to service</button> */} 

                {/* {this.state.create == true &&  */}
                    {/* <div tabIndex="-1"> */}
                        {/* <h4 role='alert'>Please enter the new job information.</h4> */}
                        {/* <label>Title</label> */}
                        {/* <input id="title" value={this.state.title} placeholder="Enter job title..." onChange={this.handleInput} /> */}
                        {/* <label>Description</label> */}
                        {/* <input id="description" value={this.state.description} placeholder="Enter job description..."  onChange={this.handleInput} /> */}
                        {/* <label>Price</label> */}
                        {/* <input id="price" value={this.state.price} placeholder="Enter job price..."  onChange={this.handleInput} /> */}
                        {/* {this.state.create == true &&  */}
                            {/* <button onClick={this.submitJob}>Submit</button> */}
                        {/* } */}
                        {/* {this.state.create == false &&  */}
                        {/* <button onClick={this.updateService}>Update</button> */}
                        }
                    {/* </div> */}



                <hr></hr>
      

                
                <div className="product-freelancer">

                {this.state.services.map(function (item, index) {
                            return (
                                <div className="admin-project-div" key={index}>
                                    {/* <img src="../images/voice-over.jpg" alt="slika"/> */}
                                    {/* <div className="icon"> {Icons.icon("notes")}  </div> */}
                                    <h4>{item.owner_id}</h4>
                                    <p role="contentinfo" tabIndex="0" aria-label={"Title, "+item.title} >{item.title}</p>
                                    <p role="contentinfo" tabIndex="0" aria-label={"Description, "+item.description}>{item.description}</p>
                                    <Link to={"/user-service-chat/"+item.service_id+"/"+item.owner_id} >View job</Link>
                                    <hr></hr>
                                </div>    
                            )
                        }, this)
                        }
                </div>
                
               
            </div>
        );
    }
}

export default UserServicesFreelancer;