import React, { Component } from 'react';
import {Cart} from '../components/Cart';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import Aria from '../components/Aria';
import ConversionStatment from '../components/ConversionStatment';
class CartView extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            cartItems: [],
            action:"",
            merchantUser:"",
            merchantPassword:"",
            merchant:"",
            customer:"",
            sessiontype:'',
            merchantPaymentId:"",
            amount:"",
            currentcy:"",
            customerEmail:"",
            customerName:"",
            customerPhone:"",
            returnUrl:"",
            sessionType:'',
            extra:"",
            total:'',
            totalRsd:0,
            alert:"",            
        }
        this.formRef = React.createRef();
        this.mainHeading = React.createRef();
    }

    componentDidMount(){
        setTimeout(()=>{
            this.updateCart();
            this.getCartTotal();
        },200);
        this.mainHeading.current.focus();
    }

    updateCart=()=>{
        console.log(Cart.getCartElements());
        this.setState({cartItems: Cart.getCartElements()},()=>{
            this.getCartTotal();
        })
        // Service.getCart({cartProducts: this.getCartProductsQuantities(this.state.cartItems)},(res)=>{
        //     this.setState({cartTotal: res.data.cartTotal, 
        //         productsTotal: res.data.productsTotal,
        //         shippingPrice: res.data.shippingPrice,
        //         //cartItems: result.data.cartProudcts
        //         },()=>{
        //             //this.props.updateCartDisplay();
        //             //duix.set('cartItems', 1);
        //         });
        // });
    }

    removeItem=(itemId)=>{
        console.log(itemId);
        Cart.removeFromCart(itemId);
        this.setState({alert: "Item removed successfully"},()=>{
            this.updateCart();
        })
        
    }

    getCartTotal=()=>{
        console.log("Cart elements" ,Cart.getCart());
        Service.get("/orders/getCartTotal",{projects: Cart.getCart()},(res)=>{
            if(res.success){
                let amountTotal = (Math.round(res.total * 100) / 100).toFixed(2);
                this.setState({total: amountTotal},()=>{
                    this.getRsdTotal();
                });
            }
        }
        )
    }

    getRsdTotal=()=>{
        Service.get("/orders/getrsdamount",{amount: this.state.total},(res)=>{
            if(res.success){
                this.setState({totalRsd: res.data.rsd},)
            }
        })
    }

    rsdFormat=(number)=>{
        let a = number.replace(',','.');
        let fa= a.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return fa;
    }

    eurFormat=(number)=>{
        let ea = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return ea;
    }

    agreeTo = (event) => {
        this.setState({ agree: event.target.checked }, () => {
            console.log("without enter",this.state.agree);
        });

    };

    // updateCart=()=>{

    //     let ce = Cart.getCartElements();
    //     this.setState({cartItems: ce},()=>{
            
    //     });
    
        
        
    // }



    createOrder=()=>{
        if(!this.state.agree) {
            this.setState({alert: "You have to accept the Selling Rules and Terms of Use."});
            return;
        }
        // if(Service.sandbox == false){
        //     this.setState({alert: "Payment is currently unavailable due technical problems. Please try again later. Sorry for the inconvenience."});
        // }
        // if(Service.sandbox == true){
            console.log(this.state.cartItems);
            Service.get('/orders/create',
            {projects: Cart.getCart()},
            (res)=>{
                window.location.href= res.data.url;
            })
        // }
    }


    

    render() {
        return (
            <div className="content">

                <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Cart</h1>

                {this.state.cartItems.map(function (item, index) {
                   return (
                        <div className="cart-container" key={index}>

                            <div className="cart-box">
                                <div className="cart-box-title">
                                    <h2>{item.project.name}</h2>
                                    <span>EUR {this.eurFormat(item.project.price)}</span>
                                </div>
                                <p>{item.project.description}</p>
                                <a href="#" onClick={()=>{this.removeItem(item.id)}}>Remove</a>
                            </div>
                        </div>
                        )
                    }, this)
                }

                {Cart.getCart().length > 0 &&
                    <div className="cart-create-order">
                    
                        <input type="checkbox" name="agree" id="terms-checkbox" value={this.state.agree} onChange={this.agreeTo} aria-label="Selling rules accept"/>
                        <label style={{display: 'inline'}} htmlFor="terms-checkbox"> 
                            I agree with <a style={{display: 'inline'}} className="terms-link" href="/selling-rules" target="_blank">Selling Rules</a>, <a style={{display: 'inline'}} className="terms-link" href="/terms-of-use" target="_blank">Terms Of Use</a> and <a style={{display: 'inline'}} className="terms-link" href="https://ec.europa.eu/info/cookies_en" target="_blank">Privacy policy</a>. 
                        </label>

                        <h2>Total: EUR {this.eurFormat(this.state.total)}</h2>
                        <p style={{textAlign: 'center'}}>{this.rsdFormat(this.state.totalRsd.toFixed(2))}RSD</p>

                        <button className="defaultButton" onClick={this.createOrder}>Create Order</button>
                        <Aria message={this.state.alert} />
                        
                        <ConversionStatment/>
                    </div>
                }

                {Cart.getCart().length < 1 &&
                    <div>
                        <p>Your cart is empty</p>
                    </div>
                }
               
              

            </div>
        );
    }

}

export default CartView;