import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Supported from "../components/Supported";

function resizeContent(){
        let w = window.innerWidth;
        let h = window.innerHeight;
        let fh = document.getElementById("footer").offsetHeight ;
        let ch = document.getElementsByClassName("content")[0].offsetHeight;
        console.log(w,h,fh,ch)
        if(ch < h-fh){
        document.getElementsByClassName("content")[0].style.minHeight = (h-fh)+"px";

        }
    }

window.onresize = function(){resizeContent()};


class Footer extends Component {

    interval;

    componentDidMount(){
        // resizeContent();
        setTimeout(()=>{
            resizeContent();
        },100);
        // this.interval = setInterval(()=>{resizeContent()}, 100)
    }

    

    

    render() {
        return (
        <div id="footer">
            {/* <div className="home-footer">
                <img className="bg-footer-right" src="/images/home-footer-background-graphic-position-right-bottom.gif" />
                <img className="bg-footer-left" src="/images/home-footer-background-graphic-position-left-bottom.gif" />
                <div className="footer-info">
                    <div className="footer-box footer-information">
                    <h3>INFORMATION</h3>
                    <ul>
                        <li>The brand</li>
                        <li>Store</li>
                        <li>Customer service</li>
                        <li>Privacy &amp; cookies</li>
                        <li>Site map</li>
                    </ul>
                    </div>

                    <div className="footer-box footer-buy">
                    <h3>WHY BUY FROM US</h3>
                    <ul>
                        <li>Best deal</li>
                        <li>Secure shopping</li>
                        <li>Polices</li>
                        <li>Ethical trading</li>
                    </ul>
                    </div>
                        
                    <div className="footer-box  footer-account">
                    <h3>YOUR ACCOUNT</h3>
                    <ul>
                        <li><Link to="/role-selector">Sign in</Link></li>
                        <li><Link to="/role-selector">Sign up</Link></li>
                        <li><Link to="/cart">View cart</Link></li>
                    </ul>
                    </div>
                </div>
            </div> */}
            

            {/* <div className="social-links">
            <a href="https://www.facebook.com/usesound"   ><i class="fab fa-facebook-f" title="facebook page"></i></a>
            <a href="https://twitter.com/usesound"   ><i class="fab fa-twitter" title="twitter page"></i></a>
            <a href="https://www.instagram.com/usesound/"><i class="fab fa-instagram" title="instagram page"></i></a>
            </div> */}
            
               
            
    
             <Supported />
            
            <div className="footer-copyright">
                <div>
                    <p>development: <a href="http://brainit.rs/" target="_blank">brainit.rs</a></p>
                    <p>©️ Copyright 2019 Soundsy - All Rights Reserved</p>
                    <p><a href="https://www.borisradivojkov.com/" target="_blank">design by: Boris Radivojkov</a></p>
                </div>
            </div>
        </div>
        );
    }
}

export default Footer;
