import React, { Component } from 'react';
import { Auth } from '../components/Auth';
import Service from '../components/Service';
import Aria from '../components/Aria';
import {Countries} from '../components/Countries';
import DashboardMenu from '../components/DashboardMenu';
import Select from 'react-select';

const customStyles = {
    container: (base, state) => ({
      ...base,
      transition:
        "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
      "&:hover": {
        boxShadow: "0 2px 4px 0 gray"
      }
    }),
    input: (base, state) => ({
      ...base,
      height: "30px",
      position: "initial"
    }),
    control: (base, state) => ({
      ...base,
      borderRadius: "0em",
      border:"2px solid gray",
      margin:"1em 0 1em"
    }),
    valueContainer: (base, state) => ({
        ...base,
        position: "initial",
      }),
      placeholder: (base, state) => ({
        ...base,
        color: "black",
      }),
  };

class EditAccount extends Component {

constructor(props) {
    super(props);
    this.state = {
        userName: '',
        name:'',
        lastName:'',
        address: '',
        telephone: '',
        email: '',
        changePassword: '',
        // bankAccount: '',
        role:'',
        gender:'',
        country:'',
        // selectedCountry:'',
        age: 30,
        alert:'',
        ages: [],
        // countries:Countries
    };
    this.mainHeading = React.createRef();

  }
    componentDidMount(){
        setTimeout(()=>{
        this.getUserData();
        },100);
        this.mainHeading.current.focus();
        let a = [];
        for(var i=18; i<100; i++){
            a.push(i);

        }
        this.setState({ages: a});
    }

    // handleAge=(event)=>{
    //     this.setState({age: event.target.value});
    //     console.log(this.state.age);
    // }


   
handleInput=(event)=> {
    this.setState({[event.target.id]: event.target.value});
}

// handleCountry=(event)=>{
//     this.setState({country: event.target.value});
//     console.log(this.state.country);
// }

handleCountry = country => {
    this.setState({ country });
};

updateAccount=(event)=>{
    event.preventDefault();
    if(!this.state.userName) {
        this.setState({alert: "Please enter your Username."});
        return;
    }else if(!this.state.name) {
        this.setState({alert: "Please enter your Name."});
        return;
    }else if(!this.state.lastName) {
        this.setState({alert: "Please enter your Last name."});
        return;
    }else if(!this.state.address) {
        this.setState({alert: "Please enter your Address."});
        return;
    }else if(!this.state.telephone) {
        this.setState({alert: "Please enter your Telephone."});
        return;
    }else if(!this.state.age) {
        this.setState({alert: "Please enter your Age."});
        return;
    }else if(!this.state.country) {
        this.setState({alert: "Please enter your Country."});
        return;
    }else{
        console.log(this.state)
        Service.get('/users/update',{
            address: this.state.address,
            telephone:  this.state.telephone,
            // bankAccount:  this.state.bankAccount,       
            country:this.state.country.value,     
            age: this.state.age,
            userName: this.state.userName,
            name: this.state.name,
            lastName: this.state.lastName
        },(res)=>{
            console.log(res);
            if(res.success){
                this.setState({alert: 'You successfuly updated your account information.'})
                this.getUserData();
            }else{
                this.setState({alert: res.info})
            }
        })    
    }


    // const files = Array.from(event.target.files);
    // this.setState({ uploading: true });

    // const formData = new FormData()

    // files.forEach((file, i) => {
    //     formData.append(i, file)
    // });

    // formData.append('address', this.state.address); 
    // formData.append('telephone', this.state.telephone); 
    // formData.append('bankAccount', this.state.bankAccount); 
    // formData.append('country', this.state.country); 
    // formData.append('languages', this.state.languages); 
    // formData.append('age', this.state.age); 
    // formData.append('userName', this.state.userName); 
    // console.log(formData);
    // var request = new XMLHttpRequest();
    // request.open("POST", '/users/update');
    // request.send(formData);
    // request.onprogress = function (res) {
    //     if (res.lengthComputable) {
    //         console.log(res.loaded+  " / " + res.total)
    //     }
    // }
}
getUserData=()=>{
    Service.get('/users/getUser',{},(res)=>{
        if(res.success){
            this.setState({
                address: res.data.user.address,
                telephone: res.data.user.telephone,
                bankAccount: res.data.user.bank_account,       
                country: res.data.user.country,   
                age: res.data.user.age,
                userName: res.data.user.user_name,
                name: res.data.user.name,
                lastName: res.data.user.last_name

            })
        }else{
            if(res.info === "user authentication failed"){
                Auth.signout();
            }
        }
        console.log(this.state)
    })
}

// editProfile()


    render(){
        const { country } = this.state;
        console.log(this.state.country)
        return(
            <div className="dashboard-wrapper">
                <img className="bg-b-r full-width" src="/images/background-dashboard.png" />
                <div className="content">
                    <h1 ref={this.mainHeading} tabIndex="0" className="title-left">Edit Account</h1>
                    <DashboardMenu />

                    <div className="edit-account-container">
                        <form className="edit-account-form">
                            <div className="edit-account-content">

                            <div className="edit-account-left">

                                <label>User Name</label>
                                <input type="text" id="userName" className="edit-account-input" value={this.state.userName} onChange={this.handleInput} />

                                <label>Name</label>
                                <input type="text" id="name" className="edit-account-input" value={this.state.name} onChange={this.handleInput} />

                                <label>Last Name</label>
                                <input type="text" id="lastName" className="edit-account-input" value={this.state.lastName} onChange={this.handleInput} />

                                <label>Legal Address</label>
                                <input type="text" id="address" className="edit-account-input" value={this.state.address} onChange={this.handleInput} />

                                {/* <button className="defaultButton delete-button" name="deleteProfile">Delete Profile</button> */}
                            </div>
    {/* 
                            <label>Change Password</label>
                            <input type="password" id="changePassword" value={this.state.changePassword} name="changePassword" onChange={this.handleInput} /> */}

                            {/* <label>Bank Account</label>
                            <input type="text" id="bankAccount" value={this.state.bankAccount} name="bankAccount" onChange={this.handleInput} /> */}

                            <div className="edit-account-right">

                                <label>Telephone</label>
                                <input type="text" id="telephone" className="edit-account-input" value={this.state.telephone} name="telephone" onChange={this.handleInput} />
                                
                                <label for="age">Year of birth</label>
                                {/* <select id='age' value={this.state.age} onChange={this.handleAge}>
                                            <option>Select Age</option>
                                    {this.state.ages.map(function (item, index){
                                        return (
                                            <option value={item}>{item}</option>
                                        )
                                    }, this)
                                    }
                                    
                                </select> */}
                                <input type="text" value={this.state.age} className="edit-account-input" id='age' onChange={this.handleInput} />

                                {/* <label for="age">Age</label>
                                <input type="number" value={this.state.age} id='age' onChange={this.handleInput} /> */}

                                {/* <label for="languages">Languages</label>
                                <input type="text" value={this.state.languages} className="edit-account-input" id='languages' onChange={this.handleInput} /> */}

                                
                                <label htmlFor="country">Country</label>
                                {/* <select id="country" value={this.state.country} onChange={this.handleCountry}>
                                        <option>Select Country</option>
                                        {this.state.countries.map(function (item, index){
                                            return (
                                                <option value={item.name}>{item.name}</option>
                                            )
                                        }, this)
                                        }              
                                </select> */}
                                <Select
                                    classNamePrefix={"select-container"}
                                    value={country}
                                    // defaultValue={Countries[1]}
                                    placeholder={this.state.country}
                                    onChange={this.handleCountry}
                                    options={Countries}   
                                    styles={customStyles}
                                />

                                <button className="defaultButton" onClick={this.updateAccount}>Submit</button><br />
                            </div>
                            </div>
                        </form>

                    
                
                        {/* <progress value={this.state.value} max="100"></progress>  */}
                        <Aria message={this.state.alert} />

                        
                    </div>
                </div>
            </div>
        );
    }

}

export default EditAccount;