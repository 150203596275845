import React, { Component } from 'react';



class Fail extends Component {





    render() {
        return (
            <div className="content">
                

                <h1>Invalid informations :( </h1>
            </div>
            
        );
    }

}

export default Fail;