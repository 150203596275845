import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Read } from "react-router-dom";
import Service from '../components/Service';



class AdminProjects extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
             
            
        }

    }



    componentDidMount(){
        this.getProjects();


    }

    getProjects=()=>{
        Service.get("/admin/getProjects",{}, (res) => {

            console.log(res);
            this.setState({projects: res.data.projects});
            
        }); 
    }





    render() {
        return (
            <div className="content admin-content" >
            <div>
            <h1> Projects</h1>
            </div>
            <table id="admin-projects">
                <tr>
                    <th>Id</th>
                    <th>User Id</th>
                    <th>Description</th>
                    <th>Username</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Link</th>
                </tr>
                {this.state.projects.map(function (item, index) {
                    return (
                        <tr key={index}>
                                <td>
                                    {item.id}
                                </td> 
                                <td>
                                    {item.user_id}
                                </td> 
                                <td> 
                                    {item.description}
                                </td> 
                                <td> 
                                    {item.name}
                                </td>  
                                <td>
                                    {item.price}
                                </td>
                                <td>
                                    {item.status == 0 &&
                                        <p>Pending</p>
                                    }
                                    {item.status == 1 &&
                                        <p>Approved</p>
                                    }
                                    {item.status == 9 &&
                                        <p>Rejected</p>
                                    }
                                </td>
                                <td>
                                <Link to ={"/admin/project/"+item.id}>Go to</Link>
                                </td>
                           
                               
                        </tr>
                    )
                }, this)
                }
            </table>
            </div>
        );
      }
    }


export default AdminProjects;