import React, { Component } from 'react';
import Service from '../components/Service';
import {Auth} from '../components/Auth';
import { BrowserRouter as Router, Route, Link ,Redirect} from "react-router-dom";
import Aria from '../components/Aria';


class SignIn extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            repeatedPassword: '',
            passwordError: '',
            emailError: '',
            role: '',
            name: '',
            lastName: '',
            alert:"",
            confirmPassword: ''
            
        }
        this.mainHeading = React.createRef();
     

    }

    // componentDidMount(){
    //     document.title = 'Your page title here';
    //     this.mainHeading.current.focus();
        
    // }
    
    
    state = {
        redirect: false
      }
      setRedirect = () => {
        this.setState({
          redirect: true
        })
      }
      renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to='/user/dashboard' />
        }
    }




    handleInput = (event) => {
        
        this.setState({ [event.target.id]: event.target.value });
         
      
    }


    submit = () => {
        const isValid = this.validate();
        if (isValid) {
           
        }
    
       
        Service.Register({ email: this.state.email, pass: this.state.password, role: this.state.role }, (result) => {

            console.log(result)
        });
       
      

       


        // console.log(this.state);
    }

    validate = () => {
        let passwordError = "";
        let emailError = "";
        
        

        if (!this.state.password) { 
            passwordError = "name cannot be blank";
        }

        if (!this.state.email.includes('@','.com')) {
            emailError = 'invalid email';
        }

    

        if (emailError || passwordError) {
            this.setState({ emailError, passwordError });
            return false;
        }

        
        return true;

    }

    selectRole = (event) => {

        this.setState({ role: event.target.value }, () => {
            console.log(this.state);
        });

    };

    logIn = () => {
        Auth.email = this.state.email;
        Auth.authenticate(this.state.password, (res) =>{
            if(res.success){
                // this.setRedirect()
                window.location="/"
                console.log('redirect')
            
            }

        },(res)=>{
            if(res == 'no-email-provided'){
                this.setState({alert: 'You have to enter the email.'})
                return;
            }
            if(res == 'no-password-provided'){
                this.setState({alert: 'You have to enter the password.'})
                return;
            }
            if(res == 'no-user'){
                this.setState({alert: 'User with this email is not registered. Please sign up.'})
                return;
            }
            if(res == 'no-confirmed-email'){
                this.setState({alert: 'This email is not confirmed. Please check your email and click the activation link to confirm your email.'})
                return;
            }
            if(res == 'wrong-password'){
                this.setState({alert: 'You have entered the wrong password'})
                return;
            }
            

            this.setState({alert: 'An error occurred, please try again later.'})
            return;
        
        });

    
    }


    defaultButton = (e) => {
        if(e.which === 13) {
          this.logIn();
        }
      }

      
    render() {
        return (
            <div className="sign-wrapper">
                <img className="bg-b-r full-width" src="/images/background-sign.png" />
                <div className="content">
                    {this.renderRedirect()}  

                    {/* <h1 className="sign" ref={this.mainHeading} tabIndex="0">Account</h1>
                    <div>
                        <div className="sign-in">
                            <label>SIGN IN</label>
                            <select id='signIn' value={this.state.signIn} onChange={this.handleSignIn}>
                                <option value=''>Choose profile</option>
                                <option value='Freelancer'>Freelancer</option>
                                <option value='Buyer'>Buyer</option>
                            </select>
        
                        </div>
                        <div className="sign-in ">
                            <label>SIGN UP</label>
                            <select id='singUp' value={this.state.signIn} onChange={this.handleSignIn}>
                                <option value=''>Choose profile</option>
                                <option value='Freelancer'>Freelancer</option>
                                <option value='Buyer'>Buyer</option>
                            </select>
        
                        </div>



                    </div> */}







                    {/* <p>If you do not have an account,</p> <Link to="/sign-up" aria-label={"If you do not have an account, sign up"}>Sign up</Link> */}  
                <div className="sign-in-box">
                    <h1  className="title-left">ACCOUNT</h1>
                    <label>SIGN IN</label><br></br>
                    <label for="email" className="sign-in-label">Email</label>
                    <input type="email" value={this.state.email} id='email' onKeyPress={this.defaultButton} onChange={this.handleInput} placeholder="Your Email"/>

                    <h4>{this.state.emailError}</h4>

                    <label for="password" className="sign-in-label">Password</label>
                    <input type="password" value={this.state.password} id='password' onKeyPress={this.defaultButton} onChange={this.handleInput} placeholder="Your Password"/>

                
                    <h4>{this.state.passwordError}</h4>
            
                    <button onClick={this.logIn} >Sign in</button>
                    <a href="#">Forgot Your Password >></a>
                </div>
                    <Aria message={this.state.alert} />

                    {/* ovde dodati sign up  */}



                </div>
            </div>
        );
    }

}

export default SignIn;